import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { useTheme } from '../../utils/useTheme'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { CheckmarkIcon } from '../../design-tokens/icons'

export interface DropdownMenuItemProps extends HTMLAttributes<HTMLLIElement> {
  selected?: boolean
  onSelect?: (value: any) => void
  tabIndex?: number
  disabled?: boolean
  role?: string
  value?: any
  allowWrap?: boolean
}

const ListItem = styled.li<DropdownMenuItemProps>`
  ${({
    theme: {
      xyz: { color, spacing },
    },
    disabled,
  }) => css`
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.125rem ${spacing.space4}rem;
    background-color: ${color.neutralWhite};

    &:focus,
    &:hover {
      background-color: ${color.neutralGray5};
      color: ${color.signalBlue};
      outline: 0;
    }

    ${disabled &&
    css`
      pointer-events: none;
      color: ${color.neutralPassiveGray};
    `}
  `}
`

const Label = styled.span<{ allowWrap: boolean; hasIcon: boolean }>`
  line-height: 150%;
  display: block;

  ${({ allowWrap }) =>
    !allowWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({
    theme: {
      xyz: { fontFamily, fontSize, spacing },
    },
    hasIcon,
  }) => css`
    margin-right: ${hasIcon ? `${spacing.space2}rem` : `${spacing.space5}rem`};
    font-family: ${fontFamily.Montserrat};
    font-size: ${fontSize.body.three}rem;
  `}
`

const StyledCheckItem = styled(CheckmarkIcon)`
  flex-shrink: 0;
`

export const DropdownMenuItem = React.forwardRef<HTMLLIElement, DropdownMenuItemProps>((props, ref) => {
  const {
    children,
    selected,
    onSelect,
    tabIndex: tabIndexProp,
    role,
    disabled,
    value,
    allowWrap = false,
    ...rest
  } = props
  const theme = useTheme()

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()
      onSelect(value)
    }
  }

  const handleClick = () => {
    onSelect(value)
  }

  let tabIndex
  if (!disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1
  }

  return (
    <ListItem
      ref={ref}
      role={role}
      selected={selected}
      aria-selected={undefined}
      aria-disabled={disabled}
      tabIndex={tabIndex}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
      disabled={disabled}
      {...rest}
    >
      <Label allowWrap={allowWrap} hasIcon={selected}>
        {children}
      </Label>
      {selected && (
        <StyledCheckItem
          width={`${theme.xyz.iconSize.s}em`}
          height={`${theme.xyz.iconSize.s}em`}
          color={disabled ? theme.xyz.color.neutralPassiveGray : theme.xyz.color.signalBlue}
        />
      )}
    </ListItem>
  )
})

DropdownMenuItem.displayName = 'DropdownMenuItem'

DropdownMenuItem.propTypes = {
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  allowWrap: PropTypes.bool,
}

DropdownMenuItem.defaultProps = {
  selected: false,
  onSelect: () => {},
  role: 'menuitem',
  tabIndex: undefined,
  disabled: false,
  value: null,
  allowWrap: false,
}
