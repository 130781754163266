import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import {
  AlertIndicatorIcon,
  CheckmarkIndicatorIcon,
  InfoIndicatorIcon,
  WarningIndicatorIcon,
  CloseIcon,
} from '../../design-tokens/icons'
import { HeadlineSize } from '../../design-tokens/typography'
import {
  ButtonText,
  colors,
  Container,
  ContentContainer,
  IndicatorIconContainer,
  StyledCloseButton,
} from './Notification.style'
import { NotificationType, NotificationProps } from './Notification.types'
import { XyzTheme } from '@postidigital/posti-theme'

const renderIcon = (type: NotificationType | keyof typeof NotificationType) => {
  switch (type) {
    case NotificationType.alert:
      return <AlertIndicatorIcon inverted={true} />
    case NotificationType.informational:
      return <InfoIndicatorIcon inverted={true} />
    case NotificationType.success:
      return <CheckmarkIndicatorIcon inverted={true} />
    case NotificationType.warning:
      return <WarningIndicatorIcon inverted={true} color={XyzTheme.color.neutralNetworkGray} />
    case NotificationType.text:
      return null
    default:
      return <InfoIndicatorIcon inverted={true} />
  }
}

export const Notification: React.FC<NotificationProps> = ({
  children,
  dismissible,
  showIcon,
  centerContent,
  onClose,
  closeText,
  contentType,
  ...rest
}) => {
  return (
    <Container contentType={contentType} {...rest}>
      <ContentContainer>
        {showIcon && contentType !== NotificationType.text && (
          <IndicatorIconContainer centerContent={centerContent} dismissible={dismissible}>
            {renderIcon(contentType)}
          </IndicatorIconContainer>
        )}
        <ButtonText size={HeadlineSize.Eight} as="span">
          {children}
        </ButtonText>
        {dismissible && (
          <StyledCloseButton
            onClick={onClose}
            iconColor={colors[contentType].closeButtonColor}
            aria-label={closeText}
            centerContent={centerContent}
            icon={CloseIcon}
          />
        )}
      </ContentContainer>
    </Container>
  )
}

Notification.propTypes = {
  contentType: PropTypes.oneOf(Object.values(NotificationType)) as Validator<NotificationType>,
  children: PropTypes.any.isRequired,
  dismissible: PropTypes.bool,
  showIcon: PropTypes.bool,
  onClose: PropTypes.func,
  closeText: PropTypes.string,
}

Notification.defaultProps = {
  contentType: NotificationType.informational,
  dismissible: true,
  showIcon: true,
  centerContent: false,
  width: '100%',
}
