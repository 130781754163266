import styled, { css } from 'styled-components'
import { Headline, Body } from '../../index'

export const Card = styled.div`
  ${({
    theme: {
      xyz: { color, spacing, borderRadius },
    },
  }) => css`
    width: 100%;
    border-radius: ${borderRadius.sm}rem;
    box-shadow: 0 8px 22px rgba(27, 31, 35, 0.07);
    background: ${color.neutralWhite};
    padding: ${spacing.space4}rem;

    ${Body} {
      &:last-child {
        margin-bottom: 0;
      }
    }
  `}
`
export const CardHeader = styled.div`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    margin-bottom: ${spacing.space4}rem;
  `}
`

export const CardContent = styled.div`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    ${Headline} {
      margin-bottom: ${spacing.space2}rem;
    }
  `}
`

export const CardContainerImage = styled.div`
  ${({
    theme: {
      xyz: { spacing, borderRadius },
    },
  }) => css`
    margin: -${spacing.space4}rem -${spacing.space4}rem ${spacing.space4}rem;
    border-radius: ${borderRadius.sm}rem ${borderRadius.sm}rem 0 0;
    overflow: hidden;
    img {
      width: 100%;
    }
  `}
`

export const CardContentBlock = styled.div`
  ${({
    theme: {
      xyz: { color, spacing },
    },
  }) => css`
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      background-color: ${color.neutralPassiveGray};
      margin: ${spacing.space4}rem 0;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  `}
`

export const CardFooter = styled.div`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    margin-top: ${spacing.space4}rem;
  `}
`
