import React, { useState } from 'react'
import { ChevronRightIcon } from '../../design-tokens/icons'
import { useTheme } from '../../utils/useTheme'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import {
  InternalSideMenuProps,
  SideMenuDefaultProps,
  SideMenuProps,
  SideMenuPropsItem,
  SideMenuPropTypes,
} from './OldSideMenu.types'
import { SIDE_MENU_ITEM_ICON_POSITION, EventSource } from './consts'
import {
  SubContainer,
  StyledHr,
  SideMenuItemElement,
  StyledLink,
  IconAndText,
  Icon,
  TextContainer,
  Subtitle,
  MainContainer,
  SideMenuContainer,
  Label,
  ToggleButton,
} from './OldSideMenu.styles'
import { randomId } from '../../utils/helpers'

const SideMenuItem: React.FC<SideMenuPropsItem & InternalSideMenuProps> = (props) => {
  const {
    subItems,
    route,
    label,
    icon,
    iconPosition = SIDE_MENU_ITEM_ICON_POSITION.left,
    onClick,
    isActive,
    isOpenByDefault,
    activeBackgroundColor,
    activeTextColor,
    subtitle,
    isChild = false,
    togglingDisabled = false,
    expandWithClick,
    isParentExpanded,
    expandSubMenuAriaLabel,
    id: optId,
    ...rest
  } = props
  const active = isActive(route)
  const showChildren = !togglingDisabled || active
  const [isExpanded, setIsExpanded] = useState((togglingDisabled && showChildren) || props.isOpenByDefault)
  const theme = useTheme()
  const [fallbackId] = useState(randomId())
  const id = optId || fallbackId
  const labelId = `label-${id}`

  const handleClickOrKeyPress = (e, type: EventSource, expand = false) => {
    if (type === EventSource.KEYBOARD && !(e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space)) {
      return
    }
    e.preventDefault()
    if (
      !togglingDisabled &&
      subItems &&
      ((type === EventSource.KEYBOARD && e.key === KeyboardKeys.Space) || (type === EventSource.MOUSE && expand))
    ) {
      e.stopPropagation()
      setIsExpanded(!isExpanded)
    } else {
      onClick(route)
    }
  }

  return (
    <SideMenuItemElement>
      <StyledLink
        href={route}
        onClick={(e) => handleClickOrKeyPress(e, EventSource.MOUSE, expandWithClick)}
        onKeyDown={(e) => handleClickOrKeyPress(e, EventSource.KEYBOARD)}
        backgroundColor={active && activeBackgroundColor}
        aria-haspopup={Boolean(subItems)}
        aria-expanded={subItems ? isExpanded : undefined}
        id={id}
        {...rest}
      >
        <IconAndText alignStart={isChild} iconPosition={iconPosition}>
          {Boolean(icon) && (
            <Icon aria-hidden={true} color={active ? activeTextColor : undefined}>
              {icon}
            </Icon>
          )}
          <TextContainer>
            <Label id={labelId} active={active} activeTextColor={activeTextColor}>
              {label}
            </Label>
            {subtitle && <Subtitle size="xs">{subtitle}</Subtitle>}
          </TextContainer>
          {subItems && !togglingDisabled ? (
            <ToggleButton
              aria-describedby={labelId}
              aria-label={expandSubMenuAriaLabel}
              isExpanded={isExpanded}
              onClick={(e) => handleClickOrKeyPress(e, EventSource.MOUSE, true)}
              icon={ChevronRightIcon}
              iconColor={active ? activeTextColor : theme.color.neutralNetworkGray}
            />
          ) : null}
        </IconAndText>
      </StyledLink>
      {showChildren && !!subItems?.length && (
        <SubContainer isExpanded={isExpanded}>
          {subItems.map((childProps: SideMenuPropsItem, i) => {
            return (
              <SideMenuItem
                key={i}
                isChild={true}
                togglingDisabled={togglingDisabled}
                isParentExpanded={isParentExpanded ?? isExpanded}
                tabIndex={isParentExpanded === false ? -1 : 0}
                expandSubMenuAriaLabel={expandSubMenuAriaLabel}
                {...childProps}
              />
            )
          })}
          <StyledHr aria-hidden="true" />
        </SubContainer>
      )}
    </SideMenuItemElement>
  )
}

/**
 * OldSideMenu Component
 * @deprecated custom implementation should be created instead.
 */
const OldSideMenu: React.FC<SideMenuProps> = ({
  structure,
  togglingDisabled,
  containerWidth,
  expandSubMenuAriaLabel,
  ...rest
}) => {
  return (
    <SideMenuContainer width={containerWidth} {...rest}>
      <MainContainer>
        {structure.map((p, i) => {
          return (
            <SideMenuItem
              key={i}
              {...p}
              togglingDisabled={togglingDisabled}
              expandSubMenuAriaLabel={expandSubMenuAriaLabel}
            />
          )
        })}
      </MainContainer>
    </SideMenuContainer>
  )
}

OldSideMenu.propTypes = SideMenuPropTypes
OldSideMenu.defaultProps = SideMenuDefaultProps

export default OldSideMenu
