import React, { HTMLAttributes, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Theme } from '@postidigital/posti-theme'
import { ThemeColor } from '../../utils/helpers'
import { CheckmarkIcon } from '../../design-tokens/icons'
import { handleKeyActivation } from '../../utils/keyActivation'

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  id: string
  size?: string
  label?: string
  selected: boolean
  iconSize?: string
  iconColor?: string
  borderColor?: ThemeColor | string
  selectedBorderColor?: ThemeColor | string
  backgroundColor?: ThemeColor | string
  selectedBackgroundColor?: ThemeColor | string
}

const Label = styled.label<Partial<Props>>(
  ({ selected, selectedBackgroundColor, backgroundColor, borderColor, selectedBorderColor, size, theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    width: ${size};
    height: ${size};
    border-width: 3px;
    border-style: solid;
    border-color: ${selected
      ? theme.color[selectedBorderColor] || selectedBorderColor
      : theme.color[borderColor] || borderColor};
    background-color: ${selected
      ? theme.color[selectedBackgroundColor] || selectedBackgroundColor
      : theme.color[backgroundColor] || backgroundColor};
    transition: background-color 0.1s ease;

    &:hover,
    &:focus,
    &:active {
      border-width: 2px;
      outline: 0;
    }
  `
)

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`
/**
 * SelectButton Component - Indicates selection like checkbox but with a different layout
 * @deprecated custom implementation should be created instead.
 */
const SelectButton: React.FC<Props> = ({
  children,
  label,
  selected,
  id,
  size,
  iconSize,
  iconColor,
  onChange,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>()
  const click = () => ref.current.click()
  return (
    <Label
      htmlFor={id}
      selected={selected}
      size={size}
      aria-label={label}
      tabIndex={0}
      onKeyDown={handleKeyActivation(click)}
      {...props}
    >
      <Checkbox id={id} ref={ref} checked={selected} onChange={onChange} role="checkbox" aria-checked={selected} />
      {selected && <CheckmarkIcon color={iconColor} width={iconSize} height={iconSize} />}
    </Label>
  )
}

SelectButton.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  iconSize: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  iconColor: PropTypes.string,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  selectedBackgroundColor: PropTypes.string,
  selectedBorderColor: PropTypes.string,
}

SelectButton.defaultProps = {
  selected: false,
  size: `${Theme.size.icon.m}em`,
  iconSize: `${Theme.size.icon.s}em`,
  label: 'Select',
  iconColor: Theme.color.neutralWhite,
  borderColor: Theme.color.neutralIconGray,
  backgroundColor: Theme.color.neutralWhite,
  selectedBackgroundColor: Theme.color.signalGreen,
  // A bit of transparency here makes the border aliasing much better
  selectedBorderColor: 'rgba(0, 138, 0, 0.75)',
}

/** @component */

export default SelectButton
