import React, { ButtonHTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import { XyzTheme } from '@postidigital/posti-theme'
import { useTheme } from '../../utils/useTheme'
import { ThemeColor } from '../../utils/helpers'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { StyledCardSelector, StyledIcon, StyledCheckmarkIndicatorIcon } from './CardSelector.styles'
import { Label, LabelSize } from '../../design-tokens/typography/Label'
import { KeyboardKeys } from '../../utils/keyboardNavigation'

export interface CardSelectorProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  iconColor?: ThemeColor | string
  disabled?: boolean
  selected?: boolean
  label?: string
  icon?: React.ComponentType<DefaultIconProps>
  onClick: (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void
}

export const CardSelector: React.FC<CardSelectorProps> = (props) => {
  const { icon: Icon, disabled, iconColor, label, selected, onClick, ...rest } = props
  const theme = useTheme()
  const color = disabled ? theme.xyz.color.neutralPassiveGray : iconColor

  const onKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === KeyboardKeys.Enter) {
      e.preventDefault()
      onClick(e)
    }
  }

  return (
    <StyledCardSelector
      aria-disabled={disabled}
      disabled={disabled}
      selected={selected}
      onClick={onClick}
      onKeyPress={onKeyPress}
      aria-pressed={selected}
      {...rest}
    >
      {selected && !disabled && <StyledCheckmarkIndicatorIcon inverted color={theme.xyz.color.signalBlue} />}
      {!!Icon && <StyledIcon as={Icon} color={color} aria-hidden={true} />}
      <Label size={LabelSize.Five}>{label}</Label>
    </StyledCardSelector>
  )
}

CardSelector.propTypes = {
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired as Validator<React.ComponentType<DefaultIconProps>>,
}

CardSelector.defaultProps = {
  disabled: false,
  selected: false,
  label: '',
  iconColor: XyzTheme.color.signalBlue,
}
