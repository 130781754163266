import styled, { css } from 'styled-components'
import { IconButton } from '../../basic-components/IconButton'
import { SIDE_MENU_ITEM_ICON_POSITION } from './consts'
import Text from '../typography/Text'

interface WidthProps {
  width: string
}

interface IsExpandedProps {
  isExpanded: boolean
}

export const SideMenuContainer = styled.nav<WidthProps>`
  width: ${(props) => props.width};
`

export const MainContainer = styled.ul`
  padding: 0;
`

export const StyledHr = styled.hr(
  ({ theme }) => css`
    border: 0;
    border-top: 1px solid ${theme.color.neutralGray5};
    border-bottom: 1px solid ${theme.color.neutralGray2};
  `
)

export const ToggleButton = styled(IconButton)<IsExpandedProps>`
  transform: ${(props) => (props.isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: transform 100ms ease;
  margin: 0 ${({ theme }) => theme.spacing.xxs}rem 0 0;
  font-size: 0.56rem;
  svg {
    display: block;
  }
`

export const StyledLink = styled.a<{ backgroundColor?: string }>(
  ({ backgroundColor, theme }) => css`
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 6px;
    color: ${theme.color.neutralNetworkGray};
    margin: 0 ${theme.spacing.xxs}rem;
    transition: background-color 0.1s ease-out;

    ${backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

    &:hover {
      filter: brightness(92%);
      background-color: ${backgroundColor || theme.color.neutralGray2};
      text-decoration: none;
    }

    &:focus {
      outline: none;
      background-color: ${backgroundColor || theme.color.neutralGray2};
      filter: brightness(92%);
    }
  `
)

export const SideMenuItemElement = styled.li(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSize.text.md}rem;
    list-style: none;
    list-style-type: none;
    cursor: pointer;
    line-height: 1;
    outline: none;
    margin: ${theme.spacing.xxs}rem 0;
  `
)

export const Icon = styled.span<{ color?: string }>(
  ({ color }) =>
    color &&
    css`
      /* Force the possible icon to have the same color as the active text */
      svg path,
      svg rect {
        fill: ${color};
      }
    `
)

export const Label = styled(Text)<{ active?: boolean; activeTextColor?: string }>(
  ({ theme, active, activeTextColor }) => css`
    color: ${active ? activeTextColor : theme.color.neutralNetworkGray};
    font-weight: ${active ? theme.fontWeight.semiBold : theme.fontWeight.medium};
  `
)

export const Subtitle = styled(Text)(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight.medium};
  `
)

export const TextContainer = styled.div`
  width: 100%;
`

export const IconAndText = styled.div<{
  alignStart?: boolean
  iconPosition?: keyof typeof SIDE_MENU_ITEM_ICON_POSITION
}>(
  ({ theme, alignStart, iconPosition = SIDE_MENU_ITEM_ICON_POSITION.left }) => css`
    display: flex;
    align-items: ${alignStart ? 'flex-start' : 'center'};
    padding: ${theme.spacing.xs}rem 0 ${theme.spacing.xs}rem ${theme.spacing.xs}rem;
    width: 100%;
    ${iconPosition === SIDE_MENU_ITEM_ICON_POSITION.right &&
    css`
      flex-direction: row-reverse;
    `}

    ${Icon} {
      font-size: ${({ theme }) => theme.fontSize.text.md}rem;
      ${iconPosition === SIDE_MENU_ITEM_ICON_POSITION.left
        ? `margin-right: ${theme.spacing.xs}rem`
        : `margin-left: ${theme.spacing.xs}rem`};
      ${iconPosition === SIDE_MENU_ITEM_ICON_POSITION.right &&
      css`
        margin-right: ${theme.spacing.xs}rem;
      `}
    }

    ${Label} {
      flex: 1;
    }
  `
)

// TODO max-height animation is for demo purposes. Waiting for the design to decide before implememting properly.
export const SubContainer = styled.ul<IsExpandedProps>(
  ({ isExpanded, theme }) => css`
    padding-left: 0;
    padding-top: ${theme.spacing.xxs}rem;
    margin-left: 24px;
    overflow: hidden;
    max-height: ${isExpanded ? '1000px' : '0'};
    visibility: ${isExpanded ? 'visible' : 'hidden'};
    transition: max-height 150ms ease-in-out;
  `
)
