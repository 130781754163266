import React, { ButtonHTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import styled, { css, useTheme } from 'styled-components'
import { CloseIcon } from '../../design-tokens/icons'
import { Label, LabelSize } from '../../design-tokens/typography/Label'

export interface FilterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
  /**
   * Shows close icon when filter button is selected.
   * This should be enabled for when de-selecting a filter button removes the button, not just deselects it.
   */
  withCloseIcon?: boolean
}

export const StyledButton = styled.button<FilterButtonProps>(
  ({
    theme: {
      xyz: { color, spacing, lineHeight },
    },
    selected,
  }) => css`
    background-color: ${selected ? color.signalBlue : color.neutralGray5};
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${spacing.space3}rem;
    border-radius: 1.25rem;
    cursor: pointer;
    outline: none;
    border: 2px solid ${selected ? color.signalBlue : 'transparent'};
    padding: 0.4375rem 0.75rem;

    label {
      line-height: ${lineHeight.label.one}rem;
      cursor: pointer;
      color: ${selected ? color.neutralWhite : color.neutralNetworkGray};
    }

    &:hover {
      background-color: ${selected ? color.signalBlue : color.neutralOnHoverGray};
    }

    &:active {
      background-color: ${selected ? color.signalBlue : color.neutralOnPressGray};
    }

    &:focus {
      outline: none;
      border: 2px solid ${selected ? color.neutralWhite : color.signalBlue};
      box-shadow: 0px 0px 7px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: 2px solid transparent;
    }

    &:not(:last-of-type) {
      margin-right: ${spacing.space2}rem;
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      background-color: ${color.neutralGray2};
      border: 2px solid transparent;

      label {
        color: ${color.neutralPassiveGray};
      }
    }
  `
)

const StyledCloseIcon = styled(CloseIcon)`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    margin-left: ${spacing.space2}rem;
    flex-shrink: 0;
  `}
`

const FilterButton = React.forwardRef<HTMLButtonElement, FilterButtonProps>(
  ({ selected, children, onClick, disabled, withCloseIcon, value, ...rest }, ref) => {
    const theme = useTheme()

    return (
      <StyledButton
        aria-disabled={disabled}
        selected={selected}
        role="option"
        aria-selected={selected}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        value={value}
        {...rest}
      >
        <Label size={LabelSize.Five}>{children}</Label>
        {withCloseIcon && selected && (
          <StyledCloseIcon
            width={`${theme.xyz.spacing.space3}rem`}
            height={`${theme.xyz.spacing.space3}rem`}
            aria-hidden={true}
            color={theme.xyz.color.neutralWhite}
          />
        )}
      </StyledButton>
    )
  }
)

FilterButton.displayName = 'FilterButton'

FilterButton.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  withCloseIcon: PropTypes.bool,
}

FilterButton.defaultProps = {
  selected: false,
  disabled: false,
  withCloseIcon: false,
}

export { FilterButton }
