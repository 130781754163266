import 'react-dates/initialize'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'
import { StyledCalendarIcon, DateInputContent, StyledUIMessage } from './DateInput.style'
import { MonthNavigationButton } from '../../composite-components/MonthPicker/MonthNavigationButton'
import { LabelAndInputContainer, StyledLabel } from '../../basic-components/Input/Input.style'
import { DEFAULT_DATE_FORMAT, DEFAULT_DAY_SIZE, DEFAULT_LOCALE, overriddenPhrases } from './DateInput'
import { DateRangeInputArrow } from './DateRangeInputArrow'
import { DateRangeInputProps } from './DateInput.types'

/** DateRangeInput Component
 * @deprecated Use DatePicker instead
 */

export const DateRangeInput: React.FC<DateRangeInputProps> = ({
  id,
  label,
  isInvalid,
  message,
  messageProps,
  locale,
  leftTemplate,
  rightTemplate,
  className,
  lightBackground,
  prevMonthButtonAriaLabel,
  nextMonthButtonAriaLabel,
  'aria-describedby': ariaDescribedBy,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>()
  const startDateInputRef = useRef<HTMLInputElement>()
  const endDateInputRef = useRef<HTMLInputElement>()
  const [focused, setFocused] = useState(Boolean(props.focusedInput))
  const [hasValue, setHasValue] = useState(Boolean(props.startDate || props.endDate))
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>()

  const messageId = props.startDateId ? `${props.startDateId}-message` : undefined
  const describedByElements = [ariaDescribedBy, messageId].filter(Boolean)

  useEffect(() => {
    if (!startDateInputRef.current || !endDateInputRef.current) {
      const elements = containerRef.current.querySelectorAll<HTMLInputElement>('.DateInput_input')
      startDateInputRef.current = elements[0]
      endDateInputRef.current = elements[1]
    }
    moment.locale(locale)
  }, [locale])

  useEffect(() => {
    setHasValue(
      Boolean(props.startDate || props.endDate || startDateInputRef.current.value || endDateInputRef.current.value)
    )
    setFocused(Boolean(props.focusedInput))
  }, [props.startDate, props.endDate, props.focusedInput])

  useEffect(() => {
    setFocused(Boolean(props.focusedInput))
    setFocusedInput(props.focusedInput)
  }, [props.focusedInput])

  useEffect(() => {
    startDateInputRef.current.setAttribute('aria-disabled', `${Boolean(props.disabled)}`)
    endDateInputRef.current.setAttribute('aria-disabled', `${Boolean(props.disabled)}`)
    startDateInputRef.current.setAttribute('aria-readonly', `${Boolean(props.readOnly)}`)
    endDateInputRef.current.setAttribute('aria-readonly', `${Boolean(props.readOnly)}`)
    startDateInputRef.current.setAttribute('aria-invalid', `${Boolean(isInvalid)}`)
    endDateInputRef.current.setAttribute('aria-invalid', `${Boolean(isInvalid)}`)
    startDateInputRef.current.setAttribute(
      'aria-describedby',
      `${describedByElements.length ? describedByElements.join(' ') : ''}`
    )
    endDateInputRef.current.setAttribute(
      'aria-describedby',
      `${describedByElements.length ? describedByElements.join(' ') : ''}`
    )
  }, [props.disabled, props.readOnly, isInvalid, describedByElements])

  const onFocusChange = (value: FocusedInputShape) => {
    if (props.readOnly || props.disabled) {
      return
    }
    setFocused(Boolean(value))
    setFocusedInput(value)
    props.onFocusChange?.(value)
  }

  const onDatesChange = (value) => {
    setHasValue(
      Boolean(value.startDate || value.endDate || startDateInputRef.current.value || endDateInputRef.current.value)
    )
    props.onDatesChange?.(value, {
      startDateValue: startDateInputRef.current.value,
      endDateValue: endDateInputRef.current.value,
    })
  }

  const focus = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetElem = event.target as HTMLElement
    if (targetElem.tagName && targetElem.tagName !== 'INPUT') {
      startDateInputRef.current.select()
    }
  }

  const leftElement = leftTemplate
  const rightElement = rightTemplate || <StyledCalendarIcon disabled={Boolean(props.disabled)} />
  const startDatePlaceholderText = hasValue || focused ? props.startDatePlaceholderText ?? '' : ''
  const endDatePlaceholderText = hasValue || focused ? props.endDatePlaceholderText ?? '' : ''

  return (
    <div ref={containerRef} className={className}>
      <DateInputContent
        id={id}
        onClick={focus}
        invalid={isInvalid}
        focused={focused}
        hasMessage={Boolean(message)}
        readOnly={props.readOnly}
        disabled={Boolean(props.disabled)}
        lightBackground={lightBackground}
        daySize={props.daySize}
        numberOfMonths={props.numberOfMonths}
      >
        {leftElement}
        <LabelAndInputContainer
          hasLeftElement={Boolean(leftElement)}
          hasRightElement={Boolean(rightElement)}
          hasValue={hasValue}
          focused={focused}
        >
          <StyledLabel
            htmlFor={props.startDateId}
            focused={focused}
            hasValue={hasValue}
            disabled={Boolean(props.disabled)}
          >
            {label}
          </StyledLabel>
          <DateRangePicker
            hideKeyboardShortcutsPanel={true}
            noBorder={true}
            navPrev={<MonthNavigationButton orientation={'left'} aria-label={prevMonthButtonAriaLabel} />}
            navNext={<MonthNavigationButton orientation={'right'} aria-label={nextMonthButtonAriaLabel} />}
            horizontalMonthPadding={0}
            horizontalMargin={0}
            customArrowIcon={<DateRangeInputArrow hasValue={hasValue} disabled={Boolean(props.disabled)} />}
            phrases={overriddenPhrases}
            {...props}
            focusedInput={focusedInput}
            startDatePlaceholderText={startDatePlaceholderText}
            endDatePlaceholderText={endDatePlaceholderText}
            onFocusChange={onFocusChange}
            onDatesChange={onDatesChange}
          />
        </LabelAndInputContainer>
        {rightElement}
      </DateInputContent>
      {message && (
        <div aria-live="polite" id={messageId} aria-hidden={!Boolean(message)}>
          <StyledUIMessage success={!isInvalid} message={message} {...messageProps} />
        </div>
      )}
    </div>
  )
}

DateRangeInput.defaultProps = {
  displayFormat: DEFAULT_DATE_FORMAT,
  locale: DEFAULT_LOCALE,
  lightBackground: true,
  daySize: DEFAULT_DAY_SIZE,
  numberOfMonths: 2,
}

DateRangeInput.propTypes = {
  displayFormat: PropTypes.string,
  locale: PropTypes.string,
  isInvalid: PropTypes.bool,
  message: PropTypes.string,
  messageProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  startDateId: PropTypes.string.isRequired,
  leftTemplate: PropTypes.element,
  rightTemplate: PropTypes.element,
  className: PropTypes.string,
  lightBackground: PropTypes.bool,
  prevMonthButtonAriaLabel: PropTypes.string.isRequired,
  nextMonthButtonAriaLabel: PropTypes.string.isRequired,
}
