import styled, { css } from 'styled-components'

import { NavButtonProps } from './NavButton.types'
import { ChevronDownIcon } from '../../design-tokens/icons'
import { XyzTheme } from '@postidigital/posti-theme'

const colors = {
  primary: {
    color: XyzTheme.color.neutralWhite,
    colorHover: XyzTheme.color.neutralWhite,
    background: XyzTheme.color.signalBlue,
    backgroundHover: '#007ee6',
  },
  secondary: {
    color: XyzTheme.color.neutralNetworkGray,
    colorHover: XyzTheme.color.signalBlue,
    background: XyzTheme.color.neutralWhite,
    backgroundHover: XyzTheme.color.neutralGray2,
  },
}

export const StyledButton = styled.button<Pick<NavButtonProps, 'isOpen' | 'contentMode' | 'selectedColor'>>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 0;
  text-align: center;
  user-select: none;
  margin: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  text-decoration: none !important;
  cursor: pointer;
  height: 100%;
  flex-wrap: nowrap;
  padding: 0.375em 0.75em;

  /* customize */
  ${({
    theme: {
      xyz: { color, fontFamily },
    },
    isOpen,
    contentMode,
    selectedColor,
  }) => css`
    font-family: ${fontFamily.Montserrat};
    color: ${colors[contentMode].color};
    background-color: ${isOpen ? colors[contentMode].backgroundHover : colors[contentMode].background};

    &:hover,
    &:focus,
    &:active {
      outline: 0;
      background-color: ${colors[contentMode].backgroundHover};
      color: ${colors[contentMode].colorHover};
    }

    &:focus {
      box-shadow: inset 0 0 0 2px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:disabled {
      cursor: default;
    }

    ${contentMode === 'secondary' &&
    css`
      &.selected {
        background-color: ${colors[contentMode].backgroundHover};
        border-top: 0.1875em solid transparent;
        border-bottom: 0.1875em solid ${selectedColor};
      }
    `}
  `}
`

export const StyledChevron = styled(ChevronDownIcon)(
  ({
    theme: {
      xyz: { iconSize },
    },
  }) => css`
    width: ${iconSize.s}em;
    height: ${iconSize.s}em;
    margin-left: 0.5em;
    margin-right: -0.25em;
    flex-shrink: 0;
  `
)

export const Title = styled.span(
  ({
    theme: {
      xyz: { fontSize, fontWeight },
    },
  }) => css`
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;
    white-space: pre-wrap;
    pointer-events: none;

    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.body.three}em;
  `
)
