import React, { HTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import Square from '../../basic-components/Square'
import OutsideClick from '../../basic-components/OutsideClick'
import styled, { css } from 'styled-components'
import { color, fontSize, fontWeight } from '@postidigital/posti-theme'

const StyledContainer = styled.div`
  ${({
    theme: {
      xyz: { spacing, borderRadius },
    },
  }) => css`
    padding: ${spacing.space4}rem;
    border-radius: ${borderRadius.md}rem;
    box-shadow: 0 8px 22px rgba(27, 31, 35, 0.07);
    background: ${color.neutralWhite};
    width: 19.5rem;
    display: flex;
    flex-flow: row wrap;
    overflow-wrap: break-word;
    overflow: hidden;
  `}
`

const ServiceItemContainer = styled.a`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    flex: 1 1 33.33%;
    max-width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${color.neutralNetworkGray};
    font-size: 0.65rem;
    font-weight: ${fontWeight.bold};
    line-height: 1.2;
    padding: ${spacing.space2}rem;

    &:focus {
      outline: 1px solid ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
  `}
`

const ServiceItemLogo = styled(Square)<{ backgroundColor?: string }>(
  ({
    theme: {
      xyz: { spacing },
    },
    backgroundColor,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${backgroundColor};
    color: ${color.neutralWhite};
    font-size: ${fontSize.text.md}rem;
    margin-bottom: ${spacing.space2}rem;
  `
)

const ServiceItemTitle = styled.p`
  text-align: center;
  word-break: normal;
  hyphens: auto;
`

interface ServiceItemProps extends HTMLAttributes<HTMLAnchorElement> {
  title: string
  href: string
  icon?: JSX.Element
  squareColor?: string
  onClick?: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
}

const ServiceItem: React.FC<ServiceItemProps> = (props) => {
  const { href, title, squareColor = color.neutralNetworkGray, icon, onClick, ...rest } = props

  const handleItemClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (onClick) {
      e.preventDefault()
      onClick(e)
    }
  }

  return (
    <ServiceItemContainer href={href} onClick={handleItemClick} aria-label={title} {...rest}>
      <ServiceItemLogo backgroundColor={squareColor}>{icon || title.charAt(0).toUpperCase()}</ServiceItemLogo>
      <ServiceItemTitle>{title}</ServiceItemTitle>
    </ServiceItemContainer>
  )
}

export interface AccountOverlayProps extends HTMLAttributes<HTMLElement> {
  toggleOpen: (open) => void
  serviceItems?: ServiceItemProps[]
}

const AccountOverlay: React.FC<AccountOverlayProps> = (props) => {
  const { toggleOpen, serviceItems = [], ...rest } = props
  return (
    <OutsideClick onOutsideClick={() => toggleOpen(false)}>
      <StyledContainer role="menu" {...rest}>
        {serviceItems?.map((item, index) => (
          <ServiceItem
            role="menuitem"
            key={`${item.title}-${index}`}
            href={item.href}
            icon={item.icon}
            title={item.title}
            squareColor={item.squareColor}
            onClick={item.onClick}
          />
        ))}
      </StyledContainer>
    </OutsideClick>
  )
}

export const serviceItemType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.node,
  squareColor: PropTypes.string,
  onClick: PropTypes.func,
}) as Validator<ServiceItemProps>

AccountOverlay.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  serviceItems: PropTypes.arrayOf(serviceItemType),
}

/** @component */
export default AccountOverlay
