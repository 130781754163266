import React, { HTMLAttributes, CSSProperties } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { XyzTheme } from '@postidigital/posti-theme'
import { ChevronDownIcon } from '../../../design-tokens/icons'
import { useTheme } from '../../../utils/useTheme'
import { ThemeColor } from '../../../utils/helpers'

interface Props extends HTMLAttributes<HTMLDivElement> {
  open: boolean
  showChevron?: boolean
  alignChevronToCenter?: boolean
  chevronStyles?: CSSProperties
  chevronColor?: ThemeColor | string
  titleId: string
  bodyId: string
}

const ChevronWrapper = styled.div.attrs({ tabIndex: -1 })<{ alignChevronToCenter?: boolean }>(
  ({
    theme: {
      xyz: { spacing },
    },
    alignChevronToCenter,
  }) => css`
    display: flex;
    padding-left: ${spacing.space1}rem;
    padding-right: ${spacing.space3}rem;
    align-items: ${alignChevronToCenter ? 'center' : 'flex-start'};
  `
)

const Rotate = styled.div<{ open: boolean }>(
  ({
    theme: {
      xyz: { spacing },
    },
    open,
  }) => css`
    width: ${spacing.space4}rem;
    height: ${spacing.space4}rem;
    display: flex;
    transform: translate3d(0, 0, 0) rotate(${open ? -180 : 0}deg);
    transition: all 200ms ease;
  `
)

const TitleWrapper = styled.div`
  display: flex;
  outline: none;
  width: 100%;
  border: 0;
  text-align: left;
  cursor: pointer;
  justify-content: space-between;
  user-select: none;
`

const ContentWrapper = styled.div.attrs({ tabIndex: -1 })<{ showChevron: boolean }>`
  width: 100%;
  user-select: none;
`

const ExpanderTitle: React.FC<Props> = ({
  children,
  showChevron,
  alignChevronToCenter,
  open,
  bodyId,
  titleId,
  chevronStyles,
  chevronColor,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <TitleWrapper
      id={titleId}
      role="button"
      aria-pressed={open}
      aria-expanded={open}
      aria-controls={bodyId}
      tabIndex={0}
      {...rest}
    >
      <ContentWrapper showChevron={showChevron}>{children}</ContentWrapper>
      {showChevron && (
        <ChevronWrapper alignChevronToCenter={alignChevronToCenter} style={chevronStyles}>
          <Rotate open={open}>
            <ChevronDownIcon
              width={`${theme.xyz.iconSize.s}em`}
              height={`${theme.xyz.iconSize.s}em`}
              color={chevronColor}
            />
          </Rotate>
        </ChevronWrapper>
      )}
    </TitleWrapper>
  )
}

ExpanderTitle.defaultProps = {
  showChevron: true,
  alignChevronToCenter: true,
  chevronStyles: {},
  chevronColor: XyzTheme.color.neutralNetworkGray,
}

ExpanderTitle.propTypes = {
  open: PropTypes.bool.isRequired,
  showChevron: PropTypes.bool,
  alignChevronToCenter: PropTypes.bool,
  chevronStyles: PropTypes.object,
  chevronColor: PropTypes.string,
  titleId: PropTypes.string.isRequired,
  bodyId: PropTypes.string.isRequired,
}

export default ExpanderTitle
