import styled, { css } from 'styled-components'
import { HeaderContainer } from '../MonthPicker/MonthPicker.styles'
import { DatePickerProps } from './DatePicker.types'
import { Size } from '../../basic-components/Button/Button'

export const StyledHeaderContainer = styled(HeaderContainer)<Pick<DatePickerProps, 'pickerSize'>>`
  ${({
    theme: {
      xyz: { spacing },
    },
    pickerSize,
  }) => css`
    padding: 1.25rem 0.625rem;
    max-width: 21.375rem;
    ${pickerSize === Size.sm &&
    css`
      max-width: 17.625rem;
      padding: ${spacing.space3}rem ${spacing.space2}rem;
    `}
    ${pickerSize === Size.lg &&
    css`
      max-width: 24.25rem;
      padding: ${spacing.space4}rem 0.75rem;
    `}
  `}
`
export const calendarShadow = ({
  theme: {
    xyz: { borderRadius },
  },
}) => `
  border-radius: ${borderRadius.md}rem;
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.08),
    0px 3px 8px 0px rgba(0, 0, 0, 0.08), 0px 15px 22px 0px rgba(0, 0, 0, 0.04);
`

export const Container = styled.div<Pick<DatePickerProps, 'pickerSize' | 'monthsShown'>>(
  ({
    theme: {
      xyz: { borderRadius, color, spacing, fontWeight, fontSize, zIndex },
    },
    monthsShown,
    pickerSize,
  }) => css`
    input {
      &:not(:focus)::-webkit-input-placeholder {
        color: transparent;
      }
      &:not(:focus):-moz-placeholder {
        color: transparent;
      }
      &:not(:focus)::-moz-placeholder {
        color: transparent;
      }
      &:not(:focus):-ms-input-placeholder {
        color: transparent;
      }
    }
    .react-datepicker {
      display: flex;
      margin-top: 1rem;
      ${monthsShown > 1 && calendarShadow}
      &__month-container {
        background-color: ${color.neutralWhite};
        padding: 0 0 ${spacing.space2}rem;
        border-radius: ${borderRadius.md}rem;
        ${monthsShown === 1 && calendarShadow}
      }
      &__month {
        padding: 0.625rem 0 0;
        ${pickerSize === Size.sm &&
        css`
          padding: ${spacing.space2}rem 0 0;
        `}
        ${pickerSize === Size.lg &&
        css`
          padding: 0.75rem 0 0;
        `}
      }
      &__day,
      &__day-name {
        width: 2.875rem;
        height: 2.875rem;
        ${pickerSize === Size.sm &&
        css`
          width: 2.375rem;
          height: 2.375rem;
        `}
        ${pickerSize === Size.lg &&
        css`
          width: 3.25rem;
          height: 3.25rem;
        `}
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__day {
        border-radius: 50%;
        cursor: pointer;
        font-size: ${fontSize.headline.seven}rem;
        font-weight: ${fontWeight.semiBold};
        color: ${color.neutralNetworkGray};
        position: relative;
        z-index: 0;
        overflow: hidden;

        &:not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range) {
          &:focus {
            outline: none;
            border: 2px solid ${color.signalBlue};
          }

          &:focus:not(:focus-visible) {
            border: none;
          }
        }

        &:not(.react-datepicker__day--selected):not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
          &:after {
            content: '';
            width: 2.875rem;
            height: 2.875rem;
            ${pickerSize === Size.sm &&
            css`
              width: 2.375rem;
              height: 2.375rem;
            `}
            ${pickerSize === Size.lg &&
            css`
              width: 3.25rem;
              height: 3.25rem;
            `}
            position: absolute;
            border-radius: 50%;
            transition: 0.2s transform ease-in;
            transform: scale(0.9);
            z-index: -1;
          }
          &:hover {
            &:after {
              transform: scale(1);
              background-color: #e2e5e8;
            }
          }
          &:active {
            &:after {
              background-color: #cdd1d6;
            }
          }
        }
      }
      &__day-name {
        font-size: ${fontSize.headline.eight}rem;
        font-weight: ${fontWeight.medium};
        color: ${color.neutralBlack};
        height: 2.375rem;
        padding: 0 0.625rem;
        ${pickerSize === Size.sm &&
        css`
          padding: 0 ${spacing.space2}rem;
        `}
        ${pickerSize === Size.lg &&
        css`
          padding: 0 0.75rem;
          height: 2.625rem;
        `}
      }
      &__day--today {
        &:not(.react-datepicker__day--selected) {
          &:focus,
          &:focus:hover {
            border-color: ${color.signalBlue};
          }

          &:focus:not(:focus-visible) {
            border-color: ${color.neutralPassiveGray};
          }
          border: 2px solid ${color.neutralPassiveGray};
          &:hover {
            border: 2px solid #cdd1d6;
          }
          &:active {
            border: 2px solid #b0b8bf;
          }
        }
      }
      &__day--disabled {
        color: ${color.neutralPassiveGray};
        pointer-events: none;
      }

      &__day--range-start,
      &__day--range-end,
      &__day--selected {
        color: ${color.neutralWhite};
        z-index: 0;
      }

      &__day--in-range,
      &__day--in-selecting-range {
        border-radius: unset;
        &:focus {
          outline: none;
          box-shadow: none;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: calc(100% - 1px);
          z-index: ${zIndex.zIndexInvisible};
          background: linear-gradient(
              transparent 16%,
              ${color.signalLightBlue} 16%,
              ${color.signalLightBlue} 84%,
              transparent 84%
            )
            no-repeat;
        }
      }
      &__day--selecting-range-start,
      &__day--range-start {
        &:before {
          right: 0;
          left: auto;
          width: 50%;
        }
      }
      &__day--in-selecting-range:hover,
      &__day--selecting-range-end,
      &__day--range-end {
        &:before {
          width: 50%;
        }
      }

      &__day--selecting-range-end:hover,
      &__day--in-selecting-range.react-datepicker__day--keyboard-selected {
        &:before {
          width: 50%;
        }
      }

      &__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--selecting-range-end):focus:after,
      &__day--in-range:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end):focus:after {
        outline: none;
        border: 2px solid ${color.signalBlue};
        background-color: #e2e5e8;
        transform: scale(1);
      }

      &__day--selecting-range-start:focus:after,
      &__day--selecting-range-end:focus:after,
      &__day--range-start:focus:after,
      &__day--range-end:focus:after {
        outline: none;
        border: 2px solid ${color.signalLightBlue};
      }

      &__day--in-range:focus:not(:focus-visible):not(:active):after,
      &__day--range-start:focus:not(:focus-visible):not(:active):after,
      &__day--range-end:focus:not(:focus-visible):not(:active):after {
        box-shadow: none;
        border: none;
      }

      &__day--selected:after,
      &__day--selected:hover:after,
      &__day--range-start:after,
      &__day--range-start:hover:after,
      &__day--range-end:after,
      &__day--range-end:hover:after {
        content: '';
        width: 2.875rem;
        height: 2.875rem;
        ${pickerSize === Size.sm &&
        css`
          width: 2.375rem;
          height: 2.375rem;
        `}
        ${pickerSize === Size.lg &&
        css`
          width: 3.25rem;
          height: 3.25rem;
        `}
        position: absolute;
        background-color: ${color.signalBlue};
        border: 2px solid ${color.signalBlue};
        border-radius: 50%;
        z-index: -1;
      }
      &__day--range-start:hover:after,
      &__day--range-end:hover:after,
      &__day--selected:hover:after {
        background-color: #0044a3;
        border: 2px solid #0044a3;
      }
      &__day--range-start:active:after,
      &__day--range-end:active:after,
      &__day--selected:active:after {
        background-color: #003784;
        border: 2px solid #003784;
      }
      &__day--range-start:focus:not(.react-datepicker__day--in-range),
      &__day--range-end:focus:not(.react-datepicker__day--in-range),
      &__day--selected:focus:not(.react-datepicker__day--in-selecting-range) {
        border: none;
      }

      &__day--outside-month {
        opacity: 0;
        visibility: hidden;
      }
      &__week,
      &__day-names {
        display: flex;
        justify-content: space-evenly;
        padding: 0 0.625rem;
        width: 21.375rem;

        ${pickerSize === Size.sm &&
        css`
          width: 17.625rem;
          padding: 0 ${spacing.space2}rem;
        `}
        ${pickerSize === Size.lg &&
        css`
          width: 24.25rem;
          padding: 0 0.75rem;
        `}
      }
      &__week {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  `
)
