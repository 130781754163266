import React, { HTMLAttributes } from 'react'
import styled, { css, useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { LanguageIcon } from '../../design-tokens/icons/pictograph'
import { Headline, HeadlineSize } from '../../design-tokens/typography'
import { LanguageBox } from '../LanguageBox'

export interface LanguageProps {
  lang: string
  langAriaLabel: string
}

export interface LanguageSelectionProps extends HTMLAttributes<HTMLElement> {
  onLanguageToggle: (value: string) => void
  language: string
  languageLabel: string
  supportedLanguages: LanguageProps[]
  'aria-label': string
}

const Container = styled.div`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    flew-wrap: nowrap;
    padding: ${spacing.space3}rem;

    svg {
      flex-shrink: 0;
    }
  `}
`

const Header = styled(Headline)`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    margin: 0 ${spacing.space3}rem;
    width: ${spacing.space12}rem;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`

export const LanguageSelection: React.FC<LanguageSelectionProps> = ({
  languageLabel,
  supportedLanguages,
  language,
  onLanguageToggle,
  ...props
}) => {
  const theme = useTheme()
  return (
    <Container {...props} role="listbox">
      <LanguageIcon
        width={`${theme.xyz.iconSize.s}em`}
        height={`${theme.xyz.iconSize.s}em`}
        color={theme.xyz.color.neutralNetworkGray}
      />

      <Header size={HeadlineSize.Seven}>{languageLabel}:</Header>
      {supportedLanguages.map((value) => {
        return (
          <LanguageBox
            key={value.lang}
            selected={value.lang == language}
            text={value.lang}
            onClick={() => onLanguageToggle(value.lang)}
            aria-label={value.langAriaLabel}
          />
        )
      })}
    </Container>
  )
}

export const LanguagePropsType = PropTypes.shape({
  lang: PropTypes.string.isRequired,
  langAriaLabel: PropTypes.string.isRequired,
})

export const LanguageSelectionPropsType = PropTypes.shape({
  onLanguageToggle: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  languageLabel: PropTypes.string.isRequired,
  supportedLanguages: LanguagePropsType.isRequired,
  'aria-label': PropTypes.string.isRequired,
})
