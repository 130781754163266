import React, { useState, useCallback, useMemo, createContext, useEffect, useLayoutEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'

type Step = {
  name: string
}

type StepMap = {
  [key: string]: Step
}

type StepperContext = {
  currentStepIndex: number
  /** Steps are registered lazily and can be undefined */
  currentStep?: Step
  currentStepKey?: string
  stepCount: number
  registerStep: (stepId: string, step: Step) => void
  unregisterStep: (stepId: string) => void
  previous: () => void
  next: () => void
}

export function useStepper({ initialStepIndex = 0 }: { initialStepIndex: number }): StepperContext {
  const [activeStepIndex, setActiveStepIndex] = useState(Math.floor(initialStepIndex))
  const [steps, setSteps] = useState<StepMap>({})

  // process to next step
  const next = useCallback(() => {
    setActiveStepIndex((prevState) => Math.min(prevState + 1, Object.keys(steps).length))
  }, [steps])

  // process to previous step
  const previous = useCallback(() => {
    setActiveStepIndex((prevState) => Math.max(prevState - 1, 0))
  }, [])

  const registerStep = useCallback((stepId: string, step: Step) => {
    setSteps((currentSteps) => {
      return {
        ...currentSteps,
        [stepId]: {
          ...step,
        },
      }
    })
  }, [])
  const unregisterStep = useCallback((stepId: string) => {
    setSteps((currentSteps) => {
      const { [stepId]: omit, ...restOfSteps } = currentSteps
      return restOfSteps
    })
  }, [])

  useEffect(() => {
    if (initialStepIndex < 0) {
      console.warn('initialStepIndex has to be 0 or greater')
    }
  }, [initialStepIndex])

  return useMemo(() => {
    return {
      currentStepIndex: activeStepIndex,
      currentStepKey: Object.keys(steps)[activeStepIndex],
      currentStep: steps[Object.keys(steps)[activeStepIndex]],
      stepCount: Object.keys(steps).length,
      registerStep,
      unregisterStep,
      next,
      previous,
    }
  }, [activeStepIndex, next, previous, registerStep, unregisterStep, steps])
}

// Stepper Context

const StepperContext = createContext<StepperContext | undefined>(undefined)

export const useStepperContext = () => {
  const ctx = useContext(StepperContext)
  if (!ctx) {
    throw new Error('Cannot use StepperContext outside of a provider')
  }
  return ctx
}

// Stepper

export interface StepperProps {
  children: React.ReactNode | ((value: StepperContext) => React.ReactNode)
  initialStepIndex?: number
}

export const Stepper: React.FC<StepperProps> = (props) => {
  const internalState = useStepper({
    initialStepIndex: props.initialStepIndex,
  })

  return (
    <StepperContext.Provider value={{ ...internalState }}>
      {isFunction(props.children) ? props.children(internalState) : props.children}
    </StepperContext.Provider>
  )
}

// Step

type StepProps = {
  name: string
  stepId: string
}

export const Step: React.FC<StepProps> = ({ name, stepId, children }) => {
  const { currentStepKey, registerStep, unregisterStep } = useStepperContext()

  useLayoutEffect(() => {
    registerStep(stepId, {
      name,
    })
    return () => {
      unregisterStep(stepId)
    }
  }, [name, stepId, registerStep, unregisterStep])

  if (currentStepKey === stepId) {
    return <>{children}</>
  }
  return null
}

Step.propTypes = {
  name: PropTypes.string.isRequired,
  stepId: PropTypes.string.isRequired,
}

Stepper.propTypes = {
  initialStepIndex: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
}
