import styled, { css } from 'styled-components'

import { ButtonContainerProps, LabelProps } from './OldDropdown.types'
import { ChevronRightIcon, ProfileIcon } from '../../design-tokens/icons'
import { XyzTheme } from '@postidigital/posti-theme'
import OutsideClick from '../../basic-components/OutsideClick'

const lineHeight = XyzTheme.lineHeight.body.four

export const StyledDropdown = styled.div`
  display: inline-block;
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  width: 100%;
  height: 4.5rem;
`

export const StyledOutsideClick = styled(OutsideClick)`
  width: 100%;
`

export const selectedStateStyle = css`
  box-shadow: 0 0 0 0.1875rem transparent;

  &::before {
    opacity: 1;
  }
`

export const StyledButton = styled.button<ButtonContainerProps>`
  ${({
    theme: {
      xyz: { fontFamily, color, spacing, zIndex, borderRadius },
    },
    isOpen,
    isValid,
    lightBackground,
  }) => css`
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    cursor: pointer;
    border-radius: ${borderRadius.sm}rem;
    margin-bottom: 0.1875rem;
    width: 100%;
    outline: none;
    font-family: ${fontFamily.Montserrat};
    color: ${color.neutralNetworkGray};
    background: ${lightBackground ? color.neutralGray2 : color.neutralWhite};
    border: 0.1875rem solid ${!isValid ? color.signalRed : isOpen ? color.signalBlue : color.neutralGray2};
    padding: ${spacing.space4}rem;
    max-height: 4.5rem;

    ${isValid &&
    !isOpen &&
    css`
      box-shadow: 0px 2px 0px ${color.neutralPassiveGray};
    `}
    ${isOpen &&
    css`
      background: ${color.neutralOnHoverGray};
    `}

    &:active {
      background: ${color.neutralOnPressGray};
      border: 0.1875rem solid ${isOpen ? color.signalBlue : color.neutralGray2};
    }

    &:disabled {
      border-color: ${color.neutralGray5};
      background: ${color.neutralGray5};
      color: ${color.neutralPassiveGray};
      cursor: default;
    }

    &:hover {
      background: ${color.neutralOnHoverGray};
    }
    &:hover:not(:focus) {
      border-color: ${isOpen ? color.signalBlue : !isValid ? color.signalRed : color.neutralOnHoverGray};
    }

    &:focus {
      outline: none;
      border-color: ${color.signalBlue};
      box-shadow: 0px 0px 7px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      outline: none;
      box-shadow: none;
    }
  `}
`

export const StyledChevron = styled(ChevronRightIcon)<{ isOpen: boolean }>(
  ({
    theme: {
      xyz: { iconSize },
    },
    isOpen,
  }) => css`
    pointer-events: none;
    transform: ${isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'};
    transition: all 100ms ease;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
  `
)

export const Label = styled.label<LabelProps>(
  ({
    theme: {
      xyz: { color, fontWeight, fontSize },
    },
    isShrinked,
    disabled,
  }) => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    pointer-events: none;
    line-height: ${lineHeight};
    color: ${color.neutralNetworkGray};
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.label.four}rem;
    margin: 0;
    transition: font-size, font-weight, line-height 100ms ease;

    ${isShrinked &&
    css`
      line-height: 154%;
      font-size: ${fontSize.body.five}rem;
      font-weight: ${fontWeight.normal};
    `}

    ${disabled &&
    css`
      color: ${color.neutralPassiveGray};
    `}
  `
)

export const Title = styled.div<{ disabled: boolean }>(
  ({
    theme: {
      xyz: { color, fontWeight, fontSize },
    },
    disabled,
  }) => css`
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    line-height: ${lineHeight};

    color: ${color.neutralNetworkGray};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.label.four}rem;

    ${disabled &&
    css`
      color: ${color.neutralPassiveGray};
    `}
  `
)

export const ContentWrapper = styled.div<{ hasIcon: boolean; isShrinked: boolean }>(
  ({
    isShrinked,
    hasIcon,
    theme: {
      xyz: { spacing },
    },
  }) => css`
    display: block;
    padding: 0;
    margin: 0;
    pointer-events: none;
    height: ${lineHeight}rem;
    margin-right: ${spacing.space2}rem;
    width: ${hasIcon ? 'calc(100% - 2*1.5rem - 1rem)' : 'calc(100% - 1.5rem - 0.5rem)'};
    min-width: 10rem;
    overflow: hidden;

    ${isShrinked &&
    css`
      height: 2.625rem;
      align-items: flex-start;
      flex-direction: column;
    `}
  `
)

export const StyledIcon = styled(ProfileIcon)`
  ${({ theme }) => css`
    width: ${theme.xyz.iconSize.s}rem;
    height: ${theme.xyz.iconSize.s}rem;
    margin-right: ${theme.xyz.spacing.space3}rem;
  `}
`
