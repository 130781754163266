import React from 'react'
import PropTypes from 'prop-types'
import { CardContainer } from './CardInfo.styled'

export interface CreditCardInfoProps extends React.SVGAttributes<SVGElement> {
  cvcText?: string
  width?: string
  height?: string
}

/**
 * @deprecated Component is unrelated to posti-web-components and should be moved to its own application
 */
const CreditCardInfo: React.FC<CreditCardInfoProps> = ({ cvcText, width, height, ...props }) => {
  return (
    <CardContainer width={width} height={height} {...props} viewBox="0 0 292 212">
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <rect width="290" height="164" fill="#D7DBDD" stroke="#AAA" rx="12" />
        <path fill="#FFF" fillRule="nonzero" d="M1 17h288v35H1z" />
        <path fill="#F1F1F1" fillRule="nonzero" d="M12 62h158v23H12z" />
        <path fill="#FFF" fillRule="nonzero" d="M170 65h29v16h-29z" />
        <path
          fill="#394B58"
          fillRule="nonzero"
          d="M177.77 78h-1.424v-6.938h-1.693v-.931l3.117-.662V78zm9.025 0h-5.666v-.979l2.777-3.029c.46-.508.775-.917.943-1.227.168-.31.252-.62.252-.93 0-.39-.11-.712-.328-.966-.219-.254-.521-.38-.908-.38-.508 0-.881.137-1.12.412-.238.276-.357.665-.357 1.17h-1.424c0-.79.255-1.441.765-1.955.51-.513 1.222-.77 2.136-.77.836 0 1.488.222 1.957.668.469.445.703 1.04.703 1.787 0 .5-.135.967-.404 1.4-.27.434-.7.975-1.29 1.623l-1.927 2.04h3.89V78zm3-4.91h.99c.473 0 .808-.112 1.005-.337.198-.225.296-.55.296-.976 0-.418-.107-.737-.322-.958-.215-.22-.539-.33-.973-.33-.394 0-.712.108-.952.324-.24.217-.36.51-.36.876h-1.424c0-.683.252-1.245.756-1.684.504-.44 1.158-.66 1.963-.66.847 0 1.515.208 2.004.622.488.414.732 1.017.732 1.81 0 .371-.108.724-.325 1.058-.217.334-.536.597-.958.788.496.176.855.436 1.078.78.223.343.334.745.334 1.206 0 .797-.265 1.415-.794 1.855-.53.44-1.22.659-2.071.659-.805 0-1.473-.207-2.004-.621-.531-.414-.797-1.01-.797-1.787h1.424c0 .387.125.695.375.926.25.23.59.345 1.02.345.449 0 .798-.117 1.048-.351.25-.235.375-.574.375-1.02 0-.492-.114-.85-.343-1.072-.228-.223-.59-.334-1.086-.334h-.99v-1.12z"
        />
        <path
          stroke="#394A58"
          d="M22.362 80.978c-.588-35.889 3.618 19.072 7.462-12.408M30 77.232c1.224-1.39 1.873-3.73 3.672-4.17 2.39-.586 9.998 3.183 12.187 3.148 1.86-.03 3.043-2.576 4.9-2.668 1.388-.068 2.136 2.029 3.507 2.252l5.408-2.09c1.687.276 1.977 3.423 3.68 3.573 9.58.842 19.229-.474 28.843-.711"
        />
        <path stroke="#FF8000" strokeLinecap="square" d="M184.5 84v99.557" />
        <rect width="34" height="22" x="167.5" y="61.5" stroke="#FF8000" rx="6" />
      </g>
      <text x="140" y="202">
        {cvcText}
      </text>
    </CardContainer>
  )
}

CreditCardInfo.propTypes = {
  cvcText: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

CreditCardInfo.defaultProps = {
  cvcText: 'CVC security code',
  height: '100%',
  width: '100%',
}

export default CreditCardInfo
