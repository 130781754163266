import React, { useState } from 'react'
import PropTypes, { Validator } from 'prop-types'

import { SubMenuProps, MenuItemGroupProps } from './SubMenu.types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { StyledSubMenu, StyledSubMenuItem, StyledMenuItemGroup, StyledTitle, SubMenuContent } from './SubMenu.styles'
import { MenuItemContent, buttonTypeOption } from '../../basic-components/MenuItem'
import { HeadlineSize, LabelSize } from '../../design-tokens/typography'
import { MenuItemSubtitle, MenuItemLabel } from '../../index'

export const SubMenu: React.FC<SubMenuProps> = (props) => {
  const {
    icon: Icon,
    disabled,
    selected,
    chevronAriaLabel,
    open,
    role,
    onSelect,
    onButtonClick,
    label,
    subtitle,
    children,
    ...rest
  } = props

  const [visible, setVisible] = useState(open ?? false)

  const handleClick = () => {
    setVisible(!visible)
  }

  return (
    <StyledSubMenu {...rest} aria-current={selected ? 'page' : 'false'}>
      <StyledSubMenuItem>
        <MenuItemContent
          icon={Icon}
          buttonType={buttonTypeOption.bottomArrow}
          chevronAriaLabel={chevronAriaLabel}
          onSelect={onSelect}
          onButtonClick={handleClick}
          disabled={disabled}
          selected={selected}
          open={visible}
        >
          <MenuItemLabel size={HeadlineSize.Seven}>{label}</MenuItemLabel>
          {subtitle && <MenuItemSubtitle size={LabelSize.Six}>{subtitle}</MenuItemSubtitle>}
        </MenuItemContent>
      </StyledSubMenuItem>
      {visible && <SubMenuContent>{children}</SubMenuContent>}
    </StyledSubMenu>
  )
}

export const MenuItemGroup: React.FC<MenuItemGroupProps> = (props) => {
  const { title, children } = props

  return (
    <StyledMenuItemGroup>
      <StyledTitle size={HeadlineSize.Seven}>{title}</StyledTitle>
      {children}
    </StyledMenuItemGroup>
  )
}

SubMenu.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  selected: PropTypes.bool,
  chevronAriaLabel: PropTypes.string,
  open: PropTypes.bool,
  subtitle: PropTypes.string,
  role: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

SubMenu.defaultProps = {
  open: false,
  selected: false,
  disabled: false,
  role: 'menuitem',
}

MenuItemGroup.propTypes = {
  title: PropTypes.string,
}
