import React, { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import { ChevronRightIcon } from '../../design-tokens/icons'
import { DefaultIconProps, IconProps } from '../../design-tokens/icons/icons.types'
import { useTheme } from '../../utils/useTheme'
import { XyzTheme } from '@postidigital/posti-theme'
import { ButtonIconPosition } from '../Button/Button'
import { Headline, HeadlineSize } from '../../design-tokens/typography/Headline/Headline'

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-label': string
  iconColor?: string
  buttonLabel?: string
  iconPosition?: keyof typeof ButtonIconPosition
  icon?: React.ComponentType<DefaultIconProps>
}

const StyledIconButton = styled.button<Omit<IconButtonProps, 'icon'>>`
  ${({
    theme: {
      xyz: { zIndex, color, spacing },
    },
    buttonLabel,
    iconPosition,
  }) => css`
    display: flex;
    cursor: pointer;
    align-items: center;
    border-style: none;
    border-radius: 3rem;
    border: 1px solid transparent;
    background-clip: padding-box;
    position: relative;
    z-index: ${zIndex.zIndex0};
    background-color: transparent;
    padding: ${spacing.space1}rem;

    ${!!buttonLabel &&
    iconPosition === ButtonIconPosition.left &&
    css`
      padding: ${spacing.space2}rem ${spacing.space2}rem ${spacing.space2}rem ${spacing.space1}rem;
      span {
        margin: 0 0 0 ${spacing.space1}rem;
      }
    `}

    ${!!buttonLabel &&
    iconPosition === ButtonIconPosition.right &&
    css`
      padding: ${spacing.space2}rem ${spacing.space1}rem ${spacing.space2}rem ${spacing.space2}rem;
      span {
        margin: 0 ${spacing.space1}rem 0 0;
      }
    `}

    &:hover {
      background-color: ${color.neutralOnPressGray};
    }

    &:focus {
      outline: 0;
      border: 1px solid ${color.signalLightBlue};
      box-shadow: 0px 0px 7px ${color.signalBlue};
      background-color: ${color.neutralWhite};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: 1px solid transparent;
      background-color: ${color.neutralOnPressGray};
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      color: ${color.neutralPassiveGray};
      background-color: ${color.neutralGray2};
      span {
        color: ${color.neutralPassiveGray};
      }
    }
  `}
`

const StyledIcon = styled(ChevronRightIcon)<IconProps>`
  ${({
    theme: {
      xyz: { iconSize },
    },
  }) => css`
    flex-shrink: 0;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
  `}
`

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { icon: Icon, disabled, iconColor, buttonLabel, iconPosition, ...rest } = props
  const theme = useTheme()
  const color = disabled ? theme.xyz.color.neutralPassiveGray : iconColor

  return (
    <StyledIconButton
      ref={ref}
      aria-disabled={disabled}
      disabled={disabled}
      buttonLabel={buttonLabel}
      iconPosition={iconPosition}
      {...rest}
    >
      {!!buttonLabel && iconPosition === ButtonIconPosition.right && (
        <Headline as="span" size={HeadlineSize.Seven}>
          {buttonLabel}
        </Headline>
      )}
      {!!Icon && <StyledIcon as={Icon} color={color} aria-hidden={true} />}
      {!!buttonLabel && iconPosition === ButtonIconPosition.left && (
        <Headline as="span" size={HeadlineSize.Seven}>
          {buttonLabel}
        </Headline>
      )}
    </StyledIconButton>
  )
})

IconButton.displayName = 'IconButton'

IconButton.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  buttonLabel: PropTypes.string,
  iconPosition: PropTypes.oneOf(Object.values(ButtonIconPosition)),
}

IconButton.defaultProps = {
  iconColor: XyzTheme.color.neutralIconGray,
  buttonLabel: '',
  iconPosition: ButtonIconPosition.left,
}
