import React, { useState, useEffect, HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '../../basic-components/IconButton'
import ListItem from '../ListItem'
import { Headline, HeadlineSize } from '../../design-tokens/typography'
import OutsideClick from '../../basic-components/OutsideClick'
import { ChevronRightIcon, ArrowLeftIcon, LanguageIcon, CloseIcon } from '../../design-tokens/icons'
import { toLocalizedTitle } from '../../page-sections/TopBar/LanguageSelector'

import { useTheme } from '../../utils/useTheme'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import FocusLock, { AutoFocusInside } from 'react-focus-lock'
import {
  MobileMenuContainer,
  MobileMenuHeader,
  MobileMenuItem,
  TopLevelMobileMenuItem,
  MobileMenuContent,
  MobileMenuHeaderButtons,
  MobileMenuBreak,
  LocaleMenu,
  LocaleMenuItem,
} from './OldMobileMenu.styles'
import Button, { Mode, Size } from '../../basic-components/Button/Button'
import { handleNavItemClick, checkAriaCurrent } from '../../page-sections/Navigation/navigationItemUtils'
import { NavigationItemProps, NavigationItemType, NavigationStateType } from '../../page-sections/TopBar/TopBar.types'

export interface MobileMenuProps extends HTMLAttributes<HTMLDivElement> {
  showMobileMenu?: boolean
  zIndex?: number
  navigationState: {
    topLevelMenuItems?: NavigationItemProps[]
    rootMenuItems?: NavigationItemProps[]
    locales?: string[]
    currentLocale?: string
    currentPage?: NavigationItemProps
    rootPage?: NavigationItemProps
  }
  titleText?: string
  servicePointsItem?: NavigationItemProps
  localeMenuLabel?: string
  getCurrentMenuItem: (id: string) => any
  onCloseClick: () => void
  onLocaleSelect: (value) => void
  closeText: string
}

/**
 * OldMobileMenu Component
 * @deprecated new MobileMenu should be used instead
 */
const OldMobileMenu: React.FC<MobileMenuProps> = (props) => {
  const {
    showMobileMenu,
    onCloseClick,
    zIndex,
    navigationState,
    onLocaleSelect,
    titleText,
    servicePointsItem,
    getCurrentMenuItem,
    closeText,
    localeMenuLabel,
    ...rest
  } = props

  const { topLevelMenuItems, rootMenuItems, locales, currentPage, currentLocale, rootPage } = navigationState

  const [notRootPage, setNotRootPage] = useState(false)
  const [currentMenuPage, setCurrentMenuPage] = useState(currentPage || rootPage || null)

  const {
    xyz: { color },
  } = useTheme()

  useEffect(() => {
    if (rootPage && currentMenuPage) {
      setNotRootPage(!(currentMenuPage.title === rootPage.title && currentMenuPage.href === rootPage.href))
    }

    if (currentMenuPage) {
      const currentChildren = getCurrentMenuItem(currentMenuPage.id).children
      if (currentChildren && currentChildren.length < 1) {
        const parent = getCurrentMenuItem(currentMenuPage.id).parent
        setCurrentMenuPage(parent || rootPage)
      }
    }
  }, [showMobileMenu, currentMenuPage, getCurrentMenuItem, rootPage])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const key = e.key

    if (key === KeyboardKeys.Escape) {
      e.preventDefault()
      onCloseClick()
    }
  }

  const handleBackButtonClick = () => {
    const parent = getCurrentMenuItem(currentMenuPage.id).parent || rootPage
    setCurrentMenuPage(parent || rootPage)
  }

  const handleMenuArrowClick = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    setCurrentMenuPage(item)
  }

  const toMobileMenuItem = (item, index) => {
    const itemChildren = getCurrentMenuItem(item.id).children
    const rightContent =
      itemChildren && itemChildren.length > 0 ? (
        <Button
          aria-label="Child items"
          size={Size.sm}
          contentMode={Mode.secondary}
          onClick={(e) => handleMenuArrowClick(e, item)}
          icon={ChevronRightIcon}
          iconColor={color.neutralIconGray}
        />
      ) : null

    return (
      <TopLevelMobileMenuItem
        key={`${item.title}-${index}`}
        href={item.href}
        aria-current={checkAriaCurrent(item, currentPage)}
        onClick={(e) => {
          if (item.onClick) {
            e.preventDefault()
            item.onClick()
          }
        }}
      >
        <ListItem size="md" title={item.title} rightContent={rightContent} leftContent={item.icon} />
      </TopLevelMobileMenuItem>
    )
  }

  if (!showMobileMenu || !currentMenuPage) {
    return null
  }

  return (
    <OutsideClick onOutsideClick={onCloseClick}>
      <FocusLock>
        <MobileMenuContainer onKeyDown={handleKeyDown} zIndex={zIndex} aria-expanded={showMobileMenu} {...rest}>
          <MobileMenuHeader notRootPage={notRootPage}>
            <MobileMenuHeaderButtons notRootPage={notRootPage}>
              {notRootPage && (
                <Button
                  aria-label="Back"
                  size={Size.sm}
                  contentMode={Mode.secondary}
                  onClick={handleBackButtonClick}
                  icon={ArrowLeftIcon}
                  iconColor={color.neutralBlack}
                />
              )}
              <IconButton
                onClick={onCloseClick}
                aria-label={closeText}
                iconColor={color.neutralBlack}
                icon={CloseIcon}
              />
            </MobileMenuHeaderButtons>
            <Headline as="div" size={HeadlineSize.Five} color="neutralBlack">
              {notRootPage ? currentMenuPage.title : titleText}
            </Headline>
          </MobileMenuHeader>
          <AutoFocusInside>
            <MobileMenuContent>
              {notRootPage && getCurrentMenuItem(currentMenuPage.id).children
                ? getCurrentMenuItem(currentMenuPage.id).children.map(toMobileMenuItem)
                : topLevelMenuItems.map(toMobileMenuItem)}
              <MobileMenuBreak />
              {rootMenuItems.map((item, index) => (
                <MobileMenuItem
                  key={`${item.id}-${index}`}
                  href={item.href}
                  onClick={(e) => handleNavItemClick(item, e)}
                  aria-current={checkAriaCurrent(item, currentPage)}
                >
                  <ListItem size="sm" title={item.title} boldTitle={false} />
                </MobileMenuItem>
              ))}
              {servicePointsItem && (
                <MobileMenuItem href={servicePointsItem.href}>
                  <ListItem size="sm" title={servicePointsItem.title} boldTitle={false} />
                </MobileMenuItem>
              )}
              {locales.length > 0 && (
                <LocaleMenu role="group" aria-label={localeMenuLabel}>
                  <LanguageIcon width="1.8em" height="1.8em" />
                  {locales.map((locale, index) => (
                    <LocaleMenuItem
                      role="button"
                      key={`${locale}-${index}`}
                      selected={locale === currentLocale}
                      aria-pressed={locale === currentLocale}
                      aria-label={toLocalizedTitle(locale)}
                      onClick={() => onLocaleSelect(locale)}
                    >
                      {locale.toUpperCase()}
                    </LocaleMenuItem>
                  ))}
                </LocaleMenu>
              )}
            </MobileMenuContent>
          </AutoFocusInside>
        </MobileMenuContainer>
      </FocusLock>
    </OutsideClick>
  )
}

OldMobileMenu.defaultProps = {
  showMobileMenu: false,
  zIndex: 3,
  titleText: 'Menu',
  localeMenuLabel: 'Language selection',
  navigationState: {
    locales: [],
    topLevelMenuItems: [],
    rootMenuItems: [],
  },
}

OldMobileMenu.propTypes = {
  navigationState: NavigationStateType,
  showMobileMenu: PropTypes.bool,
  zIndex: PropTypes.number,
  onCloseClick: PropTypes.func.isRequired,
  onLocaleSelect: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  servicePointsItem: NavigationItemType,
  getCurrentMenuItem: PropTypes.func.isRequired,
  closeText: PropTypes.string.isRequired,
  localeMenuLabel: PropTypes.string,
}

/** @component */
export default OldMobileMenu
