import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { StyledProgressIndicator } from './ProgressIndicator.styles'
import { Placement } from '../../basic-components/Tooltip/Tooltip'
import { LabelSize } from '../../design-tokens/typography'

export enum StepSizeValues {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum LabelDirections {
  centred = 'centred',
  left = 'left',
}

export function setLabelSize(type: StepSizeValues | keyof typeof StepSizeValues) {
  switch (type) {
    case 'sm':
      return LabelSize.Six
    case 'md':
      return LabelSize.Five
    case 'lg':
      return LabelSize.Four
    default:
      return LabelSize.Six
  }
}

export interface ProgressIndicatorProps {
  vertical?: boolean
  activeStep?: number
  tooltipPlacement?: keyof typeof Placement
  stepSize: keyof typeof StepSizeValues
  highlightColor?: string
  labelDirection?: keyof typeof LabelDirections
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  vertical,
  stepSize,
  activeStep,
  tooltipPlacement,
  highlightColor,
  labelDirection,
  children,
}) => {
  return (
    <StyledProgressIndicator
      vertical={vertical}
      stepSize={stepSize}
      tooltipPlacement={tooltipPlacement}
      activeStep={activeStep}
      data-design-system-id="progress-indicator"
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<any>, {
          labelDirection: labelDirection ? labelDirection : LabelDirections.left,
          highlightColor: highlightColor,
        })
      )}
    </StyledProgressIndicator>
  )
}

ProgressIndicator.propTypes = {
  vertical: PropTypes.bool,
  stepSize: PropTypes.oneOf(Object.keys(StepSizeValues)) as Validator<StepSizeValues>,
  activeStep: PropTypes.number,
  tooltipPlacement: PropTypes.oneOf(Object.keys(Placement)) as Validator<Placement>,
  highlightColor: PropTypes.string,
  labelDirection: PropTypes.oneOf(Object.values(LabelDirections)) as Validator<LabelDirections>,
}

ProgressIndicator.defaultProps = {
  vertical: false,
  stepSize: StepSizeValues.sm,
  activeStep: 0,
}
