import styled, { css } from 'styled-components'
import { InboxIcon } from '../../design-tokens/icons'
import { Headline, Label } from '../../design-tokens/typography'
import { IconButton } from '../IconButton'
import { getColor } from './utils'
import { ContentProps, MenuItemContentProps, buttonTypeOption } from './MenuItem.types'

export const StyledMenuItem = styled.li(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    display: block;
    margin: ${spacing.space1}rem 0;
  `
)

export const StyledMenuItemContent = styled.div<ContentProps>(
  ({
    theme: {
      xyz: { color, spacing },
    },
    selected,
    disabled,
  }) => css`
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 2.5rem;
    cursor: pointer;
    width: 100%;
    color: ${selected ? color.neutralWhite : color.neutralNetworkGray};
    background-color: ${selected ? color.brandBrightYellow : color.neutralWhite};
    padding: ${spacing.space3}rem;

    &:hover {
      background-color: ${selected ? color.brandBrightYellow : color.neutralOnHoverGray};
    }

    &:active {
      background-color: ${selected ? color.brandBrightYellow : color.neutralOnPressGray};
    }

    &:focus {
      outline: 0;
      box-shadow: 0px 0px 7px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    ${disabled &&
    css`
      cursor: default;
      pointer-events: none;
      background-color: ${color.neutralGray5};
    `}

    ${MenuItemSubtitle}, ${MenuItemLabel} {
      color: ${getColor({ selected, disabled })};
    }
  `
)

export const MenuItemLabel = styled(Headline)`
  overflow: hidden;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const MenuItemWrapper = styled.div<{ hasIcon: boolean; buttonType: keyof typeof buttonTypeOption }>(
  ({ hasIcon, buttonType }) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(
      100% -
        ${buttonType === buttonTypeOption.bottomArrow || buttonType === buttonTypeOption.rightArrow
          ? '2.125rem'
          : '0rem'} - ${hasIcon ? '1.5rem' : '0rem'}
    );
  `
)
export const MenuItemSubtitle = styled(Label)`
  display: block;
  padding: 0;
  margin-top: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`

export const StyledButton = styled(IconButton)<Pick<MenuItemContentProps, 'open'> & { hasSubItems: boolean }>(
  ({ open, hasSubItems }) => css`
    position: absolute;
    right: 1rem;
    ${hasSubItems && `transform: rotate(${open ? 180 : 0}deg);`}
    margin-left: auto;
  `
)

export const StyledIcon = styled(InboxIcon)(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    width: ${spacing.space4}rem;
    height: ${spacing.space4}rem;
    flex-shrink: 0;
    margin-right: ${spacing.space3}rem;
  `
)
