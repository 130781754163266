import styled, { css } from 'styled-components'

import Text from '../typography/Text'
import Title from '../typography/Title'
import { RatingTextProps, HeadingTextProps, CenteredProp, ItemImageContainerProps } from './FeedbackPanel.type'

export const StyledHeadingText = styled(Text)<HeadingTextProps>(
  ({ theme, centered }) => css`
    margin-bottom: ${theme.spacing.xs}rem;
    font-weight: ${theme.fontWeight.normal};
    ${centered && `text-align: center;`}
  `
)

export const StyledItemsContainer = styled.div<{ centered: boolean }>`
  display: flex;
  justify-content: ${({ centered }) => (centered ? 'center' : 'left')};
`

export const StyledItemContainer = styled.div`
  position: relative;
`

export const StyledItemImageContainer = styled.div<ItemImageContainerProps>(
  ({ theme, clicked }) => css`
    margin: auto;
    margin: 0.75rem;
    box-sizing: content-box;
    width: ${theme.size.icon.l}rem;
    height: ${theme.size.icon.l}rem;
    position: relative;
    outline: none;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      background-color: ${theme.color.neutralOnPressGray};
      transition: all 200ms ease-in;
      left: 0;
      top: 0.1rem;
      right: 0.1rem;
      bottom: 0.2rem;
      z-index: -1;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 200ms ease-in;
    }

    ${!clicked &&
    `
      &:focus:before {
        left: -0.5rem;
        top: -0.4rem;
        right: -0.4rem;
        bottom: -0.3rem;
      }
    `}

    &:not(:hover):not(:focus) {
      svg:first-of-type {
        opacity: 0;
      }
      svg:last-of-type {
        opacity: 1;
      }
    }

    &:hover,
    &:focus {
      svg:first-of-type {
        opacity: 1;
      }
      svg:last-of-type {
        opacity: 0;
      }
    }

    ${clicked &&
    `
      svg {
        transform: scale(1.3);
      }
    `}
  `
)

export const StyledRatingText = styled(Text)<RatingTextProps>(
  ({ theme, clicked }) => css`
    margin-top: 0.25rem;
    text-align: center;

    ${clicked &&
    `
      font-weight: ${theme.fontWeight.semiBold};
  `}
  `
)

export const StyledThankYouTitle = styled(Title)<CenteredProp>(
  ({ theme, centered }) => css`
    margin-bottom: ${theme.spacing.sm}rem;
    ${centered && `text-align: center;`}
  `
)

export const StyledImageContainer = styled.div<CenteredProp>(
  ({ theme, centered }) => css`
    margin-bottom: ${theme.spacing.lg}rem;
    ${centered && `text-align: center;`}

    svg {
      height: 9rem;
    }
  `
)

export const StyledThankYouBody = styled(Text)<CenteredProp>`
  ${({ centered }) => centered && `text-align: center;`}
`
