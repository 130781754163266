import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes, { Validator } from 'prop-types'

import { useTheme } from '../../utils/useTheme'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { OldDropdownProps } from './OldDropdown.types'
import {
  StyledDropdown,
  StyledButton,
  Label,
  Title,
  StyledChevron,
  StyledIcon,
  ContentWrapper,
  StyledOutsideClick,
} from './OldDropdown.style'
import { Menu } from '../../basic-components/Menu'
import { randomId } from '../../utils/helpers'
import { UIMessage } from '../../basic-components/UIMessage'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'

/**
 *
 * @deprecated component is deprecated.
 */
const OldDropdown: React.FC<OldDropdownProps> = (props) => {
  const {
    isOpen,
    title,
    label,
    id,
    onToggle,
    onSelect,
    disabled,
    children,
    isValid,
    message,
    zIndex,
    lightBackground,
    menuId,
    multiselect,
    menuAriaLabel,
    overflowMenuContainer,
    overflowMenuContainerDirection,
    icon: Icon,
    iconColor,
    maxMenuHeight,
    isClosingOnScroll,
    ...rest
  } = props
  const theme = useTheme()
  const [fallbackId] = useState(randomId())
  const [isVisibleOnScroll, setIsVisibleOnScroll] = useState(true)

  const menuRef = useRef<HTMLUListElement>()
  const buttonRef = useRef<HTMLButtonElement>()
  const dropdownRef = useRef<HTMLDivElement>()
  const scrollPositionRef = useRef<number>()

  const isMenuVisible = isClosingOnScroll ? isVisibleOnScroll && isOpen : isOpen
  const isLabelShrinked = isMenuVisible || Boolean(title)

  const handleOpen = () => {
    scrollPositionRef.current = window.scrollY
    setIsVisibleOnScroll(true)

    if (!isOpen) {
      onToggle()
      menuRef.current?.focus({ preventScroll: true })
    }
  }

  const handleClose = useCallback(() => {
    if (isOpen) {
      onToggle()

      if (buttonRef && buttonRef.current) {
        buttonRef.current?.focus()
      }
    }
  }, [isOpen, onToggle])

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()
      handleOpen()
    }
  }

  const handleSelect = (value: string) => {
    if (!multiselect) {
      handleClose()
    }

    onSelect(value)
  }

  const listenToScroll = useCallback(() => {
    const scroll = window.scrollY

    if (scrollPositionRef.current !== scroll && isClosingOnScroll) {
      setIsVisibleOnScroll(false)
      handleClose()
    }
  }, [handleClose, isClosingOnScroll])

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [listenToScroll])

  return (
    <StyledOutsideClick onOutsideClick={handleClose} {...rest}>
      <StyledDropdown ref={dropdownRef}>
        <StyledButton
          type="button"
          id={id || fallbackId}
          ref={buttonRef}
          aria-haspopup="listbox"
          aria-expanded={isMenuVisible}
          isOpen={isMenuVisible}
          onClick={isMenuVisible ? handleClose : handleOpen}
          onKeyPress={onKeyPress}
          disabled={disabled}
          lightBackground={lightBackground}
          isValid={isValid}
        >
          {!!Icon && (
            <StyledIcon
              as={Icon}
              color={disabled ? theme.xyz.color.neutralPassiveGray : iconColor}
              aria-hidden={true}
            />
          )}
          <ContentWrapper hasIcon={!!Icon} isShrinked={isLabelShrinked}>
            <Label isShrinked={isLabelShrinked} htmlFor={id || fallbackId} disabled={disabled} hasIcon={!!Icon}>
              {label}
            </Label>
            {title && <Title disabled={disabled}>{title}</Title>}
          </ContentWrapper>
          <StyledChevron
            isOpen={isMenuVisible}
            width="100%"
            height="100%"
            color={disabled ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray}
          />
        </StyledButton>
        <Menu
          id={menuId}
          isOpen={isMenuVisible}
          onClose={handleClose}
          exclusions={[id || fallbackId]}
          aria-multiselectable={multiselect}
          zIndex={zIndex}
          aria-label={menuAriaLabel}
          ref={menuRef}
          overflowMenuContainer={overflowMenuContainer}
          overflowMenuContainerDirection={overflowMenuContainerDirection}
          isInDropdown
          maxMenuHeight={maxMenuHeight}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child as React.ReactElement<any>, { onSelect: handleSelect })
          )}
        </Menu>
        {message && <UIMessage success={isValid} message={message} />}
      </StyledDropdown>
    </StyledOutsideClick>
  )
}

OldDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  id: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  message: PropTypes.string,
  zIndex: PropTypes.number,
  lightBackground: PropTypes.bool,
  menuId: PropTypes.string,
  multiselect: PropTypes.bool,
  menuAriaLabel: PropTypes.string.isRequired,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  isClosingOnScroll: PropTypes.bool,
}

OldDropdown.defaultProps = {
  title: '',
  disabled: false,
  isOpen: false,
  zIndex: 400,
  lightBackground: true,
  multiselect: false,
  isValid: true,
  isClosingOnScroll: false,
}

export default OldDropdown
