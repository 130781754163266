import 'react-dates/initialize'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { SingleDatePicker } from 'react-dates'
import dayjs from 'dayjs'
import { StyledCalendarIcon, DateInputContent, StyledUIMessage } from './DateInput.style'
import { MonthNavigationButton } from '../../composite-components/MonthPicker/MonthNavigationButton'
import { LabelAndInputContainer, StyledLabel } from '../../basic-components/Input/Input.style'
import { DateInputProps } from './DateInput.types'
import defaultPhrases from 'react-dates/lib/defaultPhrases'

export const DEFAULT_LOCALE = 'fi'
export const DEFAULT_DAY_SIZE = 48
export const DEFAULT_DATE_FORMAT = 'D.M.YYYY'
export const overriddenPhrases = {
  ...defaultPhrases,
  jumpToNextMonth: undefined,
  jumpToPrevMonth: undefined,
  keyboardForwardNavigationInstructions: undefined,
  keyboardBackwardNavigationInstructions: undefined,
}

/** DateInput Component
 * @deprecated Use DatePicker instead
 */

export const DateInput: React.FC<DateInputProps> = ({
  id,
  label,
  isInvalid,
  message,
  messageProps,
  locale,
  leftTemplate,
  rightTemplate,
  className,
  lightBackground,
  prevMonthButtonAriaLabel,
  nextMonthButtonAriaLabel,
  'aria-describedby': ariaDescribedBy,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>()
  const inputRef = useRef<HTMLInputElement>()
  const [hasValue, setHasValue] = useState(Boolean(props.date))
  const [focused, setFocused] = useState(Boolean(props.focused))

  const messageId = `${id}-message`
  const describedByElements = [ariaDescribedBy, messageId].filter(Boolean)

  useEffect(() => {
    if (!inputRef.current) {
      inputRef.current = containerRef.current.querySelector('.DateInput_input')
    }
    dayjs.locale(locale)
  }, [locale])

  useEffect(() => {
    setHasValue(Boolean(props.date || inputRef.current.value))
  }, [props.date])

  useEffect(() => {
    setFocused(Boolean(props.focused))
  }, [props.focused])

  useEffect(() => {
    inputRef.current.setAttribute('aria-disabled', `${Boolean(props.disabled)}`)
    inputRef.current.setAttribute('aria-readonly', `${Boolean(props.readOnly)}`)
    inputRef.current.setAttribute('aria-invalid', `${Boolean(isInvalid)}`)
    inputRef.current.setAttribute(
      'aria-describedby',
      `${describedByElements.length ? describedByElements.join(' ') : ''}`
    )
  }, [props.disabled, props.readOnly, isInvalid, describedByElements])

  const onFocusChange = (value: { focused: boolean }) => {
    if (props.readOnly || props.disabled) {
      return
    }
    setFocused(value.focused)
    props.onFocusChange?.(value)
  }

  const onDateChange = (value: moment.Moment) => {
    setHasValue(Boolean(value || inputRef.current.value))
    props.onDateChange?.(value, inputRef.current.value)
  }

  const focus = () => {
    inputRef.current.select()
    if (!focused) {
      onFocusChange({ focused: true })
    }
  }

  const leftElement = leftTemplate
  const rightElement = rightTemplate || <StyledCalendarIcon disabled={props.disabled} />

  return (
    <div ref={containerRef} className={className}>
      <DateInputContent
        singleDatePicker
        onClick={focus}
        invalid={isInvalid}
        focused={focused}
        hasMessage={Boolean(message)}
        readOnly={props.readOnly}
        disabled={props.disabled}
        lightBackground={lightBackground}
        daySize={props.daySize}
        numberOfMonths={props.numberOfMonths}
      >
        {leftElement}
        <LabelAndInputContainer
          hasLeftElement={Boolean(leftElement)}
          hasRightElement={Boolean(rightElement)}
          hasValue={hasValue}
          focused={focused}
        >
          <StyledLabel htmlFor={id} focused={focused} hasValue={hasValue} disabled={props.disabled}>
            {label}
          </StyledLabel>
          <SingleDatePicker
            id={id}
            hideKeyboardShortcutsPanel={true}
            noBorder={true}
            navPrev={<MonthNavigationButton orientation={'left'} aria-label={prevMonthButtonAriaLabel} />}
            navNext={<MonthNavigationButton orientation={'right'} aria-label={nextMonthButtonAriaLabel} />}
            horizontalMonthPadding={0}
            horizontalMargin={0}
            placeholder=""
            phrases={overriddenPhrases}
            {...props}
            focused={focused}
            onFocusChange={onFocusChange}
            onDateChange={onDateChange}
          />
        </LabelAndInputContainer>
        {rightElement}
      </DateInputContent>
      {message && (
        <div aria-live="polite" id={messageId}>
          <StyledUIMessage success={!isInvalid} message={message} {...messageProps} />
        </div>
      )}
    </div>
  )
}

DateInput.defaultProps = {
  displayFormat: DEFAULT_DATE_FORMAT,
  locale: DEFAULT_LOCALE,
  lightBackground: true,
  daySize: DEFAULT_DAY_SIZE,
  numberOfMonths: 1,
}

DateInput.propTypes = {
  displayFormat: PropTypes.string,
  locale: PropTypes.string,
  isInvalid: PropTypes.bool,
  message: PropTypes.string,
  messageProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  leftTemplate: PropTypes.element,
  rightTemplate: PropTypes.element,
  className: PropTypes.string,
  lightBackground: PropTypes.bool,
  prevMonthButtonAriaLabel: PropTypes.string.isRequired,
  nextMonthButtonAriaLabel: PropTypes.string.isRequired,
}
