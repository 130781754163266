import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { useTheme } from '../../utils/useTheme'
import { StyledIndicatorStep, StyledContainer, StyledLabel } from './IndicatorStep.styles'
import { StepSizeValues, LabelDirections } from '../ProgressIndicator/ProgressIndicator'
import { CheckmarkIcon, WarningIndicatorIcon } from '../../design-tokens/icons'
import Tooltip from '../Tooltip'
import { Placement } from '../Tooltip/Tooltip'

export enum StepStateValues {
  activated = 'activated',
  completed = 'completed',
  warning = 'warning',
}

export interface IndicatorStepProps {
  id?: string
  stepState?: keyof typeof StepStateValues
  optional?: boolean
  vertical?: boolean
  labelDirection?: keyof typeof LabelDirections
  stepSize?: keyof typeof StepSizeValues
  tooltipTitle?: React.ReactNode
  tooltipId?: string
  tooltipPlacement?: keyof typeof Placement
  highlightColor?: string
}

export const IndicatorStep: React.FC<IndicatorStepProps> = ({
  id,
  stepState,
  stepSize,
  vertical,
  tooltipTitle,
  tooltipId,
  tooltipPlacement,
  highlightColor,
  labelDirection,
  children,
}) => {
  const theme = useTheme()

  const indicatorStep = (
    <StyledIndicatorStep
      stepState={stepState}
      stepSize={stepSize ? stepSize : StepSizeValues.sm}
      vertical={vertical}
      aria-labelledby={id}
      highlightColor={highlightColor}
    >
      {stepState === StepStateValues.completed && (
        <CheckmarkIcon width="0.625rem" height="0.625rem" color={theme.xyz.color.neutralWhite} />
      )}
      {stepState === StepStateValues.warning && (
        <WarningIndicatorIcon width="1rem" height="1rem" color={theme.xyz.color.neutralWhite} />
      )}
    </StyledIndicatorStep>
  )

  return (
    <StyledContainer
      id={id}
      stepSize={stepSize}
      stepState={stepState}
      vertical={vertical}
      data-design-system-id="indicator-step"
      highlightColor={highlightColor}
      labelDirection={labelDirection ? labelDirection : LabelDirections.left}
    >
      {tooltipTitle ? (
        <Tooltip id={tooltipId} title={tooltipTitle} placement={tooltipPlacement}>
          {indicatorStep}
        </Tooltip>
      ) : (
        indicatorStep
      )}
      {!!children && <StyledLabel>{children}</StyledLabel>}
    </StyledContainer>
  )
}

IndicatorStep.propTypes = {
  id: PropTypes.string,
  stepState: PropTypes.oneOf(Object.keys(StepStateValues)) as Validator<StepStateValues>,
  stepSize: PropTypes.oneOf(Object.keys(StepSizeValues)) as Validator<StepSizeValues>,
  vertical: PropTypes.bool,
  optional: PropTypes.bool,
  tooltipId: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(Object.keys(Placement)) as Validator<Placement>,
  highlightColor: PropTypes.string,
  labelDirection: PropTypes.oneOf(Object.values(LabelDirections)) as Validator<LabelDirections>,
}

IndicatorStep.defaultProps = {
  optional: false,
  vertical: false,
  stepSize: StepSizeValues.sm,
}
