import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { DefaultIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'
import { useTheme } from '../../../utils/useTheme'

interface ChatBubbleIconProps extends DefaultIconProps {
  disabled?: boolean
}

export const ChatBubbleIcon: React.FC<ChatBubbleIconProps> = (props) => {
  const theme = useTheme()
  return props.disabled ? (
    <SVG {...props}>
      <Path
        fillRule="evenodd"
        d="M8 0.5C3.58172 0.5 0 4.08172 0 8.5V15.0807C0 19.499 3.58172 23.0807 8 23.0807H16.7144L22.2857 28.5V21.8371C24.5185 20.4179 26 17.9223 26 15.0807V8.5C26 4.08172 22.4183 0.5 18 0.5H8Z"
        fill={theme.xyz.color.neutralPassiveGray}
      />
    </SVG>
  ) : (
    <SVG {...props}>
      <Path
        fillRule="evenodd"
        d="M8 0.5C3.58172 0.5 0 4.08172 0 8.5V15.0807C0 19.499 3.58172 23.0807 8 23.0807H16.7144L22.2857 28.5V21.8371C24.5185 20.4179 26 17.9223 26 15.0807V8.5C26 4.08172 22.4183 0.5 18 0.5H8Z"
        fill={theme.xyz.color.brandPink}
      />
    </SVG>
  )
}

ChatBubbleIcon.defaultProps = {
  viewBox: '0 0 26 29',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}
