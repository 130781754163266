import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { XyzTheme } from '@postidigital/posti-theme'
import Button, { Size } from '../Button/Button'
import Loading from '../Loading'
import { LoadingAnimationPresetColor } from '../Loading/Loading'
import { getPadding } from './utils'
import { useTheme } from '../../utils/useTheme'

export enum ProgressButtonMode {
  primary = 'primary',
  secondary = 'secondary',
}

export type ProgressButtonProps = {
  contentMode?: keyof typeof ProgressButtonMode
  size?: keyof typeof Size
  disabled?: boolean
  label: string
}

export const buttonColors = {
  primary: {
    color: XyzTheme.color.neutralWhite,
    background: XyzTheme.color.signalBlue,
  },
  secondary: {
    color: XyzTheme.color.neutralNetworkGray,
    background: XyzTheme.color.neutralGray2,
  },
}

const StyledButton = styled(Button)<ProgressButtonProps>(
  ({ contentMode, size }) => css`
    color: ${buttonColors[contentMode].color};
    background-color: ${buttonColors[contentMode].background};
    padding: 0;

    & > span {
      padding: ${getPadding({ size })};
    }
  `
)

const StyledLoadingIcon = styled(Loading)(
  ({
    theme: {
      xyz: { iconSize, spacing },
    },
  }) => css`
    flex-shrink: 0;
    margin-left: ${spacing.space4}rem;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
  `
)

export const ProgressButton: React.FC<ProgressButtonProps> = ({ contentMode, disabled, ...rest }) => {
  const theme = useTheme()
  const color = disabled ? theme.xyz.color.neutralPassiveGray : theme.xyz.color.neutralWhite
  const backgroundColor = disabled ? theme.xyz.color.neutralPassiveGray : buttonColors[contentMode].background
  const loadingIconProps =
    contentMode === ProgressButtonMode.primary || disabled
      ? { color, backgroundColor }
      : { preset: LoadingAnimationPresetColor.brandPink }

  return (
    <StyledButton contentMode={contentMode} disabled={disabled} {...rest}>
      {rest.label}
      <StyledLoadingIcon {...loadingIconProps} />
    </StyledButton>
  )
}

ProgressButton.propTypes = {
  contentMode: PropTypes.oneOf(Object.values(ProgressButtonMode)),
  size: PropTypes.oneOf(Object.values(Size)),
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

ProgressButton.defaultProps = {
  contentMode: ProgressButtonMode.primary,
  size: Size.md,
  disabled: false,
}
