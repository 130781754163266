import React from 'react'

import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'

import { MenuItemProps, MenuItemContentProps, buttonTypeOption } from './MenuItem.types'
import { MenuItemWrapper, StyledButton, StyledIcon, StyledMenuItem, StyledMenuItemContent } from './MenuItem.style'
import { getColor } from './utils'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { ChevronDownIcon, ChevronRightIcon } from '../../design-tokens/icons'
import { useTheme } from '../../utils/useTheme'

export const MenuItemContent: React.FC<MenuItemContentProps> = (props) => {
  const {
    icon: Icon,
    disabled,
    selected,
    onSelect,
    onButtonClick,
    value,
    chevronAriaLabel,
    buttonType,
    open,
    children,
    ...rest
  } = props
  const theme = useTheme()

  const hasIcon = !!Icon
  const iconProps = {
    width: `${theme.xyz.iconSize.s}em`,
    height: `${theme.xyz.iconSize.s}em`,
    color: getColor({ selected, disabled }),
  }

  const handleButtonKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()
      e.stopPropagation()
      onButtonClick(value)
    }
  }

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onButtonClick(value)
  }

  return (
    <StyledMenuItemContent disabled={disabled} selected={selected} tabIndex={0}>
      {hasIcon && <StyledIcon {...iconProps} as={Icon} aria-hidden={true} />}
      <MenuItemWrapper hasIcon={hasIcon} buttonType={buttonType}>
        {children}
      </MenuItemWrapper>
      {(buttonType === buttonTypeOption.bottomArrow || buttonType === buttonTypeOption.rightArrow) && (
        <StyledButton
          aria-disabled={disabled}
          hasSubItems={buttonType === buttonTypeOption.bottomArrow}
          open={open}
          onKeyDown={handleButtonKeyPress}
          onClick={handleButtonClick}
          aria-label={chevronAriaLabel}
          icon={buttonType === buttonTypeOption.bottomArrow ? ChevronDownIcon : ChevronRightIcon}
          iconColor={
            disabled
              ? theme.xyz.color.neutralPassiveGray
              : selected
              ? theme.xyz.color.neutralNetworkGray
              : theme.xyz.color.neutralIconGray
          }
        />
      )}
    </StyledMenuItemContent>
  )
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const {
    id,
    icon: Icon,
    disabled,
    selected,
    onSelect,
    onButtonClick,
    role,
    value,
    buttonType,
    chevronAriaLabel,
    children,
    ...rest
  } = props

  const handleClick = () => {
    onSelect(value)
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()

      onSelect(value)
    }
  }

  return (
    <StyledMenuItem
      {...rest}
      id={id}
      role={role}
      aria-disabled={disabled}
      aria-current={selected ? 'page' : 'false'}
      onKeyDown={handleKeyPress}
      onClick={handleClick}
    >
      <MenuItemContent
        icon={Icon}
        buttonType={buttonType}
        value={value}
        selected={selected}
        disabled={disabled}
        onSelect={onSelect}
        onButtonClick={onButtonClick}
        chevronAriaLabel={chevronAriaLabel}
      >
        {children}
      </MenuItemContent>
    </StyledMenuItem>
  )
}

MenuItem.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  role: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  chevronAriaLabel: PropTypes.string,
}

MenuItem.defaultProps = {
  selected: false,
  disabled: false,
  role: 'menuitem',
}
