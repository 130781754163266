import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { SearchWithSuggestionsProps } from './SearchWithSuggestions.types'
import { Search } from '../../basic-components/Search'
import { DropdownMenuItem } from '../../basic-components/DropdownMenuItem'
import { Menu } from '../../basic-components/Menu'
import { DropdownMenuItemProps } from '../../basic-components/DropdownMenuItem/DropdownMenuItem'
import OutsideClick from '../../basic-components/OutsideClick'
import { KeyboardKeys } from '../../utils/keyboardNavigation'

export type MySuggestionType = DropdownMenuItemProps & { label?: string }

export const Container = styled.div`
  position: relative;
`
/**
 * @deprecated please use Dropdown (with SearchWithSuggestions functionality) component instead
 */
const SearchWithSuggestions = React.forwardRef<HTMLInputElement, SearchWithSuggestionsProps>(
  (
    {
      onItemSelect,
      suggestions,
      inputAriaLabel,
      menuAriaLabel,
      menuZIndex,
      alwaysShowSuggestions,
      clearButtonProps,
      onChange,
      onFocus,
      ...props
    },
    forwardRef
  ) => {
    const localRef = useRef<HTMLInputElement>()
    const inputRef = (forwardRef as React.MutableRefObject<HTMLInputElement>) || localRef
    const menuRef = useRef<HTMLUListElement>(null)
    const optionListId = `${props.id}-menu`
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
    const [hasSuggestions, setHasSuggestions] = useState<boolean>(false)
    const showSuggestions =
      isMenuOpen && alwaysShowSuggestions ? hasSuggestions : isMenuOpen && !!props.value && hasSuggestions

    useEffect(() => {
      setHasSuggestions(suggestions?.length > 0)
    }, [suggestions])

    const handleMenuClose = (e: React.KeyboardEvent) => {
      const key = e.key
      inputRef.current.focus()

      if (key === KeyboardKeys.Escape || key === KeyboardKeys.Tab) {
        setMenuOpen(false)
      }
    }

    const openMenu = () => {
      if (!suggestions?.length) {
        return
      }

      const firstChild = menuRef?.current?.children[0] as HTMLLIElement

      if (firstChild) {
        firstChild.focus()
      }

      setMenuOpen(true)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isMenuOpen) {
        openMenu()
        inputRef.current.focus()
      }

      if (typeof onChange !== 'undefined') {
        onChange(e)
      }
    }

    const selectMenuItem = (value: any) => {
      inputRef.current.focus()

      onItemSelect(value)
      setMenuOpen(false)
    }

    const focusToFirstSuggestion = (e: React.KeyboardEvent<HTMLElement>) => {
      if (menuRef?.current) {
        e.preventDefault()

        const list = menuRef.current
        const children: HTMLLIElement[] = Array.prototype.slice.call(list.childNodes)
        children[0].focus()
      }
    }

    const clearKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      const key = e.key

      if (key === KeyboardKeys.Tab) {
        if (e.shiftKey) {
          inputRef.current.focus()
        } else {
          e.persist()

          focusToFirstSuggestion(e)
        }
      }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      const key = e.key

      if (key === KeyboardKeys.ArrowDown) {
        focusToFirstSuggestion(e)
      }

      if (key === KeyboardKeys.Tab && !Boolean(props.value) && !e.shiftKey) {
        focusToFirstSuggestion(e)
      }
    }

    const outsideClick = () => {
      setMenuOpen(false)
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (typeof onFocus !== 'undefined') {
        onFocus(e)
      }
    }

    return (
      <OutsideClick onOutsideClick={outsideClick}>
        <Container aria-live="polite" aria-expanded={showSuggestions} aria-owns={optionListId}>
          <Search
            role="combobox"
            autoComplete="off"
            spellCheck={false}
            aria-invalid={false}
            aria-label={inputAriaLabel}
            {...props}
            ref={inputRef}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            clearButtonProps={{
              onKeyDown: clearKeyDown,
              id: `${optionListId}-clear`,
              ...clearButtonProps,
            }}
          />
          {showSuggestions && (
            <Menu
              id={`${optionListId}`}
              aria-label={menuAriaLabel}
              exclusions={['menu-btn', `${optionListId}-clear`]}
              isOpen={showSuggestions}
              onClose={handleMenuClose}
              ref={menuRef}
              isInDropdown
              zIndex={menuZIndex}
            >
              {suggestions.map((suggestion: MySuggestionType, key: number) => (
                <DropdownMenuItem
                  {...suggestion}
                  role="option"
                  aria-label={suggestion.label}
                  tabIndex={-1}
                  onSelect={selectMenuItem}
                  key={key}
                />
              ))}
            </Menu>
          )}
        </Container>
      </OutsideClick>
    )
  }
)

SearchWithSuggestions.displayName = 'SearchWithSuggestions'

SearchWithSuggestions.defaultProps = {
  ...Search.defaultProps,
  alwaysShowSuggestions: false,
  suggestions: undefined,
  inputAriaLabel: undefined,
  menuAriaLabel: undefined,
  menuZIndex: undefined,
}

SearchWithSuggestions.propTypes = {
  ...Search.propTypes,
  id: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.any),
  inputAriaLabel: PropTypes.string,
  menuAriaLabel: PropTypes.string,
  menuZIndex: PropTypes.number,
  alwaysShowSuggestions: PropTypes.bool,
  onItemSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default SearchWithSuggestions
