import styled, { css } from 'styled-components'
import { IndicatorStepProps, StepStateValues } from './IndicatorStep'
import { StepSizeValues, LabelDirections } from '../ProgressIndicator/ProgressIndicator'

export const StyledIndicatorStep = styled.div<Omit<IndicatorStepProps, 'tooltipTitle' | 'tooltipId' | 'placement'>>(
  ({
    theme: {
      xyz: { color },
    },
    stepSize,
    stepState,
    highlightColor,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: ${stepState === StepStateValues.activated || stepState === StepStateValues.completed
      ? highlightColor
        ? `3px solid ${highlightColor}`
        : `3px solid ${color.signalBlue}`
      : `3px solid ${color.neutralPassiveGray}`};
    background: ${color.neutralPassiveGray};
    box-sizing: border-box;
    padding: 0;

    ${stepSize === StepSizeValues.sm &&
    css`
      width: 1rem;
      height: 1rem;
    `}

    ${stepSize === StepSizeValues.md &&
    css`
      width: 1.25rem;
      height: 1.25rem;

      svg {
        width: 1rem;
        height: 1rem;
      }
    `}

    ${stepSize === StepSizeValues.lg &&
    css`
      width: 1.5rem;
      height: 1.5rem;

      svg {
        width: 1.125rem;
        height: 1.125rem;
      }
    `}

    ${stepState === StepStateValues.completed &&
    css`
      background: ${highlightColor ? highlightColor : color.signalBlue};
    `}
    ${stepState === StepStateValues.activated &&
    css`
      background: ${color.neutralWhite};
    `}
    ${stepState === StepStateValues.warning &&
    css`
      background: ${color.brandBrightYellow};
      border: none;
      svg {
        ${stepSize === StepSizeValues.md &&
        css`
          width: 1.25rem;
          height: 1.25rem;
        `}
        ${stepSize === StepSizeValues.lg &&
        css`
          width: 1.5rem;
          height: 1.5rem;
        `}
          circle:first-child {
          display: none;
        }
      }
    `}

    &:focus {
      outline: none;
      ${stepState !== undefined &&
      css`
        box-shadow: 0 0 0.4375rem ${color.signalBlue};

        &:not(:focus-visible) {
          box-shadow: none;
        }
      `}
    }
  `
)

export const StyledLabel = styled.span`
  display: block;
`

export const StyledContainer = styled.li.attrs({ 'aria-current': 'step' })<{
  vertical?: boolean
  stepSize?: keyof typeof StepSizeValues
  stepState?: keyof typeof StepStateValues
  highlightColor?: string
  labelDirection?: keyof typeof LabelDirections
}>(
  ({
    theme: {
      xyz: { color, spacing, fontWeight },
    },
    vertical,
    stepSize,
    stepState,
    highlightColor,
    labelDirection,
  }) => css`
    list-style: none;
    position: relative;
    width: 100%;
    min-height: auto;

    ${stepSize === StepSizeValues.sm &&
    css`
      min-width: 4.5rem;
    `}

    ${stepSize === StepSizeValues.md &&
    css`
      min-width: 6rem;
    `}

    ${stepSize === StepSizeValues.lg &&
    css`
      min-width: 8.5rem;
    `}

    ${vertical &&
    css`
      display: flex;

      ${stepSize === StepSizeValues.sm &&
      css`
        min-height: 3.5rem;
      `}

      ${stepSize === StepSizeValues.md &&
      css`
        min-height: 5rem;
      `}

      ${stepSize === StepSizeValues.lg &&
      css`
        min-height: 6.5rem;
      `}
    `}

    ${stepSize === StepSizeValues.lg &&
    css`
      &:last-child {
        min-height: auto;
      }
    `}

    &:not(:last-child):after {
      content: '';
      position: absolute;
      border-radius: 9999px;
      border: ${stepState === StepStateValues.completed
        ? highlightColor
          ? `1px solid ${highlightColor}`
          : `1px solid ${color.signalBlue}`
        : `1px solid ${color.neutralPassiveGray}`};
      background-color: ${stepState === StepStateValues.completed
        ? highlightColor
          ? highlightColor
          : color.signalBlue
        : color.neutralPassiveGray};

      ${!vertical
        ? css`
            height: 1px;
            min-width: 1rem;

            ${stepSize === StepSizeValues.sm &&
            css`
              width: calc(100% - 1rem - 0.25rem);
              left: 1.125rem;
              top: calc((1rem - 3px) / 2);
            `}

            ${stepSize === StepSizeValues.md &&
            css`
              width: calc(100% - 1.25rem - 0.25rem);
              left: 1.375rem;
              top: calc((1.25rem - 3px) / 2);
            `}

            ${stepSize === StepSizeValues.lg &&
            css`
              width: calc(100% - 1.5rem - 0.25rem);
              left: 1.625rem;
              top: calc((1.5rem - 3px) / 2);
            `}
          `
        : css`
            width: 1px;
            min-height: 1rem;
            max-height: 6.5rem;

            ${stepSize === StepSizeValues.sm &&
            css`
              height: calc(100% - 1rem - 0.25rem);
              left: calc((1rem - 3px) / 2);
              top: 1.125rem;
            `}

            ${stepSize === StepSizeValues.md &&
            css`
              height: calc(100% - 1.25rem - 0.25rem);
              left: calc((1.25rem - 3px) / 2);
              top: 1.375rem;
            `}

            ${stepSize === StepSizeValues.lg &&
            css`
              height: calc(100% - 1.5rem - 0.25rem);
              left: calc((1.5rem - 3px) / 2);
              top: 1.625rem;
            `}
          `}
    }

    &:last-child,
    &:last-child:after {
      min-height: auto;
    }

    ${StyledLabel} {
      margin: ${vertical ? `0 0 0 ${spacing.space3}rem` : `${spacing.space2}rem ${spacing.space2}rem 0 0`};
      ${vertical &&
      css`
        max-width: 15rem;
      `}

      ${!vertical &&
      css`
        display: flex;
        align-items: center;
        ${(stepSize === StepSizeValues.md || stepSize === StepSizeValues.lg) &&
        css`
          margin-top: 1.25rem;
        `}

        ${labelDirection === LabelDirections.centred &&
        css`
          text-align: center;
          margin-right: 0;

          ${stepSize === StepSizeValues.sm &&
          css`
            margin-left: calc((1rem + 0.25rem - 100%));
          `}
          ${stepSize === StepSizeValues.md &&
          css`
            margin-left: calc((1.25rem + 0.25rem - 100%));
          `}
          ${stepSize === StepSizeValues.lg &&
          css`
            margin-left: calc((1.5rem + 0.25rem - 100%));
          `}
        `}
      `}

      p {
        margin: 0;
      }

      label {
        display: block;
        ${stepState === StepStateValues.activated &&
        css`
          font-weight: ${fontWeight.semiBold};
        `}

        ${vertical &&
        css`
          ${stepSize === StepSizeValues.sm &&
          css`
            line-height: 1rem;
          `}

          ${stepSize === StepSizeValues.md &&
          css`
            line-height: 1.25rem;
          `}

          ${stepSize === StepSizeValues.lg &&
          css`
            line-height: 1.5rem;
          `}
        `}
        
        ${!vertical &&
        labelDirection === LabelDirections.centred &&
        css`
          width: 100%;
          margin: 0 auto;

          ${stepSize === StepSizeValues.sm &&
          css`
            max-width: 4.25rem;
          `}

          ${stepSize === StepSizeValues.md &&
          css`
            max-width: 6rem;
          `}

          ${stepSize === StepSizeValues.lg &&
          css`
            max-width: 7.5rem;
          `}
        `}
      }
    }
  `
)
