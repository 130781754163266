import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { useTheme } from '../../utils/useTheme'
import { BinIcon } from '../../design-tokens/icons'
import { Body, BodySize } from '../../design-tokens/typography'
import { ButtonIconPosition } from '../Button/Button'
import { FileList, StyledFile, StyledButton, StyledDocumentIcon } from './FilesDisplay.styles'

export interface FilesDisplayProps {
  files: File[]
  disabled: boolean
  removeFile: (fileName: string) => void
  removeFileLabel?: string
  /**
   * Show Remove label next to bin icon
   */
  showRemoveLabel?: boolean
  /**
   * Toggle bin icon left or right if showRemoveLabel is enabled
   */
  removeIconPosition: keyof typeof ButtonIconPosition
}

const FilesDisplay: React.FC<FilesDisplayProps> = ({
  files,
  disabled,
  removeFile,
  removeFileLabel,
  showRemoveLabel,
  removeIconPosition,
}) => {
  const theme = useTheme()

  return (
    <FileList>
      {files.map((file, index) => {
        const removeFileAriaLabel = `${removeFileLabel} ${file.name}`

        return (
          <StyledFile key={index} isDisabled={disabled}>
            <div>
              <StyledDocumentIcon color={theme.xyz.color.brandCardboard} aria-hidden={true} />
              <Body size={BodySize.Four} as="span">
                {file.name}
              </Body>
            </div>
            <StyledButton
              onClick={() => removeFile(file.name)}
              disabled={disabled}
              contentMode="secondary"
              hasBackground={false}
              size="sm"
              icon={BinIcon}
              iconColor={theme.xyz.color.neutralIconGray}
              iconPosition={removeIconPosition}
              aria-label={removeFileAriaLabel}
              type="button"
            >
              {showRemoveLabel ? removeFileLabel : null}
            </StyledButton>
          </StyledFile>
        )
      })}
    </FileList>
  )
}

FilesDisplay.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      lastModified: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired as Validator<File[]>,
  disabled: PropTypes.bool.isRequired,
  removeFile: PropTypes.func.isRequired,
  removeFileLabel: PropTypes.string,
  showRemoveLabel: PropTypes.bool,
  removeIconPosition: PropTypes.oneOf(Object.values(ButtonIconPosition)).isRequired,
}

export { FilesDisplay }
