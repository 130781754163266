import { HTMLAttributes } from 'react'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'

export enum buttonTypeOption {
  rightArrow = 'rightArrow',
  bottomArrow = 'bottomArrow',
  noButton = 'noButton',
}

export interface MenuItemProps extends Omit<HTMLAttributes<HTMLLIElement>, 'onSelect' | 'tabIndex'> {
  icon?: React.ComponentType<DefaultIconProps>
  onSelect: (value: string | number) => void
  onButtonClick?: (value: string | number) => void
  /**
   * Value is passed when menu item is selected
   */
  value: string | number
  disabled?: boolean
  selected?: boolean
  role?: string
  buttonType?: keyof typeof buttonTypeOption
  chevronAriaLabel?: string
}

export type ContentProps = Pick<MenuItemProps, 'disabled' | 'selected'>

export type MenuItemContentProps = Omit<MenuItemProps, 'role' | 'value'> & {
  value?: string | number
  open?: boolean
  chevronAriaLabel?: string
}
