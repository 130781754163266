import React, { HTMLAttributes, useEffect, useRef, useState } from 'react'
import { animated, Spring } from 'react-spring'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { XyzTheme } from '@postidigital/posti-theme'
import { ThemeColor } from '../../utils/helpers'
import { useTheme } from '../../utils/useTheme'

export enum LoadingAnimationSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum LoadingAnimationPresetColor {
  brandPink = 'brandPink',
  brandRed = 'brandRed',
  brandPetrol = 'brandPetrol',
  brandNavy = 'brandNavy',
  brandForest = 'brandForest',
  neutralNetworkGray = 'neutralNetworkGray',
}

const presets: { [key in LoadingAnimationPresetColor]: string } = {
  brandPink: XyzTheme.color.brandPurple,
  brandRed: XyzTheme.color.brandCardboard,
  brandPetrol: XyzTheme.color.brandMutedYellow,
  brandNavy: XyzTheme.color.brandPink,
  brandForest: XyzTheme.color.brandCardboard,
  neutralNetworkGray: XyzTheme.color.brandPeach,
}

const sizes: { [key in LoadingAnimationSize]: number } = {
  [LoadingAnimationSize.xs]: 1,
  [LoadingAnimationSize.sm]: 2,
  [LoadingAnimationSize.md]: 3,
  [LoadingAnimationSize.lg]: 4,
  [LoadingAnimationSize.xl]: 5,
}

export interface LoadingProps extends HTMLAttributes<HTMLOrSVGElement> {
  /**
   * The color of the animation. Using a preset will override this
   */
  color?: ThemeColor | string
  /**
   * Preconfigured color set which will set both the primary and secondary
   * colors according to the brand
   */
  preset?: keyof typeof LoadingAnimationPresetColor
  /**
   * The background color of the animation (circle). Defaults to transparent.
   * If a preset is specified then this will be matched to the brand color theme
   */
  backgroundColor?: ThemeColor | string
  /**
   * The size of the animation
   */
  size?: keyof typeof LoadingAnimationSize
  /**
   * Status text that will be shown for screen reader users. If this is not provided, use e.g. aria-hidden="true" to hide the whole control
   */
  statusText?: string
}

// prettier-ignore
const SVG = styled.svg<LoadingProps>(
  ({ size }) => css`
    position: relative;
    /* Use em units because FF doesn't like rem units in svgs */
    width: ${sizes[size]}em;
    height: ${sizes[size]}em;
  `
)

/**
 * Show the content only in screen readers
 * https://stackoverflow.com/questions/26032089/in-html-how-can-i-have-text-that-is-only-accessible-for-screen-readers-i-e-fo
 * https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
 */
const SROnlyText = styled.p`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`

const config = { mass: 1, tension: 150, friction: 15, velocity: 0 }

const Loading: React.FC<LoadingProps> = ({ color, preset, backgroundColor, statusText, ...restProps }) => {
  const theme = useTheme()
  // Set the default preset if neither coloring option is provided
  const colorPreset = !preset && !color ? 'brandPink' : preset

  const singleColorMode = color && !colorPreset
  const primaryColor = colorPreset ? theme.xyz.color[colorPreset] : color
  const secondaryColor = singleColorMode ? color : presets[colorPreset]
  const background = colorPreset ? theme.xyz.color.neutralGray2 : backgroundColor
  const [run, setRun] = useState(true)

  const shouldUpdateState = useRef(true)
  let timer
  let innerTimer

  useEffect(() => {
    return () => {
      shouldUpdateState.current = false
      clearTimeout(timer)
      clearTimeout(innerTimer)
    }
  }, [innerTimer, timer])

  const onRest = () => {
    timer = setTimeout(() => {
      if (shouldUpdateState.current) {
        setRun(false)
        innerTimer = setTimeout(() => {
          shouldUpdateState.current && setRun(true)
        }, 1000)
      }
    }, 500)
  }

  return (
    <>
      {statusText && <SROnlyText>{statusText}</SROnlyText>}
      <SVG viewBox="0 0 36 36" role="status" {...restProps}>
        <path
          fill={background}
          d="M31.85,10A16.14,16.14,0,0,0,26,4.15a15.92,15.92,0,0,0-16,0A16.14,16.14,0,0,0,4.15,10a15.92,15.92,0,0,0,0,16A16.14,16.14,0,0,0,10,31.85a15.92,15.92,0,0,0,16,0A16.14,16.14,0,0,0,31.85,26a15.92,15.92,0,0,0,0-16ZM23.49,27.52a10.92,10.92,0,0,1-11,0,11.12,11.12,0,0,1-4-4,10.92,10.92,0,0,1,0-11,11.12,11.12,0,0,1,4-4,10.92,10.92,0,0,1,11,0,11.12,11.12,0,0,1,4,4,10.92,10.92,0,0,1,0,11A11.12,11.12,0,0,1,23.49,27.52Z"
        />
        {run && (
          <>
            <Spring
              from={{ rotate: 0, opacity: 0 }}
              to={[
                { rotate: 245, opacity: singleColorMode ? 0.9 : 1 },
                { rotate: 360, opacity: 0 },
              ]}
              config={config}
            >
              {({ opacity, rotate }) => (
                <animated.path
                  style={{ opacity }}
                  transform={rotate.to((deg) => `rotate(${deg}, 18, 18)`)}
                  d="M31.85,10A16.14,16.14,0,0,0,26,4.15,15.89,15.89,0,0,0,18,2V7a11,11,0,0,1,5.49,1.48,11.12,11.12,0,0,1,4,4,10.92,10.92,0,0,1,0,11L31.85,26a15.92,15.92,0,0,0,0-16Z"
                  fill={primaryColor}
                />
              )}
            </Spring>
            <Spring
              from={{ rotate: 0, opacity: 0 }}
              to={[
                { rotate: 155, opacity: singleColorMode ? 0.8 : 1 },
                { rotate: 360, opacity: 0 },
              ]}
              delay={400}
              config={config}
            >
              {({ opacity, rotate }) => (
                <animated.path
                  style={{ opacity }}
                  transform={rotate.to((deg) => `rotate(${deg}, 18, 18)`)}
                  d="M31.85,10A16.14,16.14,0,0,0,26,4.15,15.89,15.89,0,0,0,18,2V7a11,11,0,0,1,5.49,1.48,11.12,11.12,0,0,1,4,4A11,11,0,0,1,29,18h5A15.89,15.89,0,0,0,31.85,10Z"
                  fill={secondaryColor}
                />
              )}
            </Spring>
            <Spring
              from={{ rotate: 0, opacity: 0 }}
              to={[
                { rotate: 65, opacity: singleColorMode ? 0.7 : 1 },
                { rotate: 360, opacity: 0 },
              ]}
              delay={800}
              config={config}
            >
              {({ opacity, rotate }) => (
                <animated.path
                  style={{ opacity }}
                  transform={rotate.to((deg) => `rotate(${deg}, 18, 18)`)}
                  d="M31.85,10A16.14,16.14,0,0,0,26,4.15,15.89,15.89,0,0,0,18,2V7a11,11,0,0,1,5.49,1.48,11.12,11.12,0,0,1,4,4A11,11,0,0,1,29,18h5A15.89,15.89,0,0,0,31.85,10Z"
                  fill={primaryColor}
                />
              )}
            </Spring>
            <Spring
              from={{ rotate: 0, opacity: 0 }}
              to={[
                { rotate: 35, opacity: singleColorMode ? 0.6 : 1 },
                { rotate: 340, opacity: 0 },
              ]}
              delay={1000}
              config={config}
            >
              {({ opacity, rotate }) => (
                <animated.path
                  style={{ opacity }}
                  transform={rotate.to((deg) => `rotate(${deg}, 18, 18)`)}
                  d="M23.49,8.48,26,4.15A15.89,15.89,0,0,0,18,2V7A11,11,0,0,1,23.49,8.48Z"
                  fill={secondaryColor}
                />
              )}
            </Spring>
            <Spring
              from={{ rotate: 0, opacity: 0 }}
              to={[
                { rotate: 5, opacity: singleColorMode ? 0.5 : 1 },
                { rotate: 320, opacity: 0 },
              ]}
              delay={1200}
              config={config}
              onRest={onRest}
            >
              {({ opacity, rotate }) => (
                <animated.path
                  style={{ opacity }}
                  transform={rotate.to((deg) => `rotate(${deg}, 18, 18)`)}
                  d="M23.49,8.48,26,4.15A15.89,15.89,0,0,0,18,2V7A11,11,0,0,1,23.49,8.48Z"
                  fill={primaryColor}
                />
              )}
            </Spring>
          </>
        )}
      </SVG>
    </>
  )
}

Loading.propTypes = {
  color: PropTypes.string,
  preset: PropTypes.oneOf(Object.values(LoadingAnimationPresetColor)),
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(Object.values(LoadingAnimationSize)),
  statusText: PropTypes.string,
}

Loading.defaultProps = {
  backgroundColor: 'transparent',
  size: LoadingAnimationSize.md,
}

export default Loading
