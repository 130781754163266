import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { ChevronRightIcon } from '../../design-tokens/icons'
import { XyzTheme } from '@postidigital/posti-theme'
import { ThemeColor } from '../../utils/helpers'
import { useTheme } from '../../utils/useTheme'

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  textColor?: ThemeColor | string
  chevronColor?: ThemeColor | string
  borderHoverColor?: ThemeColor | string
  backgroundColor?: ThemeColor | string
}

const Rotate = styled.div<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transform-origin: 50% 50%;
  transition: transform 100ms ease;
  margin-left: 0.25rem;
  display: inline-block;
`

const TagDropdownButton = styled.button<ButtonProps>`
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: left;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  border-radius: 2rem;
  ${({
    theme: {
      xyz: { color, fontFamily },
    },
    textColor,
    borderHoverColor,
    backgroundColor,
  }) => css`
    color: ${color[textColor] || textColor};
    border: 1px solid ${color.neutralPassiveGray};
    font-family: ${fontFamily.Montserrat};
    background: ${color[backgroundColor] || backgroundColor};

    &:hover,
    &:focus {
      border-color: ${color[borderHoverColor] || borderHoverColor};
      outline: 0;
    }
  `}
`

const Label = styled.span`
  width: calc(100% - 1rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({
    theme: {
      xyz: { fontFamily, fontSize, fontWeight },
    },
  }) => css`
    font-family: ${fontFamily.Montserrat};
    font-weight: ${fontWeight.normal};
    font-size: ${fontSize.body.three}rem;
  `}
`

interface ToggleProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  onClick: (e: React.MouseEvent) => void
  isOpen: boolean
  label: string
  textColor?: ThemeColor | string
  chevronColor?: ThemeColor | string
  borderHoverColor?: ThemeColor | string
  backgroundColor?: ThemeColor | string
}

const FilterTagDropdownToggle: React.FC<ToggleProps> = (props) => {
  const { chevronColor, isOpen, label, ...rest } = props
  const theme = useTheme()
  return (
    <TagDropdownButton aria-haspopup={true} {...rest}>
      <Label>{label}</Label>
      <Rotate isOpen={isOpen}>
        <ChevronRightIcon
          width={`${theme.xyz.iconSize.xs}em`}
          height={`${theme.xyz.iconSize.xs}em`}
          color={chevronColor}
        />
      </Rotate>
    </TagDropdownButton>
  )
}

FilterTagDropdownToggle.defaultProps = {
  textColor: XyzTheme.color.neutralNetworkGray,
  chevronColor: XyzTheme.color.neutralNetworkGray,
  borderHoverColor: XyzTheme.color.signalBlue,
  backgroundColor: XyzTheme.color.neutralGray2,
}

FilterTagDropdownToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  chevronColor: PropTypes.string,
  borderHoverColor: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default FilterTagDropdownToggle
