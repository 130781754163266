import React from 'react'
import { DefaultIconProps } from '../icons.types'
import { SVG, Rect, Path } from '../icons.styled'
import { XyzTheme } from '@postidigital/posti-theme'
import { useTheme } from '../../../utils/useTheme'

export const AdIcon: React.FC<DefaultIconProps> = (props) => {
  const theme = useTheme()
  return (
    <SVG {...props}>
      <Rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill={theme.xyz.color.neutralWhite}
        stroke={theme.xyz.color.neutralPassiveGray}
      />
      <Path
        fillRule="nonzero"
        d="M12.0166 4.20338C12.0166 3.92724 11.7928 3.70338 11.5166 3.70338L7.01665 3.70338C6.74051 3.70338 6.51665 3.92724 6.51665 4.20338C6.51665 4.47953 6.74051 4.70338 7.01665 4.70338L11.0166 4.70338L11.0166 8.70338C11.0166 8.97953 11.2405 9.20338 11.5166 9.20338C11.7928 9.20338 12.0166 8.97953 12.0166 8.70338L12.0166 4.20338ZM4.35355 12.0736L11.8702 4.55694L11.1631 3.84983L3.64645 11.3665L4.35355 12.0736Z"
        fill={theme.xyz.color.neutralPassiveGray}
      />
    </SVG>
  )
}

AdIcon.defaultProps = {
  viewBox: '0 0 16 16',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}
