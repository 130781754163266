import React, { FC, HTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import styled, { css } from 'styled-components'
import { Theme } from '@postidigital/posti-theme'
import { InvoiceInboxIcon, LetterInboxIcon, ParcelInboxIcon, ParcelIncomingInboxIcon } from '../../design-tokens/icons'
import Square from '../../basic-components/Square'
import Text from '../typography/Text'
import { TextSize } from '../../utils/helpers'

export enum Type {
  letter = 'letter',
  invoice = 'invoice',
  parcel = 'parcel',
  parcelIncoming = 'parcelIncoming',
}

const LogoHolder = styled.div<{ size: string }>(
  ({ size }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${size};
  `
)

export interface Props extends HTMLAttributes<HTMLElement> {
  type?: keyof typeof Type
  text?: string
  backgroundImageUrl?: string
  size?: string
  contentColor?: string
  textSize?: TextSize
}

const InboxIcon: FC<{ type: keyof typeof Type; size: string; text: string }> = ({ type, size, text }) => {
  const letter = text.slice(0, 1)

  switch (type) {
    case Type.letter:
      return <LetterInboxIcon letter={letter} size={size} />
    case Type.invoice:
      return <InvoiceInboxIcon letter={letter} size={size} />
    case Type.parcel:
      return <ParcelInboxIcon letter={letter} size={size} />
    case Type.parcelIncoming:
      return <ParcelIncomingInboxIcon letter={letter} size={size} />

    default:
      return <Square size={size} />
  }
}

/**
 *
 * @deprecated Component is unrelated to posti-web-components and should be moved to its own application.
 */
const SenderLogo: React.FC<Props> = ({
  type,
  text,
  size,
  children,
  color,
  backgroundImageUrl,
  contentColor,
  textSize,
  ...rest
}) => {
  return (
    <LogoHolder size={size} {...rest}>
      {type ? (
        <>
          <InboxIcon type={type} size={size} text={text} />
          {children}
        </>
      ) : (
        <Square size={size} color={color} backgroundImageUrl={backgroundImageUrl}>
          {children || (
            <Text color={contentColor} size={textSize}>
              {text}
            </Text>
          )}
        </Square>
      )}
    </LogoHolder>
  )
}

SenderLogo.propTypes = {
  type: PropTypes.oneOf(Object.values(Type)) as Validator<Type>,
  text: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  contentColor: PropTypes.string,
}

SenderLogo.defaultProps = {
  type: null,
  text: null,
  size: `${Theme.size.icon.l}rem`,
}

/** @component */
export default SenderLogo
