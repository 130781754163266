import styled, { css } from 'styled-components'
import { ProfileIcon, ChevronDownIcon, CheckmarkIcon } from '../../design-tokens/icons'

export const StyledIcon = styled(ProfileIcon)`
  ${({
    theme: {
      xyz: { iconSize, spacing },
    },
  }) => css`
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
    min-width: ${iconSize.s}rem;
    margin-right: ${spacing.space3}rem;
  `}
`

export const StyledChevronDownIcon = styled(ChevronDownIcon)`
  ${({
    theme: {
      xyz: { iconSize },
    },
  }) => css`
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
    transition: all 100ms ease;
  `}
`

export const StyledControlContainer = styled.div<{ isSearchable?: boolean }>`
  ${({ isSearchable }) => css`
    display: flex;
    justify-content: start;
    width: calc(100% - 1.5rem);
    ${isSearchable &&
    css`
      flex-direction: column;
    `}
  `}
`

export const StyledValueContainer = styled.div<{ isShrinked: boolean }>`
  ${({ isShrinked }) => css`
    display: flex;
    min-width: 0;
    align-items: normal;
    ${!isShrinked &&
    css`
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    `}
  `}
`
export const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  min-width: 0;
`

export const StyledLabel = styled.label<{ isShrinked: boolean }>`
  ${({
    theme: {
      xyz: { lineHeight, color, fontWeight, fontSize },
    },
    isShrinked,
  }) => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    pointer-events: none;
    line-height: ${lineHeight};
    color: ${color.neutralNetworkGray};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.label.four}rem;
    margin: 0;
    transition: font-size, font-weight, line-height 100ms ease;
    ${isShrinked &&
    css`
      line-height: 154%;
      font-size: ${fontSize.body.five}rem;
      font-weight: ${fontWeight.normal};
    `}
  `}
`

export const StyledButtonContainer = styled.div<{
  disabled: boolean
}>`
  ${({
    theme: {
      xyz: { color, spacing, borderRadius },
    },
    disabled,
  }) => css`
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    width: 100%;
    border-radius: ${borderRadius.sm}rem;
    padding: 0 ${spacing.space4}rem;
    height: 4.5rem;
    ${StyledLabel} {
      ${disabled &&
      css`
        color: ${color.neutralPassiveGray};
      `}
    }
  `}
`

export const StyledChildrenWrapper = styled.span`
  max-width: calc(100% - 1.75rem);
  overflow: hidden;
  text-overflow: ellipsis;
  svg {
    margin-right: 0.25rem;
  }
  span {
    max-width: calc(100% - 1.5rem - 0.25rem);
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    float: right;
  }
`

export const StyledSelectPortal = styled.div`
  ${({
    theme: {
      xyz: { spacing, fontFamily, fontSize, fontWeight, color, borderRadius },
    },
  }) => css`
    .select {
      &__menu {
        box-shadow: rgb(0 0 0 / 6%) 0px 15px 22px, rgb(0 0 0 / 8%) 0px 3px 8px, rgb(0 0 0 / 8%) 0px 1px 1px,
          rgb(0 0 0 / 4%) 0px 3px 1px;
        filter: drop-shadow(rgb(0, 81, 194) 0px 0px 3px);
        overflow: hidden auto;
        border-radius: ${borderRadius.sm}rem;
        transform-origin: 0 0;
        width: 100%;
        cursor: pointer;
      }
      &__menu-list {
        padding: 0;
        overflow-y: auto;
        overflow-x: hidden;
        overflow: -moz-scrollbars-vertical;
        overflow: overlay;
        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0.5rem;
          background-color: transparent;
        }

        ::-webkit-scrollbar-track {
          margin: ${spacing.space3}rem 0;
          background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
          background-color: ${color.neutralPassiveGray};
          background-clip: content-box;
          border-radius: ${borderRadius.sm}rem;
        }

        &:hover {
          ::-webkit-scrollbar-thumb {
            background-color: ${color.neutralIconGray};
            background-clip: content-box;
            border-radius: ${borderRadius.sm}rem;
          }
        }
        &:focus {
          outline: none;
          ::-webkit-scrollbar,
          ::-webkit-scrollbar-thumb {
            outline: none;
            box-shadow: none;
          }
        }
      }
      &__option {
        font-family: ${fontFamily.Montserrat};
        font-size: ${fontSize.label.four}rem;
        font-weight: ${fontWeight.semiBold};
        color: ${color.neutralNetworkGray};
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.125rem ${spacing.space4}rem;
        background-color: ${color.neutralWhite};

        &:hover,
        &:active {
          background-color: ${color.neutralOnHoverGray};
          color: ${color.signalBlue};
        }
        &--is-focused {
          outline: 0;
          background-color: ${color.neutralOnHoverGray};
        }
      }
    }
  `}
`

export const StyledSelectContainer = styled(StyledSelectPortal)<{
  hasValue: boolean
  lightBackground: boolean
  isOpen: boolean
  isValid: boolean
  disabled: boolean
}>`
  ${({
    theme: {
      xyz: { color, borderRadius, fontFamily, fontSize, fontWeight },
    },
    hasValue,
    lightBackground,
    isOpen,
    isValid,
    disabled,
  }) => css`
    .select {
      &__single-value,
      &__multi-value__label {
        font-family: ${fontFamily.Montserrat};
        font-size: ${fontSize.label.four}rem;
        font-weight: ${fontWeight.semiBold};
        color: ${color.neutralNetworkGray};
      }
      &__single-value {
        margin-left: 0;
      }
      &__control {
        border-radius: ${borderRadius.sm}rem;
        outline: none;
        border: 0.1875rem solid ${!isValid ? color.signalRed : isOpen ? color.signalBlue : color.neutralGray2};
        box-shadow: 0px 2px 0px ${color.neutralPassiveGray};
        background: ${lightBackground ? color.neutralWhite : color.neutralGray2};
        cursor: pointer;
        outline: none;
        color: ${color.neutralNetworkGray};
        &:hover {
          background: ${color.neutralOnHoverGray};
          border-color: ${color.neutralOnHoverGray};
          ${!isValid &&
          css`
            border-color: ${color.signalRed};
          `}
          ${isOpen &&
          css`
            border-color: ${color.signalBlue};
          `}
        }
        &:active {
          background: ${color.neutralOnHoverGray};
          border-color: ${color.signalBlue};
        }
        ${isOpen &&
        css`
          background: ${color.neutralOnHoverGray};
        `}
        ${disabled &&
        css`
          border-color: ${color.neutralGray5};
          background: ${color.neutralGray5};
          color: ${color.neutralPassiveGray};
          ${StyledValueContainer} {
            color: ${color.neutralPassiveGray};
          }
        `}
        &--is-focused {
          outline: none;
          border: 0.1875rem solid ${color.signalBlue};
          &:hover {
            border: 0.1875rem solid ${color.signalBlue};
          }
        }
        &--is-disabled {
          .select__single-value,
          .select__multi-value__label {
            color: ${color.neutralPassiveGray};
          }
        }
        ${hasValue &&
        css`
          ${StyledControlContainer} {
            display: block;
          }
          .select__input-container {
            margin: 0 0.125rem;
            padding: 0;
          }
        `}
        &--menu-is-open {
          ${StyledChevronDownIcon} {
            transform: rotate(-180deg);
          }
          ${StyledLabel} {
            line-height: 154%;
            font-size: ${fontSize.body.five}rem;
            font-weight: ${fontWeight.normal};
          }
        }
      }
      &__input {
        cursor: pointer;
      }
      &__value-container {
        padding: 0;
        .select__input-container {
          margin: 0;
          padding: 0;
          font-family: ${fontFamily.Montserrat};
          font-size: ${fontSize.label.four}rem;
          font-weight: ${fontWeight.semiBold};
          color: ${color.neutralNetworkGray};
        }
        &--is-multi {
          font-family: ${fontFamily.Montserrat};
          font-size: ${fontSize.label.four}rem;
          font-weight: ${fontWeight.semiBold};
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: initial;
          align-items: unset;
          justify-content: start;
          flex-wrap: nowrap;
          position: static;
          .select__input-container {
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
          }
        }
      }
      &__multi-value {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        display: inline-flex;
        flex-shrink: 0;
        &:first-child {
          .select__multi-value__label {
            padding-left: 0;
          }
        }
        &__label {
          display: inline;
          padding: 0;
          text-overflow: unset;
          overflow: auto;
        }
      }
    }
  `}
`

export const StyledCheckItem = styled(CheckmarkIcon)`
  flex-shrink: 0;
`
