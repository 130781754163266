import styled, { css } from 'styled-components'
import { ProgressIndicatorProps } from './ProgressIndicator'

export const StyledProgressIndicator = styled.ul<ProgressIndicatorProps>(
  ({ vertical }) => css`
    padding: 0;
    margin: 0;
    display: flex;

    ${vertical
      ? css`
          flex-direction: column;
        `
      : css`
          flex-grow: 1;
          flex-direction: row;
        `}
  `
)
