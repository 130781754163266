import React, { useState, useEffect, useCallback, createRef, useLayoutEffect, useContext, useMemo } from 'react'
import PropTypes, { Validator } from 'prop-types'
import ReactDOM from 'react-dom'
import FocusLock, { AutoFocusInside } from 'react-focus-lock'

import {
  ModalProps,
  ModalScroll,
  ModalWidth,
  MobileMode,
  ModalContextProps,
  ModalWrapperContextProps,
} from './Modal.types'
import { ModalWrapper, Root } from './Modal.style'
import BottomSheet, { BottomSheetProps } from '../BottomSheet/BottomSheet'
import OutsideClick from '../../basic-components/OutsideClick'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { isFunction } from 'lodash'

function validateEventTarget<T>(target: EventTarget, resolver: (node?: Node) => T) {
  if (target instanceof Node) {
    return resolver(target)
  }
  return resolver(undefined)
}

// Modal Context

const ModalContext = React.createContext<ModalContextProps | undefined>(undefined)

export const useModalContext = () => {
  const store = useContext(ModalContext)
  if (!store) {
    throw new Error('Cannot use ModalContext outside of a provider')
  }
  return store
}

export const ModalStateWrapper: React.FC<ModalWrapperContextProps> = ({
  children,
  open: controlledOpen,
  initialOpen,
  onChange,
}) => {
  const openIsControlled = controlledOpen != null
  const [isOpen, setIsOpen] = useState(initialOpen ?? false)
  const open = openIsControlled ? controlledOpen : isOpen

  const dispatchSetIsOpen = useCallback(
    (val: boolean) => {
      if (openIsControlled) {
        onChange?.(val)
      } else {
        setIsOpen(val)
      }
    },
    [openIsControlled, onChange, setIsOpen]
  )

  const modalContext = useMemo(() => {
    return { isOpen: open, setIsOpen: dispatchSetIsOpen }
  }, [open, dispatchSetIsOpen])

  // support render-prop approach to anyone who wants to use it
  if (isFunction(children)) {
    return <ModalContext.Provider value={modalContext}>{children(modalContext)}</ModalContext.Provider>
  }
  return <ModalContext.Provider value={modalContext}>{children}</ModalContext.Provider>
}

export function Modal(props: ModalProps): JSX.Element {
  const {
    children,
    topPosition,
    width,
    maxWidth,
    scroll,
    renderInto,
    fullSizeMainContent,
    mobileMode,
    disableOutsideClick,
    bottomSheetProps,
    handleBackgroundClick,
  } = props

  const modalWrapperProps = (alert: boolean) => {
    return {
      topPosition: topPosition,
      $width: width,
      maxWidth: maxWidth,
      scroll: scroll,
      fullSizeMainContent: fullSizeMainContent,
      alert: alert,
      mobileMode: mobileMode,
    }
  }

  const [focusOnBeforeModal, setFocusOnBeforeModal] = useState<HTMLElement | null>(null)
  const { isOpen, setIsOpen } = useModalContext()

  const wrapperRef = createRef<HTMLDivElement>()
  const rootRef = createRef<HTMLDivElement>()

  useEffect(() => {
    const closeModalOnEsc = (e: KeyboardEvent) => {
      if (e.key === KeyboardKeys.Escape) {
        setIsOpen(false)
      }
    }

    window.document.addEventListener('keydown', closeModalOnEsc)

    return () => {
      window.document.removeEventListener('keydown', closeModalOnEsc)
    }
  }, [setIsOpen])

  useEffect(() => {
    if (isOpen) {
      let scrollY = 0
      const bodyOverflow = window?.document.body.style.overflow
      const bodyTop = window?.document.body.style.top

      scrollY = window.scrollY
      setFocusOnBeforeModal((document.activeElement as HTMLElement) || null)

      // Prevents page content scrolling when modal is on top
      window.document.body.style.overflow = 'hidden'
      window.document.body.style.top = '0'

      return () => {
        window.document.body.style.overflow = bodyOverflow
        window.document.body.style.top = bodyTop
        window.scrollTo(0, scrollY)
      }
    }
  }, [isOpen])

  const onFocusLockDeactivation = useCallback(() => {
    if (focusOnBeforeModal) {
      // as per https://github.com/theKashey/react-focus-lock#unmounting-and-focus-management
      setTimeout(() => focusOnBeforeModal.focus(), 0)
    }
  }, [focusOnBeforeModal])

  const mediaQuery = window.matchMedia('(min-width: 768px)')
  const [isWideScreen, setIsWideScreen] = useState(mediaQuery.matches)
  const [querySelector, setQuerySelector] = useState(null)

  useEffect(() => {
    const handler = (e) => setIsWideScreen(e.matches)
    mediaQuery.addEventListener('change', handler)

    return () => {
      mediaQuery.removeEventListener('change', handler)
    }
  }, [mediaQuery])

  const bottomSheetWithProps = (
    <BottomSheet {...bottomSheetProps} disableOutsideClick={disableOutsideClick}>
      {children}
    </BottomSheet>
  )

  useLayoutEffect(() => {
    // querySelector may be null on the page load
    setQuerySelector(document.querySelector(renderInto))
  }, [renderInto])

  const isModalInAlertMode = (alertMode: boolean) => {
    const modalProps = modalWrapperProps(alertMode)

    return ReactDOM.createPortal(
      <Root scroll={scroll} ref={rootRef} {...props}>
        <OutsideClick
          onOutsideClick={(e) => {
            if (disableOutsideClick) return

            validateEventTarget(e.target, (node) => {
              // Make sure that setIsOpen will be triggered just for the overlay clicks
              if (rootRef?.current?.contains(node) && !wrapperRef?.current?.contains(node)) {
                handleBackgroundClick?.(e)
                setIsOpen(false)
              }
            })
          }}
        >
          <FocusLock onDeactivation={onFocusLockDeactivation}>
            <AutoFocusInside>
              <ModalWrapper {...modalProps} ref={wrapperRef}>
                {children}
              </ModalWrapper>
            </AutoFocusInside>
          </FocusLock>
        </OutsideClick>
      </Root>,
      renderInto && querySelector !== null ? querySelector : document.body
    )
  }

  const selectMobileView = (mode: MobileMode) => {
    switch (mode) {
      case MobileMode.alert:
        return isOpen ? isModalInAlertMode(true) : null
      case MobileMode.fullscreen:
        return isOpen ? isModalInAlertMode(false) : null
      case MobileMode.default:
      default:
        return bottomSheetWithProps
    }
  }

  const renderDesktopModal = isOpen ? isModalInAlertMode(false) : null

  return isWideScreen ? renderDesktopModal : selectMobileView(MobileMode[mobileMode])
}

Modal.propTypes = {
  topPosition: PropTypes.string,
  width: PropTypes.oneOf(Object.values(ModalWidth)) as Validator<ModalWidth>,
  maxWidth: PropTypes.string,
  scroll: PropTypes.oneOf(Object.values(ModalScroll)) as Validator<ModalScroll>,
  renderInto: PropTypes.string,
  fullSizeMainContent: PropTypes.bool,
  mobileMode: PropTypes.oneOf(Object.keys(MobileMode)) as Validator<MobileMode>,
  handleBackgroundClick: PropTypes.func,
  disableOutsideClick: PropTypes.bool,
  bottomSheetProps: PropTypes.shape({
    backgroundColor: PropTypes.string,
    onClose: PropTypes.func,
    scrollbarColor: PropTypes.string,
    handlebarColor: PropTypes.string,
    scrollbarBackgroundColor: PropTypes.string,
    useDefaultPadding: PropTypes.bool,
    backDropStyles: PropTypes.object,
    contentHeight: PropTypes.string,
    disableOutsideClick: PropTypes.bool,
  }) as Validator<BottomSheetProps>,
}

Modal.defaultProps = {
  maxWidth: '37rem',
  width: ModalWidth['max-content'],
  scroll: ModalScroll.content,
  topPosition: undefined,
  mobileMode: MobileMode.default,
  disableOutsideClick: false,
}
