import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import { Container } from './Navigation.style'

export interface NavigationProps extends HTMLAttributes<HTMLDivElement> {
  mobileMenu: React.ReactNode
  topBar: React.ReactNode
  baseZIndex?: number
}

export const Navigation: React.FC<NavigationProps> = (props) => {
  const { topBar, mobileMenu, children, baseZIndex, ...rest } = props

  return (
    <Container zIndex={baseZIndex} {...rest}>
      {topBar}
      {mobileMenu}
      {children}
    </Container>
  )
}

Navigation.defaultProps = {
  baseZIndex: 1,
}

Navigation.propTypes = {
  baseZIndex: PropTypes.number,
  topBar: PropTypes.node.isRequired,
  mobileMenu: PropTypes.node.isRequired,
}
