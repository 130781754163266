import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { StyledTableRow } from '../TableRow'
import TableContext from '../../composite-components/Table/TableContext'

export interface TableBodyProps extends HTMLAttributes<HTMLTableSectionElement> {
  stripes?: boolean
}

const StyledTableBody = styled.tbody<TableBodyProps>(
  ({
    theme: {
      xyz: { color },
    },
    stripes,
  }) => css`
    ${stripes &&
    css`
      ${StyledTableRow}:nth-of-type(even) {
        background-color: ${color.neutralGray2};
      }
    `}

    ${StyledTableRow}:hover {
      background-color: ${color.neutralOnHoverGray};
    }
  `
)

export const TableBody: React.FC<TableBodyProps> = ({ children, ...rest }) => {
  const table = React.useContext(TableContext)

  return (
    <StyledTableBody stripes={table.stripes} {...rest}>
      {children}
    </StyledTableBody>
  )
}
