import styled, { css } from 'styled-components'
import { CalendarIcon } from '../../design-tokens/icons'
import { iconAttrs } from '../../design-tokens/icons/icons.styled'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { Headline } from '../../design-tokens/typography'
import { MonthNavigationButton } from './MonthNavigationButton'
import { MonthPickerProps } from './MonthPicker'
import { calendarShadow } from '../DatePicker/DatePicker.style'

export const StyledCalendarIcon = styled(CalendarIcon).attrs<IconProps>(iconAttrs)<IconProps>``

export const StyledMonthNavigationButton = styled(MonthNavigationButton)`
  position: static;
  padding: 0.375rem;
`

export const StyledContainer = styled.div<Pick<MonthPickerProps, 'selectsStart' | 'selectsEnd'>>(
  ({
    theme: {
      xyz: { borderRadius, color },
    },
    selectsStart,
    selectsEnd,
  }) => css`
    .react-datepicker-popper {
      border-radius: ${borderRadius.sm}rem;
    }
    .react-datepicker {
      &__month-text {
        ${(selectsStart || selectsEnd) &&
        `
          cursor: default;
          pointer-events: none;
          color: ${color.neutralPassiveGray};
        `}
      }
    }
    .react-datepicker-popper {
      ${calendarShadow}
    }
  `
)

export const StyledMonthPickerWrapper = styled.div(
  ({
    theme: {
      xyz: { spacing, color, fontWeight },
    },
  }) => css`
    .react-datepicker {
      &__month {
        padding: ${spacing.space2}rem 0 ${spacing.space5}rem;
      }
      &__month-text {
        margin: 0.125px;
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        transition: all 100ms ease-in;
        font-weight: ${fontWeight.semiBold};
        background-color: ${color.neutralWhite};

        &:focus {
          outline: none;
          border: 2px solid ${color.signalBlue};
        }
        &:focus:not(:focus-visible) {
          border: none;
        }

        &:hover,
        &:active {
          background-color: ${color.neutralOnHoverGray};
        }
      }

      &__month-text--today {
        border: 2px solid ${color.neutralOnHoverGray};
        &:hover {
          border: 2px solid #cdd1d6;
        }
        &:active {
          border: 2px solid #b0b8bf;
        }
        &:focus:hover {
          background-color: #e2e5e8;
          border: 2px solid ${color.signalBlue};
        }
        &:focus:active {
          background-color: #b0b8bf;
          border: 2px solid ${color.signalBlue};
        }
      }

      &__month--in-range {
        cursor: pointer;
        pointer-events: auto;
        color: ${color.neutralNetworkGray};
      }

      &__month--selected {
        background-color: ${color.signalBlue};
        color: ${color.neutralWhite};
        border: 2px solid ${color.signalBlue};

        &:hover,
        &:active {
          border: 2px solid ${color.signalBlue};
          color: ${color.neutralWhite};
        }
        &:hover,
        &:focus:hover {
          background-color: #0044a3;
          border: 2px solid #0044a3;
        }
        &:active,
        &:focus:active {
          background-color: #003784;
          border: 2px solid #003784;
        }
        &:focus,
        &:focus:active,
        &:focus:hover {
          border: 2px solid #cdddf3;
        }
      }

      &__month-wrapper {
        display: flex;
        justify-content: space-evenly;
        width: 23rem;
        margin-bottom: ${spacing.space4}rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
      &__header--custom {
        position: relative;

        &::before {
          width: 20.5rem;
          height: 2px;
          background-color: ${color.neutralGray5};
          content: ' ';
          position: absolute;
          bottom: 0;
          left: calc((100% - 20.5rem) / 2);
        }
      }
    }
  `
)

export const YearName = styled(Headline)`
  width: 100%;
  text-align: center;
`

export const HeaderContainer = styled.div(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    display: flex;
    align-items: center;
    padding: ${spacing.space4}rem;
  `
)
