import styled, { css } from 'styled-components'
import { MenuItemGroupProps } from './SubMenu.types'
import { Headline } from '../../design-tokens/typography'

export const StyledSubMenu = styled.li`
  display: block;
  position: relative;
}`

export const StyledSubMenuItem = styled.div``

export const SubMenuContent = styled.ul(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    padding-left: ${spacing.space5}rem;
  `
)

export const StyledMenuItemGroup = styled.div<MenuItemGroupProps>``

export const StyledTitle = styled(Headline)(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    padding: ${spacing.space3}rem 0;
  `
)
