import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Counter } from './Counter'
import { StyledTextarea, Container, StyledLabel, TopContent } from './Textarea.style'
import { UIMessage } from '../UIMessage'
import { TextareaProps } from './Textarea.types'
import { StyledBody } from '../Input/Input.style'
import { BodySize } from '../../design-tokens/typography/Body/Body'

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      isInvalid,
      message,
      helpText,
      className,
      lightBackground,
      messageProps,
      counterProps,
      value,
      'aria-describedby': ariaDescribedBy,
      label,
      charLimitMessage,
      hasCounter,
      ...textareaProps
    },
    forwardRef
  ) => {
    const localRef = useRef<HTMLTextAreaElement>()
    const textareaRef = (forwardRef as React.MutableRefObject<HTMLTextAreaElement>) || localRef
    const [focused, setFocused] = useState(false)

    const [content, setContent] = useState(value)
    const [characterCount, setCharacterCount] = useState(content.length)

    const messageId = textareaProps.id ? `${textareaProps.id}-message` : undefined
    const charLimitMessageId = textareaProps.id ? `${textareaProps.id}-charlimit-message` : undefined
    const counterId = textareaProps.id ? `${textareaProps.id}-counter` : undefined
    const describedByElements = [ariaDescribedBy, counterProps?.id, messageId].filter(Boolean)
    const defaultMaxLength = 400
    const definedMaxLength = textareaProps.maxLength ? textareaProps.maxLength : defaultMaxLength
    const isOverLimit = hasCounter && content.length === definedMaxLength

    useEffect(() => {
      if (value !== content) {
        setContent(value)
      }
    }, [value, content])

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (hasCounter) {
        const updatedText = event.target.value.slice(0, definedMaxLength)
        setCharacterCount(updatedText.length)
        setContent(updatedText)
      } else {
        setContent(event.target.value)
      }
      textareaProps.onChange?.(event)
    }

    const onFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(true)
      textareaProps.onFocus?.(event)
    }

    const onBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(false)
      textareaProps.onBlur?.(event)
    }

    const onClick = (event: React.MouseEvent<HTMLTextAreaElement>) => {
      // prevent click event to propagate to the container, causing double focus.
      event.stopPropagation()
      textareaProps.onClick?.(event)
    }

    const focus = () => {
      textareaRef.current?.select()
    }

    /**
     * Prevent default when clicking on anything else except input-tag
     * Input element would otherwise lose focus
     * @param event
     */
    const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      const targetElem = event.target as HTMLElement
      if (targetElem.tagName && targetElem.tagName !== 'TEXTAREA') {
        event.preventDefault()
      }
    }

    return (
      <div onMouseDown={onMouseDown} className={className} role="none">
        <Container
          onClick={focus}
          invalid={isInvalid}
          focused={focused}
          hasMessage={Boolean(message)}
          readOnly={textareaProps.readOnly}
          disabled={textareaProps.disabled}
          lightBackground={lightBackground}
          isOverLimit={isOverLimit}
        >
          <TopContent>
            <StyledLabel
              htmlFor={textareaProps.id}
              focused={focused}
              hasValue={Boolean(content)}
              disabled={textareaProps.disabled}
              readOnly={textareaProps.readOnly}
            >
              {label}
            </StyledLabel>
            {hasCounter && (
              <Counter
                id={counterId}
                length={characterCount}
                maxLength={definedMaxLength}
                disabled={textareaProps.disabled}
                role="region"
                aria-live="polite"
                {...counterProps}
              />
            )}
          </TopContent>
          <StyledTextarea
            ref={textareaRef}
            aria-disabled={textareaProps.disabled}
            aria-readonly={textareaProps.readOnly}
            aria-invalid={isInvalid}
            aria-describedby={describedByElements.length ? describedByElements.join(' ') : undefined}
            {...textareaProps}
            value={content}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
          />
        </Container>
        {!!helpText && <StyledBody size={BodySize.Five}>{helpText}</StyledBody>}
        <div id={messageId} aria-live="assertive">
          {message && <UIMessage success={!isInvalid} message={message} {...messageProps} />}
        </div>
        <div id={charLimitMessageId} aria-live="assertive">
          {isOverLimit && <UIMessage success={false} message={charLimitMessage} />}
        </div>
      </div>
    )
  }
)

Textarea.displayName = 'Textarea'

Textarea.defaultProps = {
  lightBackground: true,
  charLimitMessage: 'The text hits the character limit',
  value: '',
  hasCounter: true,
}

Textarea.propTypes = {
  isInvalid: PropTypes.bool,
  message: PropTypes.string,
  helpText: PropTypes.string,
  messageProps: PropTypes.object,
  lightBackground: PropTypes.bool,
  label: PropTypes.string.isRequired,
  charLimitMessage: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  hasCounter: PropTypes.bool,
}
