/* eslint-disable import/named */
import styled, { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components'
import { ContentProps, LabelAndInputContainerProps, StyledInputProps, StyledLabelProps } from './Input.types'
import { Body, BodyProps } from '../../design-tokens/typography/Body/Body'

export const contentStyle = ({
  theme,
  hasMessage,
  invalid,
  focused,
  readOnly,
  disabled,
  lightBackground,
  noBorderChangeOnFocus,
  isDark,
}: ContentProps & { theme: DefaultTheme }): FlattenSimpleInterpolation => {
  const {
    xyz: { spacing, color, borderRadius },
  } = theme
  return css`
    background-color: ${disabled
      ? color.neutralGray5
      : readOnly
      ? 'transparent'
      : lightBackground
      ? color.neutralGray2
      : color.neutralWhite};
    width: 100%;
    padding: calc(${spacing.space3}rem - 3px) calc(${spacing.space4}rem - 3px);
    border: 3px solid transparent;
    border-radius: ${borderRadius.sm}rem;
    transition: border-color 0.2s ease-in-out, border-width 0.2s ease-in-out;

    ${!invalid &&
    !hasMessage &&
    (!readOnly || disabled) &&
    `
    box-shadow: 0 2px 0 ${color.neutralPassiveGray};
  `}

    ${focused &&
    !readOnly &&
    !disabled &&
    `
    border-color: ${isDark ? color.brandBrightYellow : color.signalBlue};
  `}

  ${(invalid || hasMessage) &&
    `
    border-color: ${invalid ? color.signalRed : color.signalGreen};
  `}

  ${noBorderChangeOnFocus &&
    `
      border-color: transparent;
      box-shadow: none;
   `}
  `
}

export const Content = styled.div<ContentProps>(contentStyle)

export const InputContent = styled(Content)(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    display: flex;
    align-items: center;
    min-height: ${spacing.space9}rem;
  `
)

export const LabelAndInputContainer = styled.div<LabelAndInputContainerProps>(
  ({
    theme: {
      xyz: { spacing },
    },
    hasLeftElement,
    hasRightElement,
    focused,
    hasValue,
  }) => {
    const notEmptyFocused = focused || hasValue
    return css`
      display: flex;
      flex: 1;
      align-items: center;
      position: relative;
      padding: ${notEmptyFocused ? `${spacing.space3}rem 0 0 0` : `${spacing.space2}rem 0`};

      ${hasLeftElement && `margin-left: ${spacing.space3}rem;`}
      ${hasRightElement && `margin-right: ${spacing.space3}rem;`}
    `
  }
)

export const StyledLabel = styled.label<StyledLabelProps>(({ theme, hasValue, focused, readOnly, disabled }) => {
  const {
    xyz: { spacing, color, fontSize, fontWeight, fontFamily },
  } = theme
  const notEmptyFocused = focused || hasValue
  return css`
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: ${notEmptyFocused || readOnly ? 1.25 : spacing.space4}rem;
    pointer-events: none;

    color: ${disabled ? color.neutralPassiveGray : color.neutralNetworkGray};
    font-size: ${notEmptyFocused || readOnly ? fontSize.body.five : fontSize.body.three}rem;
    font-weight: ${notEmptyFocused || readOnly ? fontWeight.normal : fontWeight.semiBold};
    font-family: ${fontFamily.Montserrat};
    transform: translateY(${notEmptyFocused || readOnly ? '-0.7rem' : '0'});
    transition: font-size 0.2s ease-in-out, transform 0.2s ease-in-out, height 0.2s ease-in-out;
    cursor: ${focused ? 'default' : 'text'};
  `
})

export const inputStyle = ({
  theme,
  disabled,
}: StyledInputProps & { theme: DefaultTheme }): FlattenSimpleInterpolation => {
  const {
    xyz: { color, fontFamily, fontWeight, fontSize },
  } = theme
  return css`
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: ${fontFamily.Montserrat};
    color: ${disabled ? color.neutralPassiveGray : color.neutralNetworkGray};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.body.three}rem;
    /* Force the caret color to be darker than the actual typed text for clarity */
    caret-color: ${color.neutralBlack};

    /* Remove search input default styling in webkit browsers */
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
      display: none;
    }

    /* Placeholder text could be a bit 'grayer' than the actual text */
    ::placeholder {
      font-family: ${fontFamily.Montserrat};
      font-weight: ${fontWeight.normal};
      color: ${color.neutralPassiveGray};
      font-size: ${fontSize.body.three}rem;
    }

    /* Hide the clear/cross button that appears in MS browsers. Instead we should add our own clear button if needed */
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
    }

    /* Clear native input appearance from webkit browsers, so that it doesn't conflict our own */
    -webkit-appearance: none;

    :focus {
      outline: none;
    }

    /* Clear the red box shadow firefox adds around :invalid inputs */
    :not(output):-moz-ui-invalid {
      box-shadow: none;
    }

    /* Alter the default autofill background color (https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete) */
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active,
    :-internal-autofill-selected {
      background-color: transparent;
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      -webkit-text-fill-color: ${color.neutralNetworkGray};
      transition: background-color 5000s ease-in-out 0s;
    }

    /* Expose a JS-detectable bindings to detect autofill (https://github.com/klarna/ui/blob/v4.10.0/Field/styles.scss#L228-L241) */
    @keyframes onAutoFillStart {
      from {
        /**/
      }
      to {
        /**/
      }
    }
    @keyframes onAutoFillCancel {
      from {
        /**/
      }
      to {
        /**/
      }
    }
    :-webkit-autofill {
      animation-name: onAutoFillStart;
    }
    :not(:-webkit-autofill) {
      animation-name: onAutoFillCancel;
    }
  `
}

export const StyledInput = styled.input<StyledInputProps>(inputStyle)

export const StyledBody = styled(Body)<BodyProps>(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    margin: ${spacing.space2}rem 0 0;
    padding: 0 ${spacing.space4}rem;
  `
)
