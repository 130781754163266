import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Theme } from '@postidigital/posti-theme'
import { ThemeColor } from '../../utils/helpers'
import Circle from '../Circle'

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  color?: ThemeColor | string
  size?: string
}

const DotElement = styled(Circle)`
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

/**
 * Dot Component
 * @deprecated Use DotIcon instead
 */
const Dot: React.FC<Props> = ({ children, ...props }) => <DotElement {...props}>{children}</DotElement>

Dot.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}

Dot.defaultProps = {
  color: Theme.color.signalBlue,
  size: `${Theme.size.icon.m}rem`,
}

/** @component */
export default Dot
