import styled, { css } from 'styled-components'

import { mediaQuery } from '../../utils/mediaQuery'
import { DialogScroll, Scroll, WrapperProps } from './Dialog.types'
import { Headline } from '../../design-tokens/typography'
import { IconButton } from '../../basic-components/IconButton'

export const Close = styled(IconButton)(
  ({
    theme: {
      xyz: { zIndex, spacing },
    },
  }) => css`
    position: absolute;
    top: ${spacing.space4}rem;
    right: 1rem;
    z-index: ${zIndex.zIndex10};
    border: none;
    &:hover,
    &:focus {
      background: transparent;
      border: none;
    }
  `
)

export const Root = styled.div<{ scroll: Scroll }>(
  ({
    scroll,
    theme: {
      xyz: { zIndex },
    },
  }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${zIndex.zIndexTop};
    overflow-y: auto;
    background-color: rgba(38, 44, 48, 0.64);
    display: flex;
    ${mediaQuery.md`
      display: block;
    `}
    ${scroll === DialogScroll.content &&
    css`
      ${mediaQuery.md`
      overflow: hidden;
    `}
    `}
    ${scroll === DialogScroll.body &&
    css`
      overflow-x: hidden;
      overflow-y: auto;
    `}
  /* PATCH: iOS v12 Safari bug */
  -webkit-overflow-scrolling: touch;
    & * {
      -webkit-overflow-scrolling: touch;
    }
  `
)

export const DialogWrapper = styled.div<WrapperProps & { alert: boolean }>(
  ({ theme, topPosition, width, maxWidth, scroll, alert }) => css`
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: ${theme.xyz.color.neutralWhite};
    position: ${scroll === DialogScroll.content ? 'absolute' : 'relative'};
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    overflow: hidden;
    ${alert &&
    `
      @media screen and (max-width: ${theme.xyz.breakpoint.md}) {
        border-radius: ${theme.xyz.borderRadius.sm}rem;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: calc(100% - 2*(${theme.xyz.spacing.space4}rem));
        height: auto;
        ${DialogContent} {
          padding: ${theme.xyz.spacing.space3}rem ${theme.xyz.spacing.space4}rem ${theme.xyz.spacing.space2}rem;
        }
        ${StyledTitle} {
          font-size: 1.25rem;
        }
        ${Header} {
          padding-bottom: ${theme.xyz.spacing.space3}rem;
        }
        ${MainContent} {
          margin-bottom: ${theme.xyz.spacing.space2}rem;
        }
      }
    `}
    ${mediaQuery.md`
      max-height: calc(100vh - ${theme.xyz.spacing.space9}rem);
      min-width: 20rem;
      max-width: ${maxWidth ? maxWidth : '48rem'};
      ${width && `width: ${width};`}
      min-height: auto;
      min-height: initial;
      height: auto;
      height: initial;
      border-radius: ${theme.xyz.borderRadius.md}rem;
      ${
        topPosition && scroll === DialogScroll.content
          ? `top: ${topPosition};`
          : `top: 50%; transform: translate(-50%, -50%);`
      }
      ${
        scroll === DialogScroll.body &&
        `
        max-height: none;
        margin: ${theme.xyz.spacing.space5}rem 0;
        transform: translateX(-50%);
        top: initial;
      `
      }
    `}
  `
)

export const DialogContent = styled.div<{
  scroll: Scroll
  fullSizeMainContent: boolean
}>(
  ({ theme, scroll, fullSizeMainContent }) => css`
    width: 100%;
    padding: ${
      fullSizeMainContent
        ? '0;'
        : `${theme.xyz.spacing.space8}rem ${theme.xyz.spacing.space4}rem ${theme.xyz.spacing.space5}rem
      ${theme.xyz.spacing.space4}rem;`
    }
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ${
      scroll === DialogScroll.content &&
      css`
        & > div:last-child {
          height: ${fullSizeMainContent ? '100%' : 'fit-content'};
          flex: 1 1 auto;
          overflow-y: auto;
          padding: ${theme.xyz.spacing.space3}rem ${fullSizeMainContent ? '0' : theme.xyz.spacing.space3}rem;
          margin: -${theme.xyz.spacing.space3}rem -${fullSizeMainContent ? '0' : theme.xyz.spacing.space3}rem;
        }
      `
    }
  `
)

export const MainContent = styled.div<{ fullSize: boolean }>(
  ({ theme, fullSize }) => css`
    ${fullSize ? 'height: 100%;' : ''}
    margin-bottom: ${fullSize ? '0' : theme.xyz.spacing.space5}rem;
    padding: 0;
  `
)

export const Header = styled.div(
  ({ theme }) => css`
    position: sticky;
    padding-bottom: ${theme.xyz.spacing.space4}em;
    padding: ${theme.xyz.spacing.space5}rem 0 ${theme.xyz.spacing.space4}rem;
  `
)

export const ButtonWrapper = styled.div(
  ({ theme }) => css`
    padding: ${theme.xyz.spacing.space2}rem ${theme.xyz.spacing.space2}rem ${theme.xyz.spacing.space3}rem;
    margin-left: -${theme.xyz.spacing.space2}rem;
    margin-right: -${theme.xyz.spacing.space2}rem;
    div {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  `
)

export const StyledTitle = styled(Headline)`
  word-break: break-word;
  margin-bottom: 0;
`

export const StyledHeroImage = styled.div(
  ({ theme }) => css`
    display: flex;
    margin: -${theme.xyz.spacing.space8}rem -${theme.xyz.spacing.space4}rem 0;
    width: calc(100% + ${theme.xyz.spacing.space7}rem);
    max-height: 10rem;
    overflow: hidden;
    height: auto;
    justify-content: center;
    align-items: center;
    ${mediaQuery.md`
      max-height: 12.5rem;
      border-radius: ${theme.xyz.borderRadius.md}rem ${theme.xyz.borderRadius.md}rem 0 0;
    `}
    & > * {
      width: 100%;
    }
  `
)
