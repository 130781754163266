import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export interface SnackbarProps extends HTMLAttributes<HTMLDivElement> {
  message: string
}

const Container = styled.div(
  ({
    theme: {
      xyz: { borderRadius, color, fontSize, spacing },
    },
  }) => css`
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: ${color.neutralWhite};
    font-size: ${fontSize.label.five}rem;
    background-color: ${color.neutralNetworkGray};
    position: relative;
    border-radius: ${borderRadius.xs}rem;
    padding: ${spacing.space3}rem;
    overflow: hidden;
  `
)

const Message = styled.span(
  ({
    theme: {
      xyz: { color, fontSize, fontWeight },
    },
  }) => css`
    font-size: ${fontSize.label.five}rem;
    color: ${color.neutralWhite};
    font-weight: ${fontWeight.medium};
  `
)

export const Snackbar: React.FC<SnackbarProps> = ({ message, ...props }) => {
  return (
    <Container {...props}>
      <Message>{message}</Message>
    </Container>
  )
}

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
}
