import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import RadioGroupContext from './RadioGroupContext'

export interface RadioGroupProps {
  value: string | number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  children: React.ReactNode
  alignedHorizontally?: boolean
}

const StyledRadioGroup = styled.div<{ alignedHorizontally?: boolean }>(
  ({ alignedHorizontally }) => css`
    display: flex;
    flex-direction: ${alignedHorizontally ? `row` : `column`};

    & > div {
      display: block;
    }
  `
)

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const { value: valueProp, onChange, children, ...rest } = props
  const [value, setValue] = useState(valueProp)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
    onChange(e)
  }

  return (
    <RadioGroupContext.Provider value={{ value, onChange: handleChange }}>
      <StyledRadioGroup role="radiogroup" {...rest}>
        {children}
      </StyledRadioGroup>
    </RadioGroupContext.Provider>
  )
}

RadioGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  alignedHorizontally: PropTypes.bool,
}

RadioGroup.defaultProps = {
  alignedHorizontally: false,
}
