import React, { useState, createRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import FocusLock, { AutoFocusInside } from 'react-focus-lock'
import { XyzTheme } from '@postidigital/posti-theme'

import { DialogProps, DialogScroll, DialogWidth } from './Dialog.types'
import {
  ButtonWrapper,
  Close,
  Header,
  MainContent,
  DialogContent,
  DialogWrapper,
  Root,
  StyledTitle,
  StyledHeroImage,
} from './Dialog.style'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { HeadlineSize } from '../../design-tokens/typography'
import { CloseIcon } from '../../design-tokens/icons'

function omit<T>(key: keyof T, obj: T) {
  const { [key]: _ignored, ...rest } = obj
  return rest
}

/**
 * Dialog Component
 * @deprecated Please use Modal component instead
 */
const Dialog: React.FC<DialogProps> = (props: DialogProps) => {
  const {
    children,
    headerContent,
    topPosition,
    width,
    maxWidth,
    noCloseButton,
    scroll: scrollProp,
    heroImage: HeroImage,
    renderInto,
    headingSize,
    onClose,
    closeIconColor,
    fullSizeMainContent,
    bottomButtons,
    alert,
    ...rest
  } = props
  const wrapperRef = createRef<HTMLDivElement>()
  const closeRef = createRef<HTMLButtonElement>()
  const [focusOnBeforeDialog, setFocusOnBeforeDialog] = useState<HTMLElement | null>(null)

  useEffect(() => {
    let scrollY = 0
    scrollY = window.scrollY
    setFocusOnBeforeDialog((document.activeElement as HTMLElement) || null)

    // Prevents page content scrolling when modal is on top
    window.document.body.style.overflow = 'hidden'
    window.document.body.style.top = '0'

    return () => {
      window.document.body.style.overflow = ''
      window.document.body.style.top = ''
      window.scrollTo(0, scrollY)
    }
  }, [])

  useEffect(() => {
    const closeModalOnEsc = (e: KeyboardEvent) => {
      if (e.key === KeyboardKeys.Escape) {
        onClose()
      }
    }
    window.document.addEventListener('keydown', closeModalOnEsc)

    return () => {
      window.document.removeEventListener('keydown', closeModalOnEsc)
    }
  }, [onClose])

  const onFocusLockDeactivation = useCallback(() => {
    if (focusOnBeforeDialog) {
      // as per https://github.com/theKashey/react-focus-lock#unmounting-and-focus-management
      setTimeout(() => focusOnBeforeDialog.focus(), 0)
    }
  }, [focusOnBeforeDialog])

  const onBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!props.disableOutsideClick && wrapperRef.current && !wrapperRef.current.contains(e.target as Element)) {
      props.onClose()
    }
  }

  const scroll = HeroImage ? DialogScroll.body : scrollProp

  return (
    <>
      {ReactDOM.createPortal(
        <Root onClick={onBackgroundClick} scroll={scroll}>
          <FocusLock onDeactivation={onFocusLockDeactivation}>
            <DialogWrapper
              ref={wrapperRef}
              topPosition={topPosition}
              width={width}
              maxWidth={maxWidth}
              scroll={scroll}
              alert={alert}
              aria-modal="true"
              {
                // We don't want to render closeText attribute for the div
                // `as any` is used because `closeText` prop might be defined or not
                ...omit('closeText' as any, rest)
              }
            >
              <DialogContent
                aria-live="assertive"
                role="dialog"
                aria-labelledby="dialog-title"
                scroll={scroll}
                fullSizeMainContent={fullSizeMainContent}
              >
                {props.noCloseButton === false && (
                  <Close
                    ref={closeRef}
                    aria-label={props.closeText}
                    onClick={onClose}
                    iconColor={closeIconColor}
                    icon={CloseIcon}
                  />
                )}
                {HeroImage && <StyledHeroImage>{HeroImage}</StyledHeroImage>}

                <AutoFocusInside>
                  {!fullSizeMainContent && !!headerContent && (
                    <Header tabIndex={-1}>
                      <StyledTitle as="h1" size={headingSize} id="dialog-title">
                        {headerContent}
                      </StyledTitle>
                    </Header>
                  )}
                  <MainContent tabIndex={-1} fullSize={fullSizeMainContent}>
                    {children}
                  </MainContent>
                  {!fullSizeMainContent && bottomButtons && <ButtonWrapper>{bottomButtons}</ButtonWrapper>}
                </AutoFocusInside>
              </DialogContent>
            </DialogWrapper>
          </FocusLock>
        </Root>,
        renderInto ? document.querySelector(renderInto) : document.body
      )}
    </>
  )
}

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  topPosition: PropTypes.string,
  width: PropTypes.oneOf(Object.values(DialogWidth)),
  maxWidth: PropTypes.string,
  closeText: PropTypes.string,
  scroll: PropTypes.oneOf(Object.values(DialogScroll)),
  renderInto: PropTypes.string,
  headingSize: PropTypes.oneOf(Object.values(HeadlineSize)),
  closeIconColor: PropTypes.string,
  fullSizeMainContent: PropTypes.bool,
  bottomButtons: PropTypes.node,
  alert: PropTypes.bool,
}

Dialog.defaultProps = {
  maxWidth: '37rem',
  width: DialogWidth['max-content'],
  scroll: DialogScroll.content,
  topPosition: undefined,
  headingSize: HeadlineSize.Five,
  closeIconColor: XyzTheme.color.neutralIconGray,
  noCloseButton: false,
  alert: false,
}

export default Dialog
