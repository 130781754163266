import { HTMLAttributes } from 'react'

export enum NavButtonMode {
  primary = 'primary',
  secondary = 'secondary',
}

export interface NavButtonProps extends HTMLAttributes<HTMLButtonElement> {
  isOpen?: boolean
  title: string
  contentMode?: keyof typeof NavButtonMode
  arrowColor?: string
  selectedColor?: string
  selected?: boolean
  withArrow?: boolean
  Icon?: React.ComponentType
}
