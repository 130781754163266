import React, { useState } from 'react'
import { useTheme } from '../../utils/useTheme'
import PropTypes from 'prop-types'
import { MenuItem, MenuItemLabel, buttonTypeOption } from '../../basic-components/MenuItem'
import { ArrowLeftIcon, PinIcon } from '../../design-tokens/icons'
import { HeadlineSize } from '../../design-tokens/typography'
import {
  Break,
  Container,
  Content,
  Header,
  HeaderButtons,
  HeaderTitle,
  StyledHeadline,
  RootMenu,
  StyledRootMenuItem,
  PersonalIconButton,
  CloseButtonPositioning,
  StyledWrapperLanguageSelection,
} from './MobileMenu.style'
import { MobileMenuOption, MobileMenuProps, MobileMenuPropsType } from './MobileMenu.types'
import OutsideClick from '../../basic-components/OutsideClick'
import { LocationButton } from '../TopBar/TopBar.styles'
import { handleNavItemClick, checkAriaCurrent } from '../Navigation/navigationItemUtils'
import { getSelectedItem } from './util'

export const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  const {
    navigationState,
    rootLevelItems,
    onOutsideClick,
    showMobileMenu,
    closeButton,
    zIndex,
    languageSelection,
    onMenuItemClick,
    servicePointsItem,
    returnButtonLabel,
    onArrowClick,
    onBackButtonClick,
    ...rest
  } = props

  const {
    xyz: { color, iconSize },
  } = useTheme()

  const { topLevelMenuItems, isBusiness, rootMenuItems, currentPageTopbar } = navigationState

  const [selectedOption, setSelectedOption] = useState('')

  const outsideClick = () => {
    if (!showMobileMenu) {
      return
    }
    onOutsideClick()
  }

  const onMenuItemSelect = (option: MobileMenuOption) => {
    setSelectedOption(option.value)
    onMenuItemClick(option.value)
  }

  const handleMenuArrowClick = (item: MobileMenuOption) => {
    onArrowClick(item)
  }

  const handleBackButtonClick = () => {
    setSelectedOption('')
    onBackButtonClick()
  }

  const selectedItem = getSelectedItem(topLevelMenuItems)

  return (
    <>
      {showMobileMenu && (
        <OutsideClick onOutsideClick={outsideClick}>
          <Container {...rest} zIndex={zIndex}>
            <Header>
              <HeaderButtons isDark={isBusiness}>
                <CloseButtonPositioning isDark={isBusiness}>{closeButton}</CloseButtonPositioning>
                {rootMenuItems && (
                  <RootMenu>
                    {rootMenuItems.map((item, index) => {
                      return (
                        <StyledRootMenuItem isDark={isBusiness} isActive={item.active} key={`${item.title}-${index}`}>
                          <a
                            href={item.href}
                            onClick={(e) => handleNavItemClick(item, e)}
                            aria-current={checkAriaCurrent(item, currentPageTopbar)}
                          >
                            {item.title}
                          </a>
                        </StyledRootMenuItem>
                      )
                    })}
                  </RootMenu>
                )}
              </HeaderButtons>

              {selectedItem && (
                <HeaderTitle>
                  <PersonalIconButton
                    onClick={handleBackButtonClick}
                    aria-label="Go back"
                    icon={ArrowLeftIcon}
                    iconColor={color.neutralNetworkGray}
                  />
                  <StyledHeadline size={HeadlineSize.Five}>{selectedItem.label}</StyledHeadline>
                </HeaderTitle>
              )}
            </Header>
            <Content role="menu">
              {!selectedItem
                ? topLevelMenuItems.map((opt) => {
                    return (
                      <MenuItem
                        role="menuitem"
                        aria-label={opt.label}
                        key={opt.id}
                        value={opt.value}
                        onSelect={() => onMenuItemSelect(opt)}
                        onButtonClick={() => handleMenuArrowClick(opt)}
                        icon={opt.icon}
                        buttonType={opt.redirectsToSubMenu ? buttonTypeOption.rightArrow : buttonTypeOption.noButton}
                        selected={selectedOption === opt.value}
                        chevronAriaLabel={opt.chevronAriaLabel}
                      >
                        <MenuItemLabel size={HeadlineSize.Seven}>{opt.label}</MenuItemLabel>
                      </MenuItem>
                    )
                  })
                : [selectedItem].map((validOption) =>
                    validOption.children.map((child) => {
                      return (
                        <MenuItem
                          role="menuitem"
                          aria-label={child.label}
                          key={child.id}
                          value={child.value}
                          onSelect={() => onMenuItemSelect(child)}
                          onButtonClick={() => handleMenuArrowClick(child)}
                          icon={child.icon}
                          selected={selectedOption === child.value}
                          buttonType={
                            validOption.redirectsToSubMenu ? buttonTypeOption.rightArrow : buttonTypeOption.noButton
                          }
                          chevronAriaLabel={validOption.chevronAriaLabel}
                        >
                          <MenuItemLabel size={HeadlineSize.Seven}>{child.label}</MenuItemLabel>
                        </MenuItem>
                      )
                    })
                  )}

              {props.rootLevelItems && (
                <>
                  <Break />
                  {rootLevelItems.map((opt) => {
                    return (
                      <MenuItem
                        role="menuitem"
                        key={opt.id}
                        value={opt.value}
                        selected={selectedOption === opt.value}
                        icon={opt.icon}
                        onSelect={() => onMenuItemSelect(opt)}
                      >
                        <MenuItemLabel size={HeadlineSize.Seven}>{opt.label}</MenuItemLabel>
                      </MenuItem>
                    )
                  })}
                </>
              )}

              <>
                <Break />
                {props.servicePointsItem && (
                  <LocationButton isDark={isBusiness} href={servicePointsItem.href}>
                    <PinIcon width={`${iconSize.s}rem`} height={`${iconSize.s}rem`} />
                    {servicePointsItem.title}
                  </LocationButton>
                )}
                {props.languageSelection && (
                  <StyledWrapperLanguageSelection>{props.languageSelection}</StyledWrapperLanguageSelection>
                )}
              </>
            </Content>
          </Container>
        </OutsideClick>
      )}
    </>
  )
}

MobileMenu.defaultProps = {
  showMobileMenu: true,
  zIndex: 3,
  navigationState: {
    topLevelMenuItems: [],
  },
  returnButtonLabel: 'Back',
}

MobileMenu.propTypes = {
  navigationState: MobileMenuPropsType.isRequired,
  showMobileMenu: PropTypes.bool,
  zIndex: PropTypes.number,
  returnButtonLabel: PropTypes.string,
  closeButton: PropTypes.node.isRequired,
  languageSelection: PropTypes.node,
}
