import styled, { css } from 'styled-components'
import { NavButton } from '../../basic-components/NavButton'
import { ProfileIcon } from '../../design-tokens/icons'

export const StyledDropdown = styled.div`
  display: inline-block;
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;

  ul {
    width: 100%;
  }
`

export const NavDropdownItem = styled(NavButton).attrs({ contentMode: 'secondary', withArrow: false })`
  ${({
    theme: {
      xyz: { spacing, color },
    },
  }) => css`
    width: 100%;
    padding: ${spacing.space3}rem;
    justify-content: left;

    span {
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      background-color: ${color.neutralGray5};
    }
  `}
`

export const Icon = styled(ProfileIcon)`
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5rem;
  margin-left: -0.25rem;
  flex-shrink: 0;
`
