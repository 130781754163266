import React, { useRef, forwardRef } from 'react'
import PropTypes, { Validator } from 'prop-types'
import dayjs from 'dayjs'
import DatePickerComponent from 'react-datepicker'
import { Input } from '../../basic-components/Input'

import { Container, StyledHeaderContainer } from './DatePicker.style'
import { DatePickerProps } from './DatePicker.types'
import { YearName, StyledMonthNavigationButton, StyledCalendarIcon } from '../MonthPicker/MonthPicker.styles'
import { HeadlineSize } from '../../design-tokens/typography'
import { Size } from '../../basic-components/Button/Button'

export const DEFAULT_LOCALE = 'fi'

export const DatePicker: React.FC<DatePickerProps> = ({
  prevMonthButtonAriaLabel,
  nextMonthButtonAriaLabel,
  selectsStart,
  selectsEnd,
  inputId,
  inputLabel,
  monthsShown,
  pickerSize,
  ...props
}) => {
  const monthRef = useRef<HTMLInputElement>()

  const CustomInput = forwardRef<HTMLInputElement>(function CustomInput(props, ref) {
    return (
      <Input
        {...props}
        role="combobox"
        ref={ref}
        id={inputId}
        label={inputLabel}
        rightTemplate={<StyledCalendarIcon />}
      />
    )
  })

  return (
    <Container monthsShown={monthsShown} pickerSize={pickerSize}>
      <DatePickerComponent
        customInput={<CustomInput ref={monthRef} {...props} />}
        minDate={dayjs().toDate()}
        monthsShown={monthsShown}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth, customHeaderCount }) => (
          <StyledHeaderContainer pickerSize={pickerSize}>
            <StyledMonthNavigationButton
              orientation={'left'}
              aria-label={prevMonthButtonAriaLabel}
              onClick={decreaseMonth}
              style={customHeaderCount === 0 ? null : { visibility: 'hidden' }}
            />
            <YearName size={HeadlineSize.Six}>
              {monthDate.toLocaleString(props.locale.toString(), {
                month: 'long',
                year: 'numeric',
              })}
            </YearName>
            <StyledMonthNavigationButton
              orientation={'right'}
              aria-label={nextMonthButtonAriaLabel}
              onClick={increaseMonth}
              style={monthsShown ? (customHeaderCount === monthsShown - 1 ? null : { visibility: 'hidden' }) : null}
            />
          </StyledHeaderContainer>
        )}
        {...props}
      />
    </Container>
  )
}

DatePicker.propTypes = {
  pickerSize: PropTypes.oneOf(Object.keys(Size)) as Validator<Size>,
  locale: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
}

DatePicker.defaultProps = {
  pickerSize: Size.md,
  locale: DEFAULT_LOCALE,
}
