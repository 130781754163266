import styled, { css } from 'styled-components'
import { Body } from '../../design-tokens/typography'
import { TimePickerGroupTableItemProps } from './TImePickerGroup.types'

// Min height of the slot accordingly to accessibility guidelines 44px / 2.75rem @font-size:16px
const accessibleMinHeight = 2.75

export const Container = styled.div(
  ({
    theme: {
      xyz: { breakpoint },
    },
  }) => css`
    height: 100%;
    width: 100%;
    display: flex;
    overflow-x: auto;
    @media (max-width: ${breakpoint.md}) {
      justify-content: left;
    }
  `
)

export const TableCol = styled.div`
  display: flex;
  flex-direction: column;

  // fine tune padding for button box shadow
  padding-bottom: 22px;
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 20px;
  }
`
export const DefaultTableItem = styled.div<TimePickerGroupTableItemProps>(
  ({
    theme: {
      xyz: { spacing },
    },
    hourSpan,
  }) => css`
  display: flex;
  flex-direction: column;
  height: ${accessibleMinHeight * hourSpan}rem;
  padding: ${spacing.space1}rem;
  min-height: ${accessibleMinHeight}rem;
  min-width: $spacing.space9}rem;
  text-align: center;
`
)
export const TableHeader = styled(Body)(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    text-align: center;
    margin-bottom: ${spacing.space3}em;
    white-space: nowrap;
    padding-left: ${spacing.space2}rem;
    padding-right: ${spacing.space2}rem;
  `
)

export const TableItemEmpty = styled.div(
  ({
    theme: {
      xyz: { color },
    },
  }) => css`
    border-right: ${color.neutralGray5} solid 1px;
    height: 100%;
    margin: 4px 12px;
  `
)
