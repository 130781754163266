import styled, { css } from 'styled-components'
import { CardSelectorProps } from './CardSelector'
import { ArrowRightIcon } from '../../design-tokens/icons'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { CheckmarkIndicatorIcon } from '../../design-tokens/icons/indicator/CheckmarkIndicatorIcon'

export const StyledCardSelector = styled.button<CardSelectorProps>`
  ${({
    theme: {
      xyz: { color, borderRadius, spacing },
    },
    disabled,
    selected,
  }) => css`
    position: relative;
    background-color: ${disabled ? color.neutralGray5 : color.neutralWhite};
    border-radius: ${borderRadius.xs}rem;
    border: ${selected && !disabled ? `3px solid ${color.signalBlue}` : '3px solid transparent'};
    width: 10rem;
    min-height: 6.5rem;
    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    padding: ${spacing.space4}rem;
    flex-direction: column;

    label {
      color: ${disabled ? color.neutralPassiveGray : color.signalBlue};
      cursor: ${disabled ? 'default' : 'pointer'};
    }

    ${!disabled &&
    css`
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: ${color.neutralOnHoverGray};
        }
      }
    `}

    &:focus {
      outline: 0;
      box-shadow: 0px 0px 7px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
        0px 3px 1px rgba(0, 0, 0, 0.04);
    }
  `}
`

export const StyledIcon = styled(ArrowRightIcon)<IconProps>`
  ${({
    theme: {
      xyz: { iconSize, spacing },
    },
  }) => css`
    flex-shrink: 0;
    width: ${iconSize.m}rem;
    height: ${iconSize.m}rem;
    margin-bottom: ${spacing.space2}rem;
  `}
`

export const StyledCheckmarkIndicatorIcon = styled(CheckmarkIndicatorIcon)`
  ${({
    theme: {
      xyz: { iconSize, spacing },
    },
  }) => css`
    position: absolute;
    right: ${spacing.space1}rem;
    top: ${spacing.space1}rem;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
  `}
`
