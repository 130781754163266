import styled, { css } from 'styled-components'
import { ThemeColor } from '../../utils/helpers'
import { IconButton } from '../../basic-components/IconButton'
import { Headline } from '../../design-tokens/typography'
import { ModalMainContent } from '../Modal/Modal.style'
import OutsideClick from '../../basic-components/OutsideClick'

export const BottomSheetWrapper = styled.div.attrs({
  'aria-modal': 'true',
  tabIndex: -1,
})(
  ({
    theme: {
      xyz: { spacing, zIndex },
    },
  }) => css`
    position: relative;
    visibility: visible;
    z-index: ${zIndex.zIndex100};
    border-radius: 6px 6px 0 0;
    box-shadow: 0px 6px 37px rgba(57, 75, 88, 0.33);
    height: 100%;
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: ${spacing.space2}rem;
    overflow: hidden;

    &:before {
      position: absolute;
      content: '';
      border-radius: 0.375rem;
      top: ${spacing.space2}rem;
      left: 50%;
      transform: translateX(-50%);
      right: 50%;
      width: 3.25rem;
      height: 0.375rem;
      z-index: ${zIndex.zIndex1000};
    }
  `
)

export const BottomSheetContentWrapper = styled.div.attrs({
  'aria-labelledby': 'modal-title',
  'aria-describedby': 'bottomsheet-content',
  'aria-live': 'assertive',
  role: 'dialog',
})``

export const BottomSheetHeadlineAligner = styled.div`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    position: relative;
    display: flex;
    word-break: break-word;
    padding-bottom: ${spacing.space2}rem;
  `}
`

export const BottomSheetContent = styled.div.attrs({
  id: 'bottomsheet-content',
})``

export const BackDrop = styled.div.attrs({
  'data-testid': 'bs-backdrop',
})<{
  useDefaultPadding?: boolean
  backgroundColor?: string
  handlebarColor?: string | ThemeColor
  scrollbarBackgroundColor?: string
  scrollbarColor?: string
  contentHeight?: string
  bodyScroll: boolean
  hasImgPadding?: boolean
  open?: boolean
}>(
  ({
    theme: {
      xyz: { zIndex, spacing, color },
    },
    useDefaultPadding,
    backgroundColor,
    handlebarColor,
    scrollbarColor,
    scrollbarBackgroundColor,
    contentHeight,
    bodyScroll,
    hasImgPadding,
    open,
  }) => css`
    visibility: ${open ? 'visible' : 'hidden'};
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: ${zIndex.zIndex99};
    display: flex;
    align-items: flex-end;
    left: 0;
    bottom: 0;
    background-color: rgba(38, 44, 48, 0.64);

    ${BottomSheetContent}, ${ModalMainContent} {
      width: 100%;
      scrollbar-width: thin;
      word-break: break-word;
      overflow-y: auto;
      padding-right: ${useDefaultPadding ? spacing.space4 : spacing.space0}rem;
      scrollbar-color: ${scrollbarColor ? color[scrollbarColor] || scrollbarColor : color.neutralPassiveGray}
        ${scrollbarBackgroundColor ? color[scrollbarBackgroundColor] || scrollbarBackgroundColor : color.neutralWhite};
      height: ${contentHeight ? contentHeight : 'auto'};
      scrollbar-gutter: stable;

      ${!bodyScroll &&
      css`
        touch-action: none;
      `}

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0.5rem;
      }

      ::-webkit-scrollbar-thumb {
        background: ${scrollbarColor ? color[scrollbarColor] || scrollbarColor : color.neutralPassiveGray};
        background-clip: content-box;
        border-radius: 1rem;
      }

      ::-webkit-scrollbar {
        background-color: ${scrollbarBackgroundColor
          ? color[scrollbarBackgroundColor] || scrollbarBackgroundColor
          : color.neutralWhite};
      }

      ::-webkit-scrollbar-track {
        background-color: ${scrollbarBackgroundColor
          ? color[scrollbarBackgroundColor] || scrollbarBackgroundColor
          : color.neutralWhite};
      }

      &:hover {
        ::-webkit-scrollbar-thumb {
          background-color: ${color.neutralIconGray};
        }
      }

      &:focus {
        outline: none;
        ::-webkit-scrollbar,
        ::-webkit-scrollbar-thumb {
          outline: none;
          box-shadow: none;
        }
      }
    }

    ${BottomSheetHeadlineAligner} {
      padding-top: ${useDefaultPadding ? 0 : `${spacing.space5}rem`};
    }

    ${BottomSheetWrapper} {
      background-color: ${backgroundColor ? color[backgroundColor] || backgroundColor : color.neutralWhite};
      ${useDefaultPadding &&
      css`
        padding: ${spacing.space5}rem ${spacing.space0}rem ${spacing.space4}rem ${spacing.space4}rem;
      `}
      &:before {
        background-color: ${handlebarColor ? color[handlebarColor] || handlebarColor : color.neutralNetworkGray};
      }
      ${hasImgPadding &&
      css`
        padding: 0;
      `}
    }
  `
)

export const BottomSheetClose = styled(IconButton)`
  ${({
    theme: {
      xyz: { spacing, zIndex },
    },
  }) => css`
    position: absolute;
    top: ${spacing.space2}rem;
    right: ${spacing.space2}rem;
    z-index: ${zIndex.zIndex10};
    border: none;

    &:hover,
    &:focus {
      background-color: transparent;
      border: none;
    }
  `}
`

export const BottomSheetTitle = styled(Headline).attrs({ as: 'h1', id: 'bottomsheet-title' })``

export const StyledOutsideClick = styled(OutsideClick).attrs({ 'data-testid': 'outside-click' })`
  display: flex;
  max-height: 96%;
  position: fixed;
  bottom: 0;
`
