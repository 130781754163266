import { XyzTheme } from '@postidigital/posti-theme'
import styled, { css } from 'styled-components'
import { CheckmarkIcon, MinusIcon } from '../../design-tokens/icons'

import { SwitchLabelPosition } from './Switch'

interface CircleProps {
  checked?: boolean
  disabled?: boolean
  isInvalid: boolean
}

export const StyledRoot = styled.div`
  display: inline-block;
`

export const getMainSwitchColor = ({ disabled, isInvalid, checked }: CircleProps): string => {
  if (disabled) {
    return XyzTheme.color.neutralPassiveGray
  }
  if (isInvalid) {
    return XyzTheme.color.signalRed
  }
  if (checked) {
    return XyzTheme.color.signalHoverBlue
  }
  return XyzTheme.color.neutralIconGray
}

export const StyledCircle = styled.div<CircleProps>(
  ({
    theme: {
      xyz: { borderRadius, color, zIndex },
    },
    checked,
    disabled,
    isInvalid,
  }) => css`
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    border: 0.1875rem solid ${getMainSwitchColor({ checked, disabled, isInvalid })};
    border-radius: ${borderRadius.sm}rem;
    background-color: ${color.neutralWhite};
    z-index: ${zIndex.zIndexDefault};
    transition: left 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-align: center;
  `
)

export const StyledInput = styled.input<{ disabled?: boolean; checked?: boolean }>(
  ({
    theme: {
      xyz: { zIndex },
    },
    disabled,
  }) => css`
    opacity: 0;
    height: 2rem;
    width: 100%;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    cursor: ${disabled ? 'default' : 'pointer'};
    z-index: ${zIndex.zIndex2};
    position: absolute;
    left: 0;
    top: 0;
  `
)

export const StyledBackground = styled.div<{ checked: boolean; disabled: boolean; isInvalid: boolean }>(
  ({
    theme: {
      xyz: { borderRadius, color },
    },
    checked,
    disabled,
    isInvalid,
  }) => css`
    position: absolute;
    background-color: ${isInvalid
      ? color.signalHoverRed
      : checked && !disabled
      ? color.signalBlue
      : color.neutralPassiveGray};
    width: 3.625rem;
    height: 2rem;
    border: 0.1875rem solid
      ${isInvalid ? color.signalRed : checked && !disabled ? color.signalBlue : color.neutralPassiveGray};
    border-radius: ${borderRadius.sm}rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 100ms ease-in;
  `
)

export const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  margin-top: 2px;
`

export const StyledMinusIcon = styled(MinusIcon)``

export const StyledSwitch = styled.div<{ isInvalid: boolean }>(
  ({
    theme: {
      xyz: { color, zIndex },
    },
    isInvalid,
  }) => css`
    display: inline-flex;
    width: 4rem;
    min-width: 4rem;
    height: 2rem;
    position: relative;
    vertical-align: middle;
    z-index: ${zIndex.zIndex0};

    /* Hover styles */
    ${StyledInput}:not([disabled]):hover ~ ${StyledCircle} {
      border-color: ${isInvalid ? color.signalRed : color.neutralNetworkGray};

      > ${StyledCheckmarkIcon} *,
      > ${StyledMinusIcon} * {
        fill: ${isInvalid ? color.signalRed : color.neutralNetworkGray};
      }
    }

    /* Focus styles */

    ${StyledInput}:not([disabled]):focus ~ ${StyledBackground} {
      box-shadow: 0 0 0.4375rem ${color.signalBlue};
    }

    ${StyledInput}:focus:not(:focus-visible) ~ ${StyledBackground} {
      box-shadow: none;
    }

    /* Selected styled */
    ${StyledInput}:checked ~ ${StyledCircle},
    ${StyledInput}:checked:disabled ~ ${StyledCircle} {
      left: 2rem;
    }
  `
)

export const StyledLabel = styled.label<{ labelPosition: SwitchLabelPosition; spacing: number }>(
  ({ theme, labelPosition, spacing }) => css`
    ${labelPosition === SwitchLabelPosition.left &&
    `
    ${StyledSwitch} {
      margin-left: ${spacing || 3.875}rem;
    }
  `}

    ${labelPosition === SwitchLabelPosition.right &&
    `
    ${StyledSwitch} {
      margin-right: ${spacing || theme.xyz.spacing.space3}rem;
    }
  `}
  `
)
