import React from 'react'
import { BottomSheetProps } from '../BottomSheet/BottomSheet'

export enum MobileMode {
  /* On mobile Modal will look like a BottomSheet component */
  default = 'default',
  /* On mobile Modal will look like a Modal in the alert mode */
  alert = 'alert',
  /* On mobile Modal will look like a Modal in the default mode (fullscreen) */
  fullscreen = 'fullscreen',
}

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  /*
   * By default Modal is centered into viewport. If you want to overrife this give a value (e.g. 2rem)
   * how far away Modal should be rendered from top
   */
  topPosition?: string
  width?: ModalWidth | keyof typeof ModalWidth
  maxWidth?: string
  scroll?: Scroll
  /*
   * Provides ID for an element where you want to render Modal, if else than <body />
   */
  renderInto?: string
  disableOutsideClick?: boolean
  /*
   * Hides the title and the button controls of the Modal and stretches the MainContent of the Modal to
   * take all available space. Leaves the 'X' close button on the MainContent.
   */
  fullSizeMainContent?: boolean
  /** mobileMode changes the component view on mobile only, component will have the same behavior on desktop */
  mobileMode?: keyof typeof MobileMode
  /** Object storing BottomSheet-related props */
  bottomSheetProps?: BottomSheetProps
  handleBackgroundClick?: (event: Event) => void
}

export enum ModalScroll {
  body = 'body',
  content = 'content',
}

export type Scroll = ModalScroll | keyof typeof ModalScroll

export interface WrapperProps {
  topPosition?: string
  $width?: ModalWidth | keyof typeof ModalWidth
  maxWidth?: string
  scroll?: Scroll
}

export enum ModalWidth {
  'max-content' = 'max-content',
  'min-content' = 'min-content',
  'fit-content' = 'fit-content',
  'auto' = 'auto',
}

export interface ModalContextProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type CommonProps = {
  children: React.ReactNode | ((value: ModalContextProps) => React.ReactNode)
}

type ModalWrapperProps =
  | { initialOpen?: undefined; open?: boolean; onChange?: (val: boolean) => void }
  | { initialOpen: boolean; open?: never; onChange?: never }

export type ModalWrapperContextProps = CommonProps & ModalWrapperProps
