import React, { HTMLAttributes } from 'react'

export enum NotificationType {
  alert = 'alert',
  informational = 'informational',
  success = 'success',
  warning = 'warning',
  text = 'text',
}

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  contentType?: NotificationType | keyof typeof NotificationType
  width?: string
}

export interface NotificationProps extends ContainerProps {
  /**
   * content of the notification
   */
  children: React.ReactChild
  /**
   * Enables close button
   */
  dismissible?: boolean
  showIcon?: boolean
  centerContent?: boolean
  onClose?: React.MouseEventHandler<HTMLButtonElement>
  /**
   * Text to show to screen readers for the close button
   */
  closeText?: string
  width?: string
}
