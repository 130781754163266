import React, { forwardRef, useRef } from 'react'
/* eslint-disable */
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { Input } from '../../basic-components/Input'
import { InputProps } from '../../basic-components/Input/Input.types'
import {
  StyledCalendarIcon,
  StyledMonthPickerWrapper,
  YearName,
  HeaderContainer,
  StyledMonthNavigationButton,
  StyledContainer,
} from './MonthPicker.styles'
import { HeadlineSize } from '../../design-tokens/typography'
import PropTypes from 'prop-types'

export type MonthPickerProps = ReactDatePickerProps &
  Omit<InputProps, 'id' | 'label' | 'onChange'> & {
    prevYearButtonAriaLabel: string
    nextYearButtonAriaLabel: string
    /**
     * MonthPicker uses Input component, which requires ```ìd``` for testing purposes
     */
    inputId: string
    inputLabel: string
    /**
     * The props below has type 'never' as they are predefined for MonthSelect and they shouldn't be changed for component to function correctly
     */
    dateFormat?: never
    showMonthYearPicker?: never
    customInput?: never
    calendarContainer?: never
    showFourColumnMonthYearPicker?: never
    filterDate?: never
    popperModifiers?: never
    renderCustomHeader?: never
  }

export const MonthPicker: React.FC<MonthPickerProps> = ({
  prevYearButtonAriaLabel,
  nextYearButtonAriaLabel,
  inputId,
  inputLabel,
  selectsStart,
  selectsEnd,
  ...props
}) => {
  const monthRef = useRef<HTMLInputElement>()

  const CustomInput = forwardRef<HTMLInputElement>(function CustomInput(props, ref) {
    return <Input {...props} ref={ref} id={inputId} label={inputLabel} leftTemplate={<StyledCalendarIcon />} />
  })

  const validMonths = (date: Date) => {
    if (selectsStart || selectsEnd) {
      const time = date.getMonth()
      const from = props.startDate.getMonth()
      const to = props.endDate.getMonth()
      return time >= from && time <= to
    } else return true
  }

  return (
    <StyledContainer selectsStart={selectsStart} selectsEnd={selectsEnd}>
      <DatePicker
        {...props}
        dateFormat="MMMM"
        showMonthYearPicker
        customInput={<CustomInput ref={monthRef} {...props} />}
        calendarContainer={StyledMonthPickerWrapper}
        showFourColumnMonthYearPicker
        filterDate={validMonths}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 16],
            },
          },
        ]}
        renderCustomHeader={({ monthDate, decreaseYear, increaseYear }) => (
          <HeaderContainer>
            <StyledMonthNavigationButton
              orientation={'left'}
              aria-label={prevYearButtonAriaLabel}
              onClick={decreaseYear}
            />
            <YearName size={HeadlineSize.Six}>
              {monthDate.toLocaleString('en-US', {
                year: 'numeric',
              })}
            </YearName>
            <StyledMonthNavigationButton
              orientation={'right'}
              aria-label={nextYearButtonAriaLabel}
              onClick={increaseYear}
            />
          </HeaderContainer>
        )}
      />
    </StyledContainer>
  )
}

MonthPicker.defaultProps = {
  locale: 'fi',
  prevYearButtonAriaLabel: 'Previous',
  nextYearButtonAriaLabel: 'Next',
  inputId: 'input-id',
  inputLabel: 'Month Picker',
}

MonthPicker.propTypes = {
  locale: PropTypes.string,
  prevYearButtonAriaLabel: PropTypes.string.isRequired,
  nextYearButtonAriaLabel: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
}
