import React, { useState, useCallback } from 'react'
import XyzTheme from '@postidigital/posti-theme'

export interface SpeechBubbleProps {
  message: string
  x: number // Speech bubble x-position
  y: number // Speech bubble y-position
  width: number // Speech bubble width
  height: number // Speech bubble height
  padding?: number // Padding; space between text and bubble boundaries
  centerY?: boolean // Center text vertically
  centerX?: boolean // Center text horizontally
  style?: Record<string, unknown> // Custom text styling
  debug?: boolean // Activates the debug mode
}

export const SpeechBubbleSVG: React.FC<SpeechBubbleProps> = ({
  message,
  x,
  y,
  width,
  height,
  padding = 10,
  centerY = true,
  centerX = false,
  style = {},
  debug = false,
}) => {
  // Rerender element when fonts are fully loaded.
  // Otherwise manual font wrapping might be inaccurate.
  const [, updateState] = useState<Record<string, never>>()
  const forceUpdate = useCallback(() => updateState({}), [])

  document.fonts && document.fonts.addEventListener('loadingdone', forceUpdate)

  const getSVGElements = () => {
    const ns = 'http://www.w3.org/2000/svg'
    const svg = document.createElementNS(ns, 'svg')
    const txt = document.createElementNS(ns, 'text')
    svg.appendChild(txt)
    window?.document.body.appendChild(svg)

    // Set given font styles. Set neutralBlack font color as default.
    txt.style.fill = XyzTheme.color.neutralBlack
    Object.entries(style).forEach(([key, value]) => (txt.style[`${key}`] = `${value}`))

    return { svg, txt }
  }

  const calculateSVGTextLength = (text: string): number => {
    /* Calculates text length in pixels when it is rendered inside a SVG element */
    const { svg, txt } = getSVGElements()

    // Set text size and content
    txt.textContent = text

    // Append elements temporarily into body and get width
    const width = txt.getBoundingClientRect().width

    document.body.removeChild(svg)

    return width
  }

  const getLineHeightPixels = (): number => {
    /* Calculates height of a single text element */
    const { svg, txt } = getSVGElements()
    txt.textContent = 'test'
    const height = txt.getBoundingClientRect().height
    document.body.removeChild(svg)
    return height
  }

  const breakWord = (word: string, maxLength: number): Array<string> => {
    /* Takes a single word and breaks it into smaller parts if it doesn't fit
    on one line. E.g., "veryverylongword" > ["veryverylo", "ngword"] */
    const { svg, txt } = getSVGElements()

    let char = 0
    let dx = word.length
    maxLength = Math.max(maxLength, 0)

    while (true) {
      // Select next pivot character
      char = Math.floor(char + dx)

      // Get computed text length to the pivot char
      txt.textContent = word.slice(0, char)
      const lenCurr = txt.getBoundingClientRect().width

      // Case: Word is shorter than max line length
      // > Check whether one extra character overflows
      if (lenCurr <= maxLength) {
        txt.textContent = word.slice(0, char + 1)
        const lenNext = txt.getBoundingClientRect().width

        // Case: Word has no next character
        // > Word is short enough, no wrapping needed
        if (lenNext === lenCurr) break

        // Case: Next character overflows
        // > Break word here
        if (lenNext > maxLength) break
      }

      // dx helps us find next optimal pivot character.
      dx = Math.abs(dx) / 2
      dx = Math.max(dx, 1)
      if (lenCurr > maxLength) dx *= -1
    }

    document.body.removeChild(svg)

    // Char must be atleast 1, zero causes infinite
    // loop has the tail gets recursively rewrapped
    char = Math.max(char, 1)

    const head = word.slice(0, char)
    const tail = word.slice(char)

    // Case: Word wasn't wrapped at all
    // > Return entire head
    if (head === word) {
      return [].concat(head)
    }
    // Case: Word was wrapped
    // > Return first part of the word
    // & wrap also overflowing part
    else {
      return [].concat(head, breakWord(tail, maxLength))
    }
  }

  const wrapText = (text: string, lineLengthPixels: number): Array<string> => {
    /* SVG test does not support wrapping. This function takes text as an input,
    and returns lines. */
    const lines = []

    // Split text into words and break too long ones
    const words = text.split(' ').reduce((result, word) => {
      return result.concat(breakWord(word, lineLengthPixels))
    }, [])

    let line = words.shift()
    words.map((word) => {
      const newLine = `${line} ${word}`
      if (calculateSVGTextLength(newLine) <= lineLengthPixels) {
        line = newLine
      } else {
        lines.push(line)
        line = word
      }
    })
    lines.push(line)
    return lines
  }

  const ConditionalDebug = ({ debug }) => {
    /* Renders debug lines to help aiming text with the image */
    if (debug) {
      const radius = 2
      return (
        <>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            rx={radius}
            ry={radius}
            stroke="red"
            strokeWidth={1}
            fill="#FFFFFF00"
          />
          <rect
            x={x + padding}
            y={y + padding}
            width={width - padding * 2}
            height={height - padding * 2}
            rx={radius}
            ry={radius}
            stroke="grey"
            strokeDasharray="2 2"
            strokeWidth={1}
            fill="#FFFFFF00"
          />
        </>
      )
    }
    return null
  }

  const lineHeight = getLineHeightPixels()
  let lines = wrapText(message, width - padding * 2)

  // Case: Too many rows, text overflows
  // > Add "..." in the end of last line
  // Give more room if text is centered vertically. Vertically centered text
  // looks good even though it is almost overflowing.
  const verticalRoom = centerY ? height : height - padding
  const maxLines = Math.floor(verticalRoom / lineHeight)
  if (lines.length > maxLines) {
    lines = lines.slice(0, maxLines)
    lines[lines.length - 1] += String.fromCharCode(8230)
  }

  let posX = x + padding
  let posY = y + padding - lineHeight / 4
  let textAnchor = ''
  let transform = ''

  if (centerY) {
    posY = y + height / 2
    transform = `translate(0, -${(lines.length * lineHeight) / 2 + lineHeight / 4})`
  }

  if (centerX) {
    posX = x + width / 2
    textAnchor = 'middle'
  }

  return (
    <>
      <ConditionalDebug debug={debug} />
      <text x={posX} y={posY} textAnchor={textAnchor} transform={transform} {...style}>
        {lines.map((line, index) => (
          <tspan key={`speech-bubble-line-${index}`} x={posX} dy={lineHeight}>
            {line}
          </tspan>
        ))}
      </text>
    </>
  )
}

export default SpeechBubbleSVG
