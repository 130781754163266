import styled, { css } from 'styled-components'
import Button from '../../basic-components/Button'
import { NavigationItemProps } from './TopBar.types'

export const TopBarContainer = styled.div<{ isDark: boolean }>(
  ({
    theme: {
      xyz: { color, breakpoint },
    },
    isDark,
  }) => {
    const secondaryColor = isDark ? color.neutralNetworkGray : color.neutralWhite
    const primaryColor = isDark ? color.neutralWhite : color.neutralNetworkGray
    return css`
      background: ${secondaryColor};
      color: ${primaryColor};
      width: 100%;
      box-shadow: rgba(27, 31, 35, 0.07) 0px 8px 22px;

      & #topbar-locale-dropdown {
        display: none;
        @media screen and (min-width: ${breakpoint.lg}) {
          display: inline-flex;
        }
      }

      ${StyledMenuButton} {
        background-color: transparent;
        &:before {
          background-color: transparent;
        }
        &:focus {
          box-shadow: 0px 0px 7px ${isDark ? color.brandBrightYellow : color.signalBlue};
        }
      }
    `
  }
)

export const TopBarContent = styled.div<{ hasTopLevelMenu: boolean; hasSearch: boolean }>(
  ({
    theme: {
      xyz: { breakpoint, spacing },
    },
  }) => css`
    width: 100%;
    max-width: 100rem;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: ${spacing.space2}rem 0;
    min-height: 4.5rem;

    @media screen and (min-width: ${breakpoint.lg}) {
      padding: ${spacing.space2}rem 0.75rem;
    }
  `
)

export const LeftItems = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  & svg {
    height: 100%;
  }
  flex: 0 1 auto;
`

export const RightItems = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export const LogoContainer = styled.a<{ hideInMobile: boolean; isDark: boolean }>(
  ({
    theme: {
      xyz: { breakpoint, spacing, color, borderRadius },
    },
    isDark,
    hideInMobile,
  }) => css`
    padding: ${spacing.space2}rem;
    display: ${hideInMobile ? 'none' : 'initial'};
    margin-right: ${spacing.space1}rem;
    border-radius: ${borderRadius.xs}rem;
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;
      background-color: ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
      ${!isDark &&
      css`
        svg {
          & > path {
            fill: ${color.signalBlue};
          }
        }
      `}
    }

    &:focus {
      outline: none;
      border: 1px solid ${isDark ? color.brandBrightYellow : color.signalBlue};
      box-shadow: 0px 0px 7px ${isDark ? color.brandBrightYellow : color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      outline: none;
      border: 1px solid transparent;
      box-shadow: none;
    }

    @media screen and (min-width: ${breakpoint.md}) {
      display: initial;
    }

    @media screen and (min-width: ${breakpoint.lg}) {
      padding: ${spacing.space2}rem;
      margin-right: ${spacing.space3}rem;
    }
  `
)

const TopBarButton = styled(Button)<{ isDark: boolean }>(
  ({
    theme: {
      xyz: { spacing, color },
    },
    isDark,
  }) => css`
    margin: 0 0.1rem;
    padding: 0.2rem ${spacing.space2}rem;

    span {
      padding: 0;
    }
    &:hover,
    &:active,
    &:focus {
      outline: none;
      color: ${isDark ? color.neutralWhite : color.neutralBlack};
      &::before {
        background: ${isDark ? color.neutralIconGray : color.neutralOnPressGray};
      }
    }
  `
)

export const StyledMenuButton = styled(TopBarButton)<{ isDark: boolean }>(
  ({
    theme: {
      xyz: { breakpoint, spacing, color },
    },
    isDark,
  }) => css`
    height: 50%;
    margin-left: ${spacing.space2}rem;

    &:hover,
    &:active,
    &:focus {
      &:before: {
        background-color: transparent;
      }
    }

    &:focus {
      box-shadow: 0px 0px 7px ${isDark ? color.brandBrightYellow : color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: 3px solid ${isDark ? '#73808C' : 'rgb(221, 225, 233)'};
    }

    @media screen and (min-width: ${breakpoint.lg}) {
      display: none;
    }
  `
)

export const SearchButton = styled(TopBarButton)<{ isDark: boolean }>(
  ({
    theme: {
      xyz: { breakpoint, color, spacing },
    },
    isDark,
  }) => css`
    & .searchText {
      display: none;
    }

    &:hover,
    &:focus,
    &:active {
      &:before {
        background-color: transparent;
      }
      svg {
        & > path {
          fill: ${isDark ? color.brandBrightYellow : color.signalBlue};
        }
      }
      div {
        color: ${isDark ? color.brandBrightYellow : color.signalBlue};
      }
      & > span:before {
        border-bottom: 2px solid ${isDark ? color.brandBrightYellow : color.signalBlue};
        background-color: ${isDark ? color.brandBrightYellow : color.signalBlue};
      }
    }

    &:hover {
      background-color: ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
      border: 3px solid ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
    }

    &:focus {
      box-shadow: 0px 0px 7px ${isDark ? color.brandBrightYellow : color.signalBlue};
      background-color: ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
      border: 3px solid ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: 3px solid ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
    }

    @media screen and (min-width: ${breakpoint.lg}) {
      & .searchText {
        display: inline-flex;
      }
      & svg {
        margin-right: ${spacing.space1}rem;
      }
      & > span {
        height: 100%;
        position: relative;

        &:before {
          content: ' ';
          position: absolute;
          bottom: 0;
          height: 1px;
          width: 100%;
        }
      }
    }
  `
)

export const LoginStatus = styled.div`
  margin: 0 0.75rem;
  height: 100%;
  display: flex;
  align-items: center;
`

export const LoginButton = styled(TopBarButton)<{ isDark: boolean }>(
  ({
    theme: {
      xyz: { breakpoint, color, fontSize, spacing },
    },
    isDark,
  }) => css`
    border: 2px solid ${isDark ? color.neutralWhite : color.signalBlue};
    background-color: ${isDark ? 'transparent' : color.neutralWhite};
    height: 50%;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.6rem;

    span {
      color: ${isDark ? color.neutralWhite : color.signalBlue};
      font-size: ${fontSize.body.five}rem;
    }

    & .loginButtonText {
      display: none;
    }

    svg {
      margin-right: 0;
      & > path {
        fill: ${isDark ? color.neutralWhite : color.signalBlue};
      }
    }

    &:hover,
    &:active {
      &::before {
        background-color: ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
        border: 2px solid ${isDark ? color.brandBrightYellow : color.signalBlue};
      }

      span {
        color: ${isDark ? color.brandBrightYellow : color.signalBlue};
      }

      ${isDark &&
      css`
        color: ${color.brandBrightYellow};
        svg {
          & > path {
            fill: ${color.brandBrightYellow};
          }
        }
      `}
    }

    &:focus {
      border: 2px solid ${isDark ? color.brandBrightYellow : color.signalBlue};
      background-color: ${isDark ? color.neutralWhite : color.signalBlue};

      ${isDark &&
      css`
        box-shadow: 0px 0px 7px ${color.brandBrightYellow};
      `}

      &:before {
        border: 2px solid transparent;
        background-color: ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
      }

      & span {
        color: ${isDark ? color.brandBrightYellow : color.signalBlue};
      }
      & svg {
        filter: none;
        & > path {
          fill: ${isDark ? color.brandBrightYellow : color.signalBlue};
        }
      }
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: 2px solid ${isDark ? color.brandBrightYellow : color.signalBlue};
    }

    @media screen and (min-width: ${breakpoint.md}) {
      width: auto;
      height: auto;

      & .loginButtonText {
        display: inline-flex;
        margin-left: ${spacing.space2}rem;
      }
    }
  `
)

export const MenuList = styled.ul(
  ({
    theme: {
      xyz: { breakpoint, spacing },
    },
  }) => css`
    display: none;
    align-items: stretch;
    flex: 1 1 auto;
    list-style: none;
    margin: 0;
    padding: 0 ${spacing.space1}rem;
    height: 100%;
    @media screen and (min-width: ${breakpoint.lg}) {
      display: flex;
    }
  `
)

export const DesktopMenuList = styled(MenuList)`
  display: none !important;
  margin: auto;
`

export const TabletMenuList = styled(MenuList)(
  ({
    theme: {
      xyz: { breakpoint },
    },
  }) => css`
    display: none;
    max-width: 100rem;
    margin: auto;
    @media screen and (min-width: ${breakpoint.lg}) {
      display: flex;
      margin-top: -0.75rem;
    }
  `
)

export const RootMenuItem = styled.li<{ isDark: boolean; isActive: boolean }>(
  ({
    theme: {
      xyz: { fontSize, fontWeight, color, lineHeight, borderRadius, spacing, breakpoint },
    },
    isActive,
    isDark,
  }) => css`
    word-break: normal;
    display: flex;
    align-items: center;
    margin-right: ${spacing.space2}rem;

    &:last-child {
      margin-right: 0;
    }

    &:focus {
      outline: 0;
    }

    & > a {
      color: ${isDark ? color.neutralWhite : color.neutralNetworkGray};
      font-size: ${fontSize.body.five}rem;
      font-weight: ${fontWeight.medium};
      line-height: ${lineHeight.headline.seven}rem;
      border-radius: ${borderRadius.lg}rem;
      border: 1px solid transparent;
      display: none;

      &:hover {
        background-color: ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
      }

      &:hover,
      &:active {
        text-decoration: none;
      }

      &:focus {
        outline: none;
        border: 1px solid ${isDark ? color.brandBrightYellow : color.signalBlue};
        box-shadow: 0px 0px 7px ${isDark ? color.brandBrightYellow : color.signalBlue};
      }

      &:focus:not(:focus-visible) {
        box-shadow: none;
        border: 1px solid transparent;
      }

      &:hover,
      &:active,
      &:focus {
        color: ${isDark ? color.brandBrightYellow : color.signalBlue};

        & > svg > * {
          fill: ${isDark ? color.brandBrightYellow : color.signalBlue};
        }
      }

      ${isActive &&
      css`
        &:hover,
        &:active,
        &:focus {
          background-color: ${isDark ? '#D69A00' : color.neutralNetworkGray};
          color: ${isDark ? color.neutralBlack : color.neutralWhite};
        }

        @media screen and (min-width: ${breakpoint.sm}) {
          display: flex;
          padding: ${spacing.space1}rem ${spacing.space2}rem;
        }

        @media screen and (min-width: ${breakpoint.lg}) {
          background-color: ${isDark ? color.brandBrightYellow : color.neutralNetworkGray};
          color: ${isDark ? color.neutralNetworkGray : color.neutralWhite};
          border-radius: ${borderRadius.sm}rem;

          &:hover,
          &:active {
            color: ${isDark ? color.neutralBlack : color.neutralWhite};
          }

          &:focus {
            color: ${isDark ? color.neutralNetworkGray : color.neutralWhite};
            background-color: ${isDark ? color.brandBrightYellow : color.neutralNetworkGray};
          }

          &:focus:not(:focus-visible) {
            color: ${isDark ? color.neutralBlack : color.neutralWhite};
            background-color: ${isDark ? '#D69A00' : color.neutralBlack};
          }

          padding: ${spacing.space1}rem ${spacing.space3}rem;
        }
      `}

      ${!isActive &&
      css`
        @media screen and (min-width: ${breakpoint.lg}) {
          display: flex;
        }
      `}
    }
  `
)

export const RootMenu = styled(MenuList)<{ rootMenuItems: NavigationItemProps[] }>(
  ({
    theme: {
      xyz: { color, breakpoint, spacing },
    },
    rootMenuItems,
  }) => css`
    height: 40%;
    display: flex;

    @media screen and (min-width: ${breakpoint.lg}) {
      padding-left: ${spacing.space3}rem;
      border-left: ${rootMenuItems ? `1px solid ${color.neutralPassiveGray}` : 'none'};
    }
  `
)

export const RootMenuRight = styled(MenuList)`
  justify-content: flex-end;
  align-items: center;
  display: flex;

  ${RootMenuItem} {
    height: 40%;
    margin: 0;
  }
`

export const LocationButton = styled.a<{ isDark: boolean }>(
  ({
    theme: {
      xyz: { breakpoint, spacing, color },
    },
    isDark,
  }) => css`
    display: none;
    padding: ${spacing.space1}rem ${spacing.space2}rem;

    & svg {
      margin-right: ${spacing.space1}rem;
      path {
        fill: ${isDark ? color.neutralWhite : color.neutralNetworkGray};
      }
    }

    &:hover {
      background-color: ${isDark ? color.neutralBlack : color.neutralNetworkGray};
    }

    &:hover,
    &:focus {
      svg path {
        fill: ${isDark ? color.brandBrightYellow : color.signalBlue};
      }
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: 1px solid transparent;
    }

    @media screen and (min-width: ${breakpoint.lg}) {
      display: inline-flex;
      align-items: center;
    }
  `
)

export const TopLevelMenuItem = styled(RootMenuItem)<{ selected: boolean; isDark: boolean }>(
  ({
    theme: {
      xyz: { color, fontSize, fontWeight, borderRadius, spacing },
    },
    selected,
    isDark,
  }) => {
    const selectedColor = isDark ? color.brandMutedYellow : color.neutralNetworkGray
    return css`
      border-bottom-left-radius: ${selected ? '0' : `${borderRadius.md}rem`};
      border-bottom-right-radius: ${selected ? '0' : `${borderRadius.md}rem`};
      border-bottom: ${selected ? `4px solid ${selectedColor}` : '4px solid transparent'};
      padding: ${spacing.space1}rem 0;
      margin: 0;

      & > a {
        font-size: ${fontSize.headline.seven}rem;
        font-weight: ${fontWeight.semiBold};

        &:hover {
          color: ${isDark ? color.brandBrightYellow : color.signalBlue};
          background-color: ${isDark ? color.neutralBlack : color.neutralOnHoverGray};
        }

        &:active,
        &:focus {
          color: ${isDark ? color.brandBrightYellow : color.neutralNetworkGray};
        }
      }
    `
  }
)

export const StyledLink = styled.a(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    padding: ${spacing.space1}rem ${spacing.space3}rem;
    display: block;

    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: 1px solid transparent;
    }
  `
)
