import React from 'react'
import PropTypes from 'prop-types'
import { StyledFilterButton, StyledLabelPart } from './TimeSlot.styles'
import { FilterButtonProps } from '../../../basic-components/FilterButton'
import dayjs from 'dayjs'
export interface TimeSlotProps extends FilterButtonProps {
  id: string
  date: string
  from: string
  to: string
  price?: string
  ariaLabelFrom: string
  ariaLabelTo: string
  ariaLabelDash?: string
  message?: string
}

const getHoursMinutes = (timestamp: string): string => {
  return dayjs(timestamp).format('HH:mm')
}

const getDateHeader = (date: string, locale: string): string => {
  const header = dayjs(date).locale(locale).format('dddd D. MMMM YYYY')
  return header.charAt(0).toUpperCase() + header.slice(1)
}

const TimeSlot: React.FC<TimeSlotProps> = (props) => {
  const { from, to, price, ariaLabelDash, ariaLabelFrom, ariaLabelTo, message, ...rest } = props

  return (
    <StyledFilterButton
      {...rest}
      aria-label={`${ariaLabelFrom} ${ariaLabelDash !== undefined && ariaLabelDash} ${ariaLabelTo} ${
        price !== undefined && price
      }`}
    >
      <StyledLabelPart>
        <span aria-hidden="true">{from}</span>
        <span aria-hidden="true"> - </span>
        <span aria-hidden="true">{to}</span>
      </StyledLabelPart>
      {price !== undefined && (
        <StyledLabelPart>
          <span aria-hidden="true">{price}</span>
        </StyledLabelPart>
      )}
    </StyledFilterButton>
  )
}

TimeSlot.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  price: PropTypes.string,
  ariaLabelFrom: PropTypes.string.isRequired,
  ariaLabelTo: PropTypes.string.isRequired,
  ariaLabelDash: PropTypes.string,
  message: PropTypes.string,
}

TimeSlot.defaultProps = {
  ariaLabelDash: '',
  price: '',
}

export { TimeSlot, getHoursMinutes, getDateHeader }
