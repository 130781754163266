import React, { HTMLAttributes, MouseEvent } from 'react'
import PropTypes, { Validator } from 'prop-types'
import { PostiLogo } from '../../design-tokens/BrandAsset'
export interface NavigationItemProps {
  id?: string
  title: string
  href: string
  icon?: JSX.Element
  active?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
}
export interface TopBarProps extends HTMLAttributes<HTMLDivElement> {
  navigationState: {
    isBusiness?: boolean
    topLevelMenuItems?: NavigationItemProps[]
    rootMenuItems?: NavigationItemProps[]
    locales?: string[]
    currentPage?: NavigationItemProps
    activeTopLevelItemId?: string
    rootPage?: NavigationItemProps
  }
  searchMessage?: string
  loginMessage?: string
  logo?: any
  servicePointsItem?: NavigationItemProps
  loginTemplate?: JSX.Element
  languageSelectorLabel?: string
  languageSelectorMenuLabel?: string
  closeSearchLabel?: string
  onSearchFocus?: () => void
  onSearchBlur?: () => void
  onSearchClose?: () => void
  onSearchButtonClick?: () => void
  onLoginButtonClick: () => void
  onSearchQueryChange?: () => void
  onSearchSubmit?: () => void
  onSearchItemSelect?: (value: any) => void
  onLocaleSelect: (id: string) => void
  hasSearch?: boolean
  hasLogin?: boolean
  hasLanguageSelector?: boolean
  menuButton: React.ReactNode
}

export const NavigationItemType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
}) as Validator<NavigationItemProps>

export const NavigationStateType = PropTypes.shape({
  isBusiness: PropTypes.bool,
  topLevelMenuItems: PropTypes.arrayOf(NavigationItemType),
  rootMenuItems: PropTypes.arrayOf(NavigationItemType),
  currentPage: NavigationItemType,
  locales: PropTypes.arrayOf(PropTypes.string),
  currentLocale: PropTypes.string,
  rootPage: NavigationItemType,
  activeTopLevelItemId: PropTypes.string,
}).isRequired as Validator<TopBarProps['navigationState']>

export const TopBarPropTypes = {
  navigationState: NavigationStateType,
  onLoginButtonClick: PropTypes.func.isRequired,
  onLocaleSelect: PropTypes.func.isRequired,
  searchMessage: PropTypes.string,
  loginMessage: PropTypes.string,
  servicePointsItem: NavigationItemType,
  onSearchFocus: PropTypes.func,
  onSearchBlur: PropTypes.func,
  onSearchClose: PropTypes.func,
  onSearchButtonClick: PropTypes.func,
  onSearchQueryChange: PropTypes.func,
  onSearchSubmit: PropTypes.func,
  onSearchItemSelect: PropTypes.func,
  logo: PropTypes.any,
  loginTemplate: PropTypes.element,
  languageSelectorLabel: PropTypes.string,
  languageSelectorMenuLabel: PropTypes.string,
  closeSearchLabel: PropTypes.string,
  hasSearch: PropTypes.bool,
  hasLogin: PropTypes.bool,
  hasLanguageSelector: PropTypes.bool,
  menuButton: PropTypes.element.isRequired,
}

export const TopBarDefaultProps = {
  closeSearchLabel: 'Close search',
  searchMessage: 'Search',
  loginMessage: 'Log in',
  logo: PostiLogo,
  onSearchButtonClick: (): boolean => true,
  onLoginButtonClick: (): boolean => true,
  hasSearch: false,
  hasLogin: false,
  hasLanguageSelector: false,
  navigationState: {
    isBusiness: false,
  },
}
