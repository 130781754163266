import styled, { css } from 'styled-components'
import { Container } from './Notification.style'
import { ComplexNotificationIllustrationProps } from './ComplexNotification.types'
import { Headline } from '../../design-tokens/typography/Headline'

export const ComplexNotificationContainer = styled(Container)`
  border-radius: ${({ theme }) => theme.xyz.borderRadius.sm}rem;
  overflow: hidden;
`

export const ComplexNotificationWrapper = styled.div(
  ({
    theme: {
      xyz: { breakpoint, spacing },
    },
  }) => css`
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: ${spacing.space4}rem;

    @media screen and (max-width: ${breakpoint.sm}) {
      padding: ${spacing.space3}rem;
    }
  `
)

export const ComplexNotificationTextWrapper = styled.div(
  ({
    theme: {
      xyz: { breakpoint, color, spacing },
    },
  }) => css`
    flex: 1 1 0;
    color: ${color.brandPetrol};

    margin-right: ${spacing.space3}rem;

    @media screen and (max-width: ${breakpoint.sm}) {
      margin-right: ${spacing.space2}rem;
    }
  `
)

export const ComplexNotificationTitle = styled(Headline)<{ addMarginBottom: boolean }>(
  ({
    theme: {
      xyz: { spacing },
    },
    addMarginBottom,
  }) => css`
    ${addMarginBottom ? ` margin-bottom: ${spacing.space2}rem; ` : ''}
  `
)

export const ComplexNotificationIllustrationWrapper = styled.div<ComplexNotificationIllustrationProps>(
  ({
    theme: {
      xyz: { breakpoint, spacing },
    },
    cutOnNarrowScreen,
    withVerticalIndent,
    withHorizontalIndent,
  }) => `
    flex: 0 0 auto;
    overflow: hidden;

    & > * {
      display: block;
      margin-left: auto;
    }

    ${
      withHorizontalIndent
        ? ''
        : `
        margin-right: -${spacing.space4}rem;
    
        @media screen and (max-width: ${breakpoint.sm}) {
          margin-right: -${spacing.space3}rem;
        }
      `
    }

    ${
      withVerticalIndent
        ? ''
        : `
        margin-top: -${spacing.space4}rem;
        margin-bottom: -${spacing.space4}rem;
      `
    }

    ${
      cutOnNarrowScreen
        ? `
      flex: 1 1 0;

      @media screen and (max-width: ${breakpoint.sm}) {
        margin-right: -${spacing.space4}rem;
      }
    `
        : ''
    }
  `
)
