import React from 'react'
import styled, { css } from 'styled-components'
import { ArrowLeftIcon, ArrowRightIcon } from '../../design-tokens/icons'
import { useTheme } from '../../utils/useTheme'
import { ButtonProps } from '../../basic-components/Button/Button'
import { IconButton } from '../../basic-components/IconButton'

export interface MonthNavigationButtonProps extends ButtonProps {
  orientation: 'left' | 'right'
  'aria-label'?: string
}

export const StyledButton = styled(IconButton)<{ isLeft: boolean }>(
  ({
    theme: {
      xyz: { spacing, color },
    },
    isLeft,
  }) => css`
    position: absolute;
    top: ${spacing.space3}rem;
    border: 2px solid transparent;
    ${isLeft
      ? `
    left: ${spacing.space3}rem;
    `
      : `
    right: ${spacing.space3}rem;
    `}
    &:focus {
      border: 2px solid ${color.signalBlue};
      box-shadow: none;
    }
    &:hover,
    &:active,
    &:hover:focus {
      border: 2px solid ${color.neutralOnPressGray};
    }
    &:focus:hover,
    &:focus:active {
      background-color: ${color.neutralOnPressGray};
      border: 2px solid ${color.signalBlue};
    }
    &:focus:not(:focus-visible) {
      border: 2px solid ${color.neutralOnPressGray};
    }
  `
)

export const MonthNavigationButton: React.FC<MonthNavigationButtonProps> = ({ orientation, ...props }) => {
  const theme = useTheme()
  const isLeft = orientation === 'left'

  return (
    <StyledButton
      isLeft={isLeft}
      icon={isLeft ? ArrowLeftIcon : ArrowRightIcon}
      iconColor={theme.xyz.color.neutralIconGray}
      {...props}
    />
  )
}
