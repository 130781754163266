import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'

import { Theme } from '@postidigital/posti-theme'
import { ThemeColor, TextSize } from '../../../utils/helpers'

export interface TextProps {
  bold?: boolean
  semibold?: boolean
  size?: TextSize
  color?: ThemeColor | string
}

/**
 * For extending props without needing to export an interface; makes usage of this component easier where needed
 * @deprecated please use Body or Label component instead
 */
const Text = styled.div<TextProps>(({ theme, bold, semibold, size, color }) => {
  return css`
    ${bold && `font-weight: ${theme.fontWeight.bold};`}
    ${semibold && `font-weight: ${theme.fontWeight.semiBold};`}

    color: ${theme.color[color] || color};
    font-size: ${theme.fontSize.text[size]}rem;
    line-height: ${theme.lineHeight.text[size]}rem;
    font-family: ${theme.fontFamily.primary};
  `
})

Text.propTypes = {
  size: PropTypes.oneOf(Object.keys(Theme.fontSize.text)) as Validator<TextSize>,
  color: PropTypes.string,
  bold: PropTypes.bool,
  semibold: PropTypes.bool,
}

Text.defaultProps = {
  size: 'md',
  color: Theme.color.neutralNetworkGray,
  bold: false,
  semibold: false,
}

export default Text
