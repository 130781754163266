import React from 'react'
import PropTypes, { Validator } from 'prop-types'

import { NavButtonProps, NavButtonMode } from './NavButton.types'
import { StyledButton, Title, StyledChevron } from './NavButton.style'
import { XyzTheme } from '@postidigital/posti-theme'

const NavButton = React.forwardRef<HTMLButtonElement, NavButtonProps>(
  (
    {
      isOpen,
      contentMode,
      title,
      arrowColor = XyzTheme.color.neutralNetworkGray,
      selectedColor = XyzTheme.color.neutralNetworkGray,
      selected,
      className,
      Icon = null,
      withArrow = true,
      onClick,
      ...rest
    },
    buttonRef
  ) => {
    const onArrowClick: React.MouseEventHandler = (e) => {
      // Prevents opening the link, if the user just wants to open the dropdown
      e.preventDefault()
    }

    return (
      <StyledButton
        ref={buttonRef}
        isOpen={isOpen}
        onClick={onClick}
        contentMode={contentMode}
        selectedColor={selectedColor}
        className={`${className}${selected ? ' selected' : ''}`}
        {...rest}
      >
        {Icon && <Icon />}
        <Title>{title}</Title>
        {withArrow && <StyledChevron width="100%" height="100%" color={arrowColor} onClick={onArrowClick} />}
      </StyledButton>
    )
  }
)

NavButton.displayName = 'NavButton'

NavButton.propTypes = {
  isOpen: PropTypes.bool,
  contentMode: PropTypes.oneOf(Object.keys(NavButtonMode)) as Validator<NavButtonMode>,
  title: PropTypes.string.isRequired,
  arrowColor: PropTypes.string,
  selectedColor: PropTypes.string,
  selected: PropTypes.bool,
  withArrow: PropTypes.bool,
}

NavButton.defaultProps = {
  isOpen: false,
  contentMode: NavButtonMode.secondary,
  arrowColor: XyzTheme.color.neutralNetworkGray,
  selectedColor: XyzTheme.color.neutralNetworkGray,
  selected: false,
  withArrow: true,
}

export { NavButton }
