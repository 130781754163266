//@ts-strict
import React, { CSSProperties, useState, useRef, HTMLAttributes, useEffect, useCallback, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import { useSpring, animated as a, config } from 'react-spring'
import { useDrag } from '@use-gesture/react'
import FocusLock from 'react-focus-lock'
import PropTypes from 'prop-types'
import { BackDrop, BottomSheetWrapper, StyledOutsideClick } from './BottomSheet.styles'
import { ThemeColor } from '../../utils/helpers'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import debounce from 'lodash/debounce'

export interface BottomSheetProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The background color of the Bottomsheet.
   * Using a preset will override this
   */
  backgroundColor?: ThemeColor | string
  /**
   * Define the CSS properties of backdrop
   */
  backDropStyles?: CSSProperties
  /**
   * Function to set the outer state to match inner state
   */
  onClose?: () => void
  /**
   * Customize the scrollbar color
   */
  scrollbarColor?: ThemeColor | string
  /**
   * Customize the scrollbar's background color
   */
  scrollbarBackgroundColor?: ThemeColor | string
  /**
   * Whether BottomSheet is draggable via mouse/touch.
   */
  isDraggable?: boolean
  /**
   * Customize the handle bar color
   */
  handlebarColor?: ThemeColor | string
  /**
   * Pre-configured default paddings are: top: 2rem, bottom: 1rem, left: 1.5rem, right: 1rem
   */
  useDefaultPadding?: boolean
  /**
   * Defines the BottomSheet content height, 'auto' by default.
   */
  contentHeight?: string
  hasImgPadding?: boolean
  isOpen?: boolean
  disableOutsideClick?: boolean
}

// Time in milliseconds that it takes for the component to animate itself open
const ANIMATION_TIME = 300
// Determines the ammount of pixels that user has to drag in order to close the bottomsheet
const THRESHOLD = 100

const BottomSheet: React.FC<BottomSheetProps> = ({
  backgroundColor,
  onClose,
  children,
  scrollbarColor,
  scrollbarBackgroundColor,
  isDraggable,
  handlebarColor,
  useDefaultPadding,
  contentHeight,
  backDropStyles,
  hasImgPadding,
  isOpen,
  disableOutsideClick,
}) => {
  const [animationComplete, setAnimationComplete] = useState(false)
  const [bodyScroll, setBodyScroll] = useState<boolean>(true)
  const initialWrapWidth = window.innerWidth
  const [wrapperWidth, setWrapperWidth] = useState(initialWrapWidth)
  const [focusBeforeBottomSheet, setFocusBeforeBottomSheet] = useState<HTMLElement | null>(null)

  const backdropRef = useRef<HTMLDivElement>()

  const close = useCallback(() => {
    if (onClose) {
      setTimeout(() => {
        onClose()
      }, ANIMATION_TIME)
    }
  }, [onClose])

  // Initializes BottomSheet with body scroll lock and focus lock when the component is opened. Timeout is used to activate the focus lock only after the animation has been completed.
  // This hook also sets previously selected element where the focus will be returned when BottomSheet is closed, and sets keydown listener so the BottomSheet can be closed by ESC keypress.
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === KeyboardKeys.Escape) {
        close()
        setBodyScroll(false)
      }
    }

    const animationTimeout = setTimeout(() => {
      setAnimationComplete(true)
    }, ANIMATION_TIME)

    if (isOpen) {
      setFocusBeforeBottomSheet((document.activeElement as HTMLElement) || null)
      window.document.addEventListener('keydown', onKeyDown)
      // Prevent scroll on touch screens while dragging
      setBodyScroll(true)
    }

    return () => {
      window.document.removeEventListener('keydown', onKeyDown)
      clearTimeout(animationTimeout)
    }
  }, [isOpen, close])

  const onBackgroundClick = (e: Event) => {
    if (!disableOutsideClick) {
      e.stopPropagation()
      close()
    }
  }

  const displayBottomSheetAnimation = useSpring({
    from: { transform: `translate3d(0,${isOpen ? 100 : 0}%,0)` },
    to: { transform: `translate3d(0,${isOpen ? 0 : 100}%,0)` },
    config: {
      ...config.slow,
      duration: ANIMATION_TIME,
    },
  })

  const [props, set] = useSpring(() => ({
    y: 0,
  }))

  const bind = useDrag(({ last, down, movement: [, y], cancel }) => {
    setBodyScroll(false)

    if (y < 0 || !isDraggable) {
      cancel()
    } else if (last && y > THRESHOLD) {
      close()
    }
    set({
      y: down ? y : 0,
      immediate: down,
      config: {
        tension: 500,
        friction: 50,
      },
    })
  })

  const onFocusLockDeactivation = useCallback(() => {
    if (focusBeforeBottomSheet) {
      // as per https://github.com/theKashey/react-focus-lock#unmounting-and-focus-management
      setTimeout(() => focusBeforeBottomSheet.focus(), 0)
    }
  }, [focusBeforeBottomSheet])

  useLayoutEffect(() => {
    const onResize = debounce(function () {
      setWrapperWidth(window.innerWidth)
    }, 20)
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <>
      {ReactDOM.createPortal(
        <>
          <BackDrop
            style={backDropStyles}
            useDefaultPadding={useDefaultPadding}
            backgroundColor={backgroundColor}
            handlebarColor={handlebarColor}
            scrollbarColor={scrollbarColor}
            scrollbarBackgroundColor={scrollbarBackgroundColor}
            contentHeight={contentHeight}
            bodyScroll={bodyScroll}
            hasImgPadding={hasImgPadding}
            open={isOpen}
            ref={backdropRef}
          >
            <StyledOutsideClick onOutsideClick={(e) => onBackgroundClick(e)}>
              <a.div
                /**
                 * @use-gesture's bind() causes ts-error "This expression is not callable." which is related to strictNullChecks
                 * This error should be fixed in the later versions of TypeScript
                 */
                /* eslint-disable */
                // @ts-ignore
                {...(isDraggable ? bind() : () => {})}
                style={{ ...props, ...displayBottomSheetAnimation, display: 'flex', touchAction: 'none' }}
              >
                <FocusLock disabled={!animationComplete} onDeactivation={onFocusLockDeactivation}>
                  <BottomSheetWrapper style={{ minWidth: `${wrapperWidth}px` }}>{children}</BottomSheetWrapper>
                </FocusLock>
              </a.div>
            </StyledOutsideClick>
          </BackDrop>
        </>,
        document.body
      )}
    </>
  )
}

BottomSheet.defaultProps = {
  isDraggable: true,
  useDefaultPadding: true,
  disableOutsideClick: false,
}

BottomSheet.propTypes = {
  backgroundColor: PropTypes.string,
  onClose: PropTypes.func,
  scrollbarColor: PropTypes.string,
  handlebarColor: PropTypes.string,
  scrollbarBackgroundColor: PropTypes.string,
  useDefaultPadding: PropTypes.bool,
  backDropStyles: PropTypes.object,
  contentHeight: PropTypes.string,
  isOpen: PropTypes.bool,
  disableOutsideClick: PropTypes.bool,
}

export default BottomSheet
