import React, { HTMLAttributes, useState, CSSProperties } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { KeyboardKeys } from '../../utils/keyboardNavigation'
import ExpanderTitle from './ExpanderTitle'
import ExpanderBody from './ExpanderBody'
import { ThemeColor } from '../../utils/helpers'
import { XyzTheme } from '@postidigital/posti-theme'

export interface ExpanderProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Use this to force the expander open or to manage the state from outside (useful if it needs to be e.g. open by default)
   */
  open?: boolean
  /**
   * Change the background color of the component when it is clicked or activated
   */
  changeColorOnClick?: boolean
  /**
   * Custom background color when the Expander is in closed state, defaults to neutralWhite #FFFFFF
   */
  customBackgroundColorClosed?: ThemeColor | string
  /**
   * Custom background color when the Expander is in opened state, requires to have the prop "changeColorOnClick = true" to have an effect to the background color
   */
  customBackgroundColorOpened?: ThemeColor | string
  customBackgroundColorOnHover?: ThemeColor | string
  /**
   * Show the chevron on the right side of the title
   */
  showChevron?: boolean
  /**
   * Use 'align-items: center' css property to determine chevron position
   */
  alignChevronToCenter?: boolean
  /**
   * Style properties for the chevron
   */
  chevronStyles?: CSSProperties
  /**
   * Custom chevron color
   */
  chevronColor?: ThemeColor | string
  /**
   * The title content of the Expander
   */
  titleContent: JSX.Element
  /**
   * The body content of the Expander
   */
  bodyContent: JSX.Element
  /**
   * Id property of the title content
   */
  titleId: string
  /**
   * Id property of the body content
   */
  bodyId: string
}

const ExpanderWrapper = styled.div<{
  changeColorOnClick: boolean
  open: boolean
  customBackgroundColorClosed: ThemeColor | string
  customBackgroundColorOpened: ThemeColor | string
  customBackgroundColorOnHover: ThemeColor | string
  titleHasFocus: boolean
}>(
  ({
    theme: {
      xyz: { color, borderRadius },
    },
    changeColorOnClick,
    customBackgroundColorClosed,
    customBackgroundColorOpened,
    customBackgroundColorOnHover,
    open,
    titleHasFocus,
  }) => css`
    background-color: ${changeColorOnClick
      ? open
        ? color[customBackgroundColorOpened] || customBackgroundColorOpened
        : color[customBackgroundColorClosed] || customBackgroundColorClosed
      : color[customBackgroundColorClosed] || customBackgroundColorClosed};
    transition: all 200ms ease-in-out;
    border-radius: ${borderRadius.sm}rem;

    &:hover {
      background-color: ${customBackgroundColorOnHover
        ? color[customBackgroundColorOnHover] || customBackgroundColorOnHover
        : color.neutralOnHoverGray};
    }

    ${titleHasFocus &&
    `
      box-shadow: 0 0 7px ${color.signalBlue};
    `}
  `
)

const Expander: React.FC<ExpanderProps> = ({
  open,
  changeColorOnClick,
  titleContent,
  bodyContent,
  showChevron,
  alignChevronToCenter,
  chevronStyles,
  bodyId,
  titleId,
  customBackgroundColorClosed,
  customBackgroundColorOpened,
  customBackgroundColorOnHover,
  chevronColor,
  onClick,
  onKeyPress,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const overrideOpenState = open !== undefined
  const expanderIsOpen = overrideOpenState ? open : isOpen
  const [titleHasFocus, setTitleHasFocus] = useState(false)

  const onExpanderClick = (e) => {
    if (!overrideOpenState) {
      setIsOpen(!isOpen)
    }
  }

  const onExpanderKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key !== KeyboardKeys.Enter && e.key !== KeyboardKeys.Space) {
      return null
    }
    e.preventDefault()
    onExpanderClick(e)
  }

  const onFocus = (titleId: string) => {
    const expanderButton = document.getElementById(`${titleId}`)

    if (document.activeElement === expanderButton) {
      setTitleHasFocus(true)
    }
  }

  const onBlur = () => {
    setTitleHasFocus(false)
  }

  const titleProps = { showChevron, chevronStyles, alignChevronToCenter, bodyId, titleId, chevronColor }

  return (
    <ExpanderWrapper
      changeColorOnClick={changeColorOnClick}
      open={expanderIsOpen}
      customBackgroundColorClosed={customBackgroundColorClosed}
      customBackgroundColorOpened={customBackgroundColorOpened}
      customBackgroundColorOnHover={customBackgroundColorOnHover}
      onFocus={() => onFocus(titleId)}
      onBlur={onBlur}
      titleHasFocus={titleHasFocus}
      {...rest}
    >
      <ExpanderTitle
        open={expanderIsOpen}
        onClick={overrideOpenState ? onClick : onExpanderClick}
        onKeyPress={overrideOpenState ? onKeyPress : onExpanderKeyPress}
        {...titleProps}
      >
        {titleContent}
      </ExpanderTitle>
      <ExpanderBody open={expanderIsOpen} id={bodyId}>
        {bodyContent}
      </ExpanderBody>
    </ExpanderWrapper>
  )
}

Expander.defaultProps = {
  open: undefined,
  changeColorOnClick: true,
  customBackgroundColorClosed: XyzTheme.color.neutralWhite,
  customBackgroundColorOpened: XyzTheme.color.neutralGray2,
  customBackgroundColorOnHover: XyzTheme.color.neutralOnHoverGray,
  showChevron: true,
  alignChevronToCenter: true,
  chevronStyles: {},
  chevronColor: XyzTheme.color.neutralNetworkGray,
}

Expander.propTypes = {
  open: PropTypes.bool,
  changeColorOnClick: PropTypes.bool,
  customBackgroundColorClosed: PropTypes.string,
  customBackgroundColorOpened: PropTypes.string,
  customBackgroundColorOnHover: PropTypes.string,
  showChevron: PropTypes.bool,
  alignChevronToCenter: PropTypes.bool,
  chevronStyles: PropTypes.object,
  chevronColor: PropTypes.string,
  titleContent: PropTypes.element.isRequired,
  bodyContent: PropTypes.element.isRequired,
  titleId: PropTypes.string.isRequired,
  bodyId: PropTypes.string.isRequired,
}

export default Expander
