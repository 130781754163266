import React from 'react'
import { UseRowSelectInstanceProps, UseRowSelectRowProps } from 'react-table'
import styled, { css } from 'styled-components'

import { ArrowLeftIcon } from '../../design-tokens/icons'
import { Checkbox } from '../../basic-components/Checkbox/Checkbox'

const StyledCheckbox = styled(Checkbox)`
  margin-right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`
export const StyledArrowDownIcon = styled(ArrowLeftIcon)<{ isVisible: boolean; isUp: boolean }>`
  ${({ isVisible, isUp }) => css`
    display: inline-block;
    visibility: ${isVisible ? 'visible' : 'hidden'};
    margin-left: 1rem;
    transform: ${isUp ? 'rotate(90deg)' : 'rotate(-90deg)'};
  `}
`
export const CheckboxHeader = <D extends object>({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<D>) => {
  const { title, ...props } = getToggleAllRowsSelectedProps()
  return (
    <StyledCheckbox
      {...props}
      onChange={props.onChange as any}
      value={title || 'Check all checkboxes'}
      aria-label={title}
      enterKeyHint={undefined}
    />
  )
}

CheckboxHeader.displayName = 'Header'

export const CheckboxCell = <D extends object>({ row }: { row: UseRowSelectRowProps<D> }) => {
  const { title, ...props } = row.getToggleRowSelectedProps()
  return (
    <StyledCheckbox
      {...props}
      onChange={props.onChange as any}
      value="Toggle row checkbox"
      aria-label={title}
      enterKeyHint={undefined}
    />
  )
}

CheckboxCell.displayName = 'Cell'
