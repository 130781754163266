import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { ListProps, ListOptionProps, ListSectionProps } from './List.types'
import { ListSectionContainer, ListContainer } from './List.styles'
import { Headline, HeadlineSize } from '../../design-tokens/typography/Headline'
import { FilterButtonProps } from '../../basic-components/FilterButton'

export const usePickerGroup = (): ListProps => {
  return React.useContext(ListContext)
}

const ListOption: React.FC<ListOptionProps> = (props) => {
  const { id, onClick, selected, children } = props
  const pickerGroup = usePickerGroup()
  let selectedProp = selected
  let onClickProp = onClick

  if (pickerGroup) {
    selectedProp = pickerGroup.selectedOption.some((element) => element.id === id)

    if (pickerGroup.handleSelectOption) {
      onClickProp = pickerGroup.handleSelectOption
    }
  }

  return (
    <>
      {React.cloneElement(children, {
        id: id,
        selected: selectedProp,
        onClick: onClickProp,
        role: 'option',
        'aria-selected': selectedProp,
      })}
    </>
  )
}

const ListSection: React.FC<ListSectionProps> = (props) => {
  const { title, children } = props

  return (
    <ListSectionContainer aria-label={title}>
      {title && (
        <Headline size={HeadlineSize.Seven} role="heading">
          {title}
        </Headline>
      )}
      {children}
    </ListSectionContainer>
  )
}

const ListContext = React.createContext<ListProps | null>(null)

const List: React.FC<ListProps> = (props) => {
  const { selectedOption, handleSelectOption, children } = props

  return (
    <ListContext.Provider value={{ selectedOption, handleSelectOption }}>
      <ListContainer role="listbox">{children}</ListContainer>
    </ListContext.Provider>
  )
}

ListOption.propTypes = {
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}

ListSection.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
}

const FilterButtonType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}) as Validator<FilterButtonProps>

List.propTypes = {
  selectedOption: PropTypes.arrayOf(FilterButtonType),
  handleSelectOption: PropTypes.func.isRequired,
}

export { List, ListSection, ListOption }
