import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { UIMessage } from '../../basic-components/UIMessage'

import Select, * as ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import { AsyncProps } from 'react-select/dist/declarations/src/useAsync'
import { useTheme } from '../../utils/useTheme'

import type { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu'

import {
  StyledIcon,
  StyledSelectContainer,
  StyledButtonContainer,
  StyledChevronDownIcon,
  StyledSelectPortal,
  StyledControlContainer,
  StyledLabel,
  StyledContentContainer,
  StyledCheckItem,
  StyledValueContainer,
  StyledChildrenWrapper,
} from './Dropdown.styles'
import { CloseIcon } from '../../design-tokens/icons'
import { IconButton } from '../IconButton'
import { CustomDropdownProps } from './Dropdown.types'
import { isArray } from '../../utils/helpers'

const DropdownCustomContext = React.createContext<{
  messageId: string
  icon: React.ComponentType<DefaultIconProps>
  isValid: boolean
  iconColor: string
  lightBackground: boolean
  isSearchWithSuggestion: boolean
  zIndex: number
}>(null)

function useGetCustomProps() {
  const ctx = React.useContext(DropdownCustomContext)
  if (!ctx) throw new Error('Not able to use DropdownCustomContext outside of provider')
  return ctx
}

const CustomInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>(
  props: ReactSelect.InputProps<Option, IsMulti, Group>
) => {
  const { messageId, isValid } = useGetCustomProps()
  return (
    <ReactSelect.components.Input
      {...props}
      id={props.selectProps.inputId}
      aria-disabled={props.selectProps.isDisabled}
      aria-describedby={messageId}
      aria-invalid={isValid !== undefined ? !isValid : false}
      readOnly={!props.selectProps.isSearchable}
    />
  )
}

const CustomDownChevron = (props: { isDisabled: boolean; iconColor: string }) => {
  const theme = useTheme()
  const { iconColor } = useGetCustomProps()
  return (
    <StyledChevronDownIcon
      color={props.isDisabled ? theme.xyz.color.neutralPassiveGray : iconColor ?? theme.xyz.color.neutralPassiveGray}
    />
  )
}

const CustomControl = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>(
  props: ReactSelect.ControlProps<Option, IsMulti, Group>
) => {
  const theme = useTheme()
  const { children, hasValue, selectProps } = props
  const { iconColor, icon: Icon, isSearchWithSuggestion } = useGetCustomProps()
  const isShrinked = selectProps.isSearchable ? selectProps.menuIsOpen || hasValue : hasValue
  return (
    <ReactSelect.components.Control {...props}>
      <StyledButtonContainer disabled={props.selectProps.isDisabled}>
        {!!Icon && (
          <StyledIcon
            as={Icon}
            color={
              props.selectProps.isDisabled
                ? theme.xyz.color.neutralPassiveGray
                : iconColor ?? theme.xyz.color.neutralPassiveGray
            }
            aria-hidden={true}
          />
        )}
        <StyledContentContainer>
          <StyledControlContainer isSearchable={selectProps.isSearchable}>
            <StyledLabel isShrinked={isShrinked} htmlFor={props.selectProps.inputId}>
              {props.selectProps.name}
            </StyledLabel>
            <StyledValueContainer isShrinked={isShrinked}>{children}</StyledValueContainer>
          </StyledControlContainer>
          {!isSearchWithSuggestion && (
            <CustomDownChevron
              isDisabled={props.selectProps.isDisabled}
              iconColor={iconColor ?? theme.xyz.color.neutralPassiveGray}
            />
          )}
          {isSearchWithSuggestion && (props.selectProps.inputValue || hasValue) && (
            <IconButton
              type="button"
              disabled={props.selectProps.isDisabled}
              aria-label="Close"
              onClick={() => props.clearValue()}
              icon={CloseIcon}
            />
          )}
        </StyledContentContainer>
      </StyledButtonContainer>
    </ReactSelect.components.Control>
  )
}

const CustomMenuPortal = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>(
  props: MenuPortalProps<Option, IsMulti, Group>
) => {
  const { zIndex } = useGetCustomProps()
  const innerProps = { ...props.innerProps, style: { zIndex: zIndex !== undefined ? zIndex : 400 } }
  return (
    <ReactSelect.components.MenuPortal {...props} innerProps={innerProps}>
      <StyledSelectPortal>{props.children}</StyledSelectPortal>
    </ReactSelect.components.MenuPortal>
  )
}

const CustomSelectContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>(
  props: ReactSelect.ContainerProps<Option, IsMulti, Group>
) => {
  const { lightBackground, isValid } = useGetCustomProps()
  return (
    <StyledSelectContainer
      hasValue={props.hasValue}
      lightBackground={lightBackground}
      isOpen={props.selectProps.menuIsOpen}
      isValid={isValid !== undefined ? isValid : true}
      disabled={props.selectProps.isDisabled}
    >
      <ReactSelect.components.SelectContainer {...props}>{props.children}</ReactSelect.components.SelectContainer>
    </StyledSelectContainer>
  )
}

const CustomMultiValueContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>({
  selectProps: { getOptionLabel, value },
  data,
}: ReactSelect.MultiValueGenericProps<Option, IsMulti, Group>) => {
  const allSelected = isArray(value) ? [...value] : [value]

  const stringified =
    getOptionLabel(allSelected[allSelected.length - 1]) === getOptionLabel(data)
      ? getOptionLabel(data)
      : getOptionLabel(data) + ', '

  return <>{stringified}</>
}

const CustomOption = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>(
  props: ReactSelect.OptionProps<Option, IsMulti, Group>
) => {
  const theme = useTheme()
  return (
    <ReactSelect.components.Option {...props}>
      <StyledChildrenWrapper>{props.children}</StyledChildrenWrapper>
      {props.isSelected && (
        <StyledCheckItem
          width={`${theme.xyz.iconSize.s}rem`}
          height={`${theme.xyz.iconSize.s}rem`}
          color={props.selectProps.isDisabled ? theme.xyz.color.neutralPassiveGray : theme.xyz.color.signalBlue}
        />
      )}
    </ReactSelect.components.Option>
  )
}

const CustomMenuList = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>(
  props: ReactSelect.MenuListProps<Option, IsMulti, Group>
) => {
  const innerProps = { ...props.innerProps, role: 'listbox', 'aria-expanded': true }
  return <ReactSelect.components.MenuList {...props} innerProps={innerProps} />
}

export function Dropdown<
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>(props: ReactSelect.Props<Option, IsMulti, Group> & AsyncProps<Option, IsMulti, Group> & CustomDropdownProps) {
  const {
    options,
    icon: Icon,
    isValid,
    zIndex,
    message,
    isMulti,
    messageProps,
    maxMenuHeight,
    iconColor,
    lightBackground,
    isSearchWithSuggestion,
  } = props

  const messageId = props.id ? `${props.id}-message` : `dropdown-message`

  const components = {
    IndicatorSeparator: () => null,
    IndicatorsContainer: () => null,
    Input: CustomInput,
    Control: CustomControl,
    DropdownIndicator: () => null,
    MenuPortal: CustomMenuPortal,
    SelectContainer: CustomSelectContainer,
    MenuList: CustomMenuList,
    MultiValueRemove: () => null,
    MultiValueContainer: CustomMultiValueContainer,
    Option: CustomOption,
  }

  const commonProps = {
    hideSelectedOptions: false,
    tabSelectsValue: false,
    ...props,
    options,
    placeholder: null,
    classNamePrefix: 'select',
    maxMenuHeight: maxMenuHeight !== undefined ? maxMenuHeight : 400,
    components: components,
    theme: (theme) => ({ ...theme, borderRadius: 0 }),
  }

  return (
    <div>
      <DropdownCustomContext.Provider
        value={{
          icon: Icon,
          isValid,
          messageId,
          iconColor,
          lightBackground,
          isSearchWithSuggestion,
          zIndex,
        }}
      >
        {isSearchWithSuggestion ? (
          <AsyncSelect
            isClearable
            backspaceRemovesValue
            closeMenuOnSelect
            isSearchable
            noOptionsMessage={() => null}
            {...commonProps}
          />
        ) : (
          <Select
            isClearable={false}
            isSearchable={false}
            backspaceRemovesValue={props.isSearchable}
            closeMenuOnSelect={isMulti ? false : true}
            {...commonProps}
          />
        )}
        <div id={messageId} aria-live="assertive">
          {message && <UIMessage success={isValid} message={message} {...messageProps} />}
        </div>
      </DropdownCustomContext.Provider>
    </div>
  )
}

Dropdown.propTypes = {
  isValid: PropTypes.bool,
  isSearchWithSuggestion: PropTypes.bool,
  message: PropTypes.string,
  messageProps: PropTypes.object,
  zIndex: PropTypes.number,
  lightBackground: PropTypes.bool,
  inputId: PropTypes.string.isRequired,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
}
