/* eslint-disable import/named */
import React, { ButtonHTMLAttributes } from 'react'
import styled, { css, DefaultTheme } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { useTheme } from '../../utils/useTheme'
import { KeyboardKeys } from '../../utils/keyboardNavigation'

export interface ToolButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  icon?: React.ComponentType<DefaultIconProps>
  text?: string
  selected?: boolean
  disabled?: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void
}

const StyledButton = styled.button<ToolButtonProps>`
  ${({
    theme: {
      xyz: { borderRadius, color },
    },
    selected,
    text,
  }) => css`
    border-radius: ${borderRadius.xs}rem;
    background-clip: padding-box;
    border: 2px solid ${color.neutralGray5};
    background-color: ${color.neutralWhite};
    color: ${color.neutralNetworkGray};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: ${text ? '0.75rem 1.5rem' : '0.75rem'};

    ${selected &&
    css`
      background-color: ${color.brandPetrol};
      border: 2px solid ${color.neutralGray5};
      color: ${color.neutralWhite};
    `}

    &:hover,
    &:active {
      background-color: ${selected ? color.brandPetrol : color.neutralOnPressGray};
      border: 2px solid ${selected ? color.neutralGray5 : color.neutralOnPressGray};
    }

    &:focus {
      outline: none;
      border: 2px solid ${selected ? color.neutralGray5 : color.brandPetrol};
      box-shadow: 0px 0px 7px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:disabled {
      background-color: ${color.neutralGray5};
      border: 2px solid ${color.neutralPassiveGray};
      color: ${color.neutralPassiveGray};
    }
  `}
`

const Label = styled.span<{ withLeftMargin: boolean }>`
  ${({
    theme: {
      xyz: { fontFamily, fontWeight, fontSize, lineHeight, spacing },
    },
    withLeftMargin,
  }) => css`
    font-family: ${fontFamily.Montserrat};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.body.three}rem;
    line-height: ${lineHeight.body.three};

    ${withLeftMargin &&
    css`
      margin-left: ${spacing.space3}rem;
    `}
  `}
`

export const getToolButtonIconColor = (
  { selected, disabled }: Pick<ToolButtonProps, 'selected' | 'disabled'>,
  theme: DefaultTheme
): string => {
  if (disabled) {
    return theme.xyz.color.neutralPassiveGray
  }
  if (selected) {
    return theme.xyz.color.neutralWhite
  }
  return theme.xyz.color.neutralIconGray
}

const ToolButton = React.forwardRef<HTMLButtonElement, ToolButtonProps>((props, ref) => {
  const { icon: Icon, text, selected, disabled, children, id, onClick, ...rest } = props
  const theme = useTheme()
  const iconProps = {
    width: `${theme.xyz.iconSize.s}em`,
    height: `${theme.xyz.iconSize.s}em`,
    color: getToolButtonIconColor({ selected, disabled }, theme),
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === KeyboardKeys.Enter) {
      e.preventDefault()
      onClick(e)
    }
  }

  return (
    <StyledButton
      {...rest}
      ref={ref}
      selected={selected}
      disabled={disabled}
      id={id}
      onClick={onClick}
      onKeyPress={onKeyPress}
      aria-pressed={selected}
    >
      {!!Icon && <Icon aria-hidden={true} {...iconProps} />}
      {!!text && <Label withLeftMargin={!!Icon}>{text}</Label>}
      {children}
    </StyledButton>
  )
})

ToolButton.displayName = 'ToolButton'

ToolButton.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
}

ToolButton.defaultProps = {
  selected: false,
  disabled: false,
}

export { ToolButton }
